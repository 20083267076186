import React from 'react'

interface WarningButtonProps {
  label?: string;
  icon?: any;
  position?: string;
  onClick: () => void;
}

function WarningButton({ label, icon, position, onClick }: WarningButtonProps) {
  return (
    <button className="bg-yellow-400 hover:bg-yellow-300 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className="font-semibold">{label ? label : "Warning"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  )
}

export default WarningButton