import React, { useState, useEffect, Fragment } from "react";
import LeaveService from "../../services/LeaveService";
import { Leave } from "../../interfaces/LeaveInterface";
import { useNavigate } from "react-router-dom";

import { PlusIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import CardLeave from "../../components/cards/CardLeave";
import { useIsOnlineAtom } from "../../context/store";

function Leaves() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [leaves, setLeaves] = useState<Leave[]>([]);

  useEffect(() => {
    setIsLoading(true);
    LeaveService.getLeaves()
      .then((response) => {
        setLeaves(response.filter((leave: Leave) => leave.date_to > new Date().toISOString()));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Fragment>
      {!isLoading ? (
        <div className="h-full flex flex-col justify-between">
          <div>
            <TitleWithMenuIcon label="Ferie" wifi={true} />
            <div className="w-full flex flex-col mt-4">
              {leaves.length === 0 ? (
                <NoData />
              ) : (
                <Fragment>
                  {leaves.map((leave, index) => (
                    <CardLeave data={leave} key={index} index={index} />
                  ))}
                </Fragment>
              )}
            </div>
          </div>
          <div className={`w-full sticky bottom-0 mt-3`}>
            {isOnline && (
              <PrimaryButton
                label="Nuova richiesta"
                icon={<PlusIcon className="w-5 h-5 text-white font-bold" />}
                position="start"
                onClick={() => {navigate("/leaves/new")}}
              />
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
}

export default Leaves;
