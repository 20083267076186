import { ColorRing } from "react-loader-spinner";
import { usePhotoSyncAtom, usePhotosToSyncAtom, useSyncAtom, useTripsToSyncAtom } from "../../context/store";

function RemainingItemsToSync() {
  const [photosToSync, setPhotosToSync] = usePhotosToSyncAtom();
  const [tripsToSync, setTripsToSync] = useTripsToSyncAtom();
  const [reportsToSync, setReportsToSync] = useTripsToSyncAtom();

  const [syncingPhotos, setSyncingPhotos] = usePhotoSyncAtom();
  const [syncing, setSyncing] = useSyncAtom();

  return (
    <div
      className={`mb-2 w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 ${
        photosToSync > 0 || tripsToSync > 0 ? "p-2 h-12" : "p-0 h-0"
      } transition-all duration-200 ease-in-out rounded-xl font-semibold flex flex-col items-center justify-start pl-3 text-sm`}
    >
      {photosToSync > 0 && (
        <div className="flex flex-row items-center justify-between w-full">
          <div>{photosToSync} immagini da sincronizzare</div>
          {syncingPhotos && <ColorRing
          visible={true}
          height="24"
          width="24"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#dc2626", "#dc2626", "#dc2626", "#dc2626", "#dc2626"]}
          />}
        </div>
      )}
      {tripsToSync > 0 && (
        <div className="flex flex-row items-center justify-between w-full">
        <div>{tripsToSync} viaggi da sincronizzare</div>
        {syncing && <ColorRing
        visible={true}
        height="24"
        width="24"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#dc2626", "#dc2626", "#dc2626", "#dc2626", "#dc2626"]}
        />}
      </div>
      )}
    </div>
  );
}

export default RemainingItemsToSync;
