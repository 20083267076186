import { Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";

interface StandaloneAccordionProps {
  title: string;
  isOpen?: boolean;
  helper?: boolean;
  children: React.ReactNode;
}

function StandaloneAccordion({
  title,
  isOpen = false,
  helper,
  children,
}: StandaloneAccordionProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(isOpen === undefined){
      setOpen(false);
      return;
    };
    setOpen(isOpen);
  }, [isOpen])

  return (
    <Fragment>
      <div>
        {helper && (
          <p className="opacity-70">Clicca la freccia per i dettagli</p>
        )}
        <div className="mt-2 w-full">
          <div
            className="mt-2 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 rounded-xl w-full py-3 px-2"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div className="px-2 flex flex-row items-center justify-between">
              <label className="block font-bold text-xl leading-6 text-zinc-700 dark:text-zinc-300">
                {title}
              </label>
              <ChevronRightIcon
                className={`transition-all duration-300 ease-in-out h-5 w-5 text-zinc-700 dark:text-zinc-300 ${
                  open ? "rotate-90" : "rotate-0"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <Transition
        className="mx-auto"
        show={open}
        enter="transition-all ease-in-out duration-500 delay-[200ms]"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </Fragment>
  );
}

export default StandaloneAccordion;
