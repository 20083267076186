import React from "react";
interface TitleProps {
  label?: string;
  position?: string;
}

function Title({ label, position }: TitleProps) {

  return (
    <div className="inline-flex items-center">
      <h1
        className={`ml-2 font-bold text-3xl dark:text-zinc-200 mb-2 ${
          position === "center" ? "text-center" : ""
        }`}
      >
        {label}
      </h1>
    </div>
  );
}

export default Title;
