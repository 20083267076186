const HeaderBuilder = {
    build: () => {
        return {headers: {"x-openerp-session-id": localStorage.getItem("chiarcosso_token")}};
    },

    buildGuest: () => {
        return {headers: {"Authorization": "Bearer " + localStorage.getItem("chiarcosso_token")}};
    },
};

export default HeaderBuilder;