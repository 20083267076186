import { ATTACHMENT_TYPE, MOBILE_TRIP_STATUS } from "./TripInterface";

export class BaseAttachment {
    tag: ATTACHMENT_TYPE;
    mobileapp_trip_status: MOBILE_TRIP_STATUS;

  
    constructor(tag: ATTACHMENT_TYPE, mobileapp_trip_status: MOBILE_TRIP_STATUS) {
      this.tag = tag;
      this.mobileapp_trip_status = mobileapp_trip_status;
    }
  
    static deserialize(input: any) {
      return new BaseAttachment(
        input.tag,
        input.mobileapp_trip_status,
      );
    }
  }
  