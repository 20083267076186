import WebInstance from "../utils/AxiosInstance";

import SelectServer from "../utils/SelectServer";
import HeaderBuilder from '../utils/HeaderBuilder';
import { Leave } from "../interfaces/LeaveInterface";
import GADService from "./GADService";

const apiUrl = SelectServer.getServer()

const LeaveService = {
    getLeaves: async () => {
        if(navigator.onLine){
            let response = await WebInstance.get(`${apiUrl}/leave`, HeaderBuilder.build())
            let deserialized = response.data.map((leave: any) => {return Leave.deserialize(leave)})
            let gad = await GADService.getGAD()
            gad.vacations = deserialized
            await GADService.updateOfflineGAD(gad)
            return deserialized
        }

        const data = await GADService.getGAD()
        if(data){
            return data.vacations
        }

        return []
    },

    getLeaveTypes: async () => {
        return WebInstance.get(`${apiUrl}/leave/type`, HeaderBuilder.build())
    },

    createleave: async (leave: any) => {
        return WebInstance.post(`${apiUrl}/leave`, leave, HeaderBuilder.build())
    },

};

export default LeaveService;
