import HeaderBuilder from "../utils/HeaderBuilder";
import SelectServer from "../utils/SelectServer";
import WebInstance from "../utils/AxiosInstance";

const apiUrl = SelectServer.getServer();

const UserService = {
    changePassword: async (oldPassword: string, newPassword: string, confirmPassword: string) => {
        return WebInstance.post(`${apiUrl}/auth/password/change`, { current: oldPassword, new: newPassword, confirm: confirmPassword}, HeaderBuilder.build())
    },

    resetPassword: async (username: string) => {
            return WebInstance.get(
        `${apiUrl}/auth/password/recovery?username=${username}`,
        {headers: { "Skip-Auth": "true" }}
        );
    },


    resetWithToken: async (token: string, newPassword: string, confirmPassword: string) => {
        return WebInstance.post(`${apiUrl}/auth/password/set/${token}`, {new: newPassword, confirm: confirmPassword})
    },

    getUserData: async () => {
        return WebInstance.get(`${apiUrl}/user`, HeaderBuilder.build())
    },
};

export default UserService;