export class User {
  id: number;
  name: string;
  username: string;
  displayName: string;
  companyId: number;
  partnerId: number;
  webBaseUrl: string;
  maxFileUploadSize: number;
  sessionId: string;
  access_rights: "full" | "restricted";

  constructor(
    id: number,
    name: string,
    username: string,
    displayName: string,
    companyId: number,
    partnerId: number,
    webBaseUrl: string,
    maxFileUploadSize: number,
    sessionId: string,
    access_rights: "full" | "restricted"
  ) {
    this.id = id;
    this.name = name;
    this.username = username;
    this.displayName = displayName;
    this.companyId = companyId;
    this.partnerId = partnerId;
    this.webBaseUrl = webBaseUrl;
    this.maxFileUploadSize = maxFileUploadSize;
    this.sessionId = sessionId;
    this.access_rights = access_rights;
  }

  static deserialize(input: any) {
    return new User(
      input.uid,
      input.name,
      input.username,
      input.partner_display_name,
      input.company_id,
      input.partner_id,
      input["web.base.url"],
      input.max_file_upload_size,
      input.session_id,
      input.access_rights
    );
  }

  static getId(user: User): number {
    return user.id;
  }
}
