import React from 'react'

interface NumberInputProps {
    name: string;
    label?: string;
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    uom?: string;
    disabled?: boolean;
  }

function NumberInput({ name, label, placeholder, value, onChange, uom, disabled }: NumberInputProps) {

  return (
    <div className='relative'>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-300">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          disabled={disabled}
          id={name}
          name={name}
          type="number"
          pattern="[0-9]*"
          placeholder={placeholder || ""}
          autoComplete={name}
          value={value}
          max={1500000}
          min={0}
          onChange={(e) => {if(parseInt(e.target.value) < 0) return; if(parseInt(e.target.value) > 1500000) return; onChange(e.target.value)}}
          className="block w-full disabled:opacity-50 rounded-xl py-3 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 border-none ring-0 focus:ring-transparent focus:border-transparent focus:shadow-outline sm:text-sm sm:leading-6"
        />
      </div>
      {uom && (
        <h1 className={`absolute h-6 w-6 text-zinc-500 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 right-3 top-11 font-semibold ${disabled && "opacity-25"}`}>{uom}</h1>
      )}
    </div>
  )
}

export default NumberInput
