import React, { useState } from "react";

interface Props {
  index: number;
  onChange: (e: any) => void;
}

function SingleDigitInput({index, onChange}: Props) {
  const [value, setValue] = useState<number>();

  const handleInput = (e: any) => {
    const val = e.target.value;

    if(val <= 9){
      setValue(val);
      onChange(val);
      return
    }
  };


  return (
    <input
      type="number"
      name={"codedigit_"+index}
      id={"codedigit_"+index}
      value={value}
      onChange={handleInput}
      className="block text-center rounded-md border-0 h-8 w-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-m focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
      max={9}
      min={0}
    />
  );
}

export default SingleDigitInput;
