export class Equipment {
  id: number;
  name: string;
  depth: number;
  parent: number;
  childs: Equipment[];
  added?: boolean;

  constructor(
    id: number,
    name: string,
    depth: number,
    parent: number,
    childs: Equipment[],
    added: boolean = false
  ) {
    this.id = id;
    this.name = name;
    this.depth = depth;
    this.parent = parent;
    this.childs = childs;
    this.added = added;
  }

  static deserialize(input: any) {
    return new Equipment(
      input.id,
      input.name,
      input.depth,
      input.parent,
      input.childs,
      input.added
    );
  }

  static getId(equipment: Equipment): number {
    return equipment.id;
  }
}
