import {
  IAttachmentToAdd,
  MOBILE_TRIP_STATES,
  TRIP_STATES,
  Trip,
} from "../interfaces/TripInterface";
import SelectServer from "../utils/SelectServer";
import HeaderBuilder from "../utils/HeaderBuilder";
import WebInstance from "../utils/AxiosInstance";
import { ConvertToBase64 } from "../utils/ConvertToBase64";

const PadronciniTripService = {
  getTripsPadroncini: async () => {
    const res = await WebInstance.get(
      `${SelectServer.getServer()}/guest/trip`,
      HeaderBuilder.buildGuest()
    );
    return res.data.map((trip: any) => {
      return Trip.deserialize(trip);
    });
  },

  updateTripPadroncini: async (trip: Trip) => {
    const res = await WebInstance.post(
      `${SelectServer.getServer()}/guest/trip`,
      trip,
      HeaderBuilder.buildGuest()
    );
    return Trip.deserialize(res.data);
  },

  savePreTripData: async (trip: any, data: any) => {
    let newTripData = trip;
    let attachments_start: IAttachmentToAdd[] = [];

    newTripData.start_kms = parseFloat(data.kms);
    newTripData.start_coordinates = data.coords;
    newTripData.start_gross_weight = parseFloat(data.grossWeight);
    newTripData.start_tare_weight = parseFloat(data.tareWeight);
    newTripData.start_net_weight = parseFloat(data.netWeight);
    newTripData.state = TRIP_STATES.IN_PROGRESS;
    newTripData.start_datetime = newTripData.start_datetime ? newTripData.start_datetime.slice(0,19) : new Date().toISOString().slice(0,19);

    for await (const attachment of data.photos) {
      const base64 = await ConvertToBase64(attachment.image);
      attachments_start.push({ type: attachment.type, base64 });
    }

    newTripData.attachments_to_add_start = attachments_start;


    const res = await PadronciniTripService.updateTripPadroncini(newTripData);
    return res;
  },

  savePostTripData: async (trip: any, data: any) => {
    let newTripData = trip;
    let attachments_end: IAttachmentToAdd[] = [];

    newTripData.end_kms = parseFloat(data.kms);
    newTripData.end_coordinates = data.coords;
    newTripData.end_gross_weight = parseFloat(data.grossWeight);
    newTripData.end_tare_weight = parseFloat(data.tareWeight);
    newTripData.end_net_weight = parseFloat(data.netWeight);
    newTripData.state = TRIP_STATES.COMPLETED;
    newTripData.start_datetime = newTripData.start_datetime ? newTripData.start_datetime.slice(0,19) : new Date().toISOString().slice(0,19);
    newTripData.end_datetime = newTripData.end_datetime ? newTripData.end_datetime.slice(0,19) : new Date().toISOString().slice(0,19);

    for await (const attachment of data.photos) {
      const base64 = await ConvertToBase64(attachment.image);
      attachments_end.push({ type: attachment.type, base64 });
    }

    newTripData.attachments_to_add_end = attachments_end;


    const res = await PadronciniTripService.updateTripPadroncini(newTripData);
    return res;
  },
};

export default PadronciniTripService;