import React, { useState, Fragment, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ImSpinner8 } from "react-icons/im";
import AuthService from "../../services/AuthService";

function GuestLogin() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [title, setTitle] = useState<string>("Recupero il codice...");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (!token) {
        missingTokenRedirect();
      }

      foundTokenRedirect();
    }, 2000);
  }, []);

  const missingTokenRedirect = () => {
    setTitle("Codice non trovato");
    setLoading(false);
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  const foundTokenRedirect = () => {
    setLoading(false);
    setTitle("Codice trovato!");
    localStorage.setItem("chiarcosso_token", token);
    setTimeout(() => {
      login();
    }, 2000);
  };

  const login = () => {
    setTitle("Effettuo il login...");
    setLoading(true);
    setTimeout(() => {
      AuthService.check().then((res) => {
        setTitle("Login effettuato!");
        setLoading(false);
        redirect();
      }).catch((err) => {
        setTitle("Errore nel login");
        setLoading(false);
        console.log(err);
      });
    }, 2000);
  };

  const redirect = () => {
    setTimeout(() => {
      navigate("/padroncini/viaggi");
    }, 1000);
  };

  return (
    <Fragment>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
          },
        }}
      />
      <div className="flex overflow-x-hidden h-[98dvh] overflow-y-hidden p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out">
        <div className="flex min-h-full flex-1 flex-col px-6 mt-32">
          <div className="mx-auto w-full max-w-sm">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="mx-auto h-18 w-auto"
            />
            <div className="flex flex-col items-center justify-center">
              <h2 className="w-full text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
                {title}
              </h2>
              {loading && (
                <span className="ml-6">
                  <ImSpinner8 className="w-6 h-6 animate-spin text-zinc-800" />
                </span>
              )}
            </div>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
              <div className="flex flex-row w-full justify-center gap-2 items-center"></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GuestLogin;
