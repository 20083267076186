import { BaseAttachment } from "./BaseAttachmentInterface";
import { ATTACHMENT_TYPE, MOBILE_TRIP_STATUS } from "./TripInterface";

export class UploadedAttachment extends BaseAttachment {
  id: number;
  url: string;
  to_remove: boolean;

  constructor(
    id: number,
    url: string,
    to_remove: boolean = false,
    tag: ATTACHMENT_TYPE,
    mobileapp_trip_status: MOBILE_TRIP_STATUS
  ) {
    super(tag, mobileapp_trip_status);
    this.id = id;
    this.url = url;
    this.to_remove = to_remove;  }

  static deserialize(input: any) {
    return new UploadedAttachment(
      input.id,
      input.url,
      input.to_remove,
      input.tag,
      input.mobileapp_trip_status
    );
  }
}
