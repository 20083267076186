import React from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DangerButton from "../../components/buttons/DangerButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";

function SegnalazioniStep1() {
  const navigate = useNavigate();
  return (
    <div className="h-full  flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label="Nuova segnalazione" />
        <div className="flex flex-row justify-center items-center mt-24">
          <div className="flex flex-col justify-center items-center">
            <ExclamationTriangleIcon className="w-24 h-24 text-red-500 dark:text-red-500" />
            <h1 className="font-bold text-3xl text-red-500">ATTENZIONE</h1>
            <h3 className="font-bold text-xl text-zinc-900 dark:text-zinc-200 text-center">
              Prima di eseguire qualunque operazione su quest’app, assicurati di
              essere fuori pericolo ed aver adempito ai tuoi doveri di autista
              secondo il codice della strada, quali la segnalazione del veicolo
              fermo ed eventuali chiamate alle autorità.
            </h3>
          </div>
        </div>
      </div>
      <div className={`w-full sticky bottom-0 mt-3`}>
        <div className="grid grid-cols-2 gap-2">
          <DangerButton
            label="Torna indietro"
            onClick={() => {
              navigate("/segnalazioni");
            }}
          />
          <SecondaryButton
            label="Sono al sicuro continua"
            onClick={() => {
              navigate("/segnalazioni/step2");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SegnalazioniStep1;
