import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ScrollShadow,
  Input,
} from "@nextui-org/react";
import { AvailableVehicle } from "../../interfaces/AvailableVehiclesInterface";

interface Props {
    plate: any;
    setPlate: (plate: any) => void;
}

function SelectPlateModal({ plate, setPlate }: Props) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [plates, setPlates] = useState<AvailableVehicle[]>([]);
  const [filteredPlates, setFilteredPlates] = useState<AvailableVehicle[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    loadAllPlates();
  }, []);

  const loadAllPlates = () => {
    let gad = JSON.parse(localStorage.getItem("chiarcosso_gad")!);
    setPlates(gad.available_vehicles);
    setFilteredPlates(gad.available_vehicles);
  };

  useEffect(() => {
    if (searchTerm.length < 2) {
        loadAllPlates();
      return;
    }
    const filtered = plates.filter((plate) => {
      return plate.license_plate.includes(searchTerm.toUpperCase());
    });
    setFilteredPlates(filtered);
  }, [searchTerm]);

  return (
    <>
      <Button
        style={{ borderColor: "transparent", borderWidth: "0px" }}
        className="w-full bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200 font-semibold"
        onPress={onOpen}
      >
        {!plate.license_plate ? "Seleziona una targa" : plate.license_plate}
      </Button>
      <Modal size="full" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Seleziona una targa
              </ModalHeader>
              <ModalBody>
                <Input
                  placeholder="Cerca una targa..."
                  className="border-transparent ring-transparent outline-transparent"
                  onValueChange={setSearchTerm}
                />
                <ScrollShadow className="mt-1 h-[75dvh]">
                  {filteredPlates.length > 0 ? filteredPlates.map((plate, index) => (
                    <div
                      key={index}
                      onClick={() => {setPlate(plate); onClose();}}
                      className={`bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200 py-1 px-4 rounded-xl ${
                        index > 0 ? "mt-2" : ""
                      }`}
                    >
                      <p className="font-semibold">{plate.license_plate}</p>
                    </div>
                  )) : (
                    <div
                    className={`bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200 py-1 text-center px-4 rounded-xl mt-2`}
                  >
                    <p className="font-semibold">Nessuna targa trovata</p>
                  </div>
                  )}
                </ScrollShadow>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="w-full bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200"
                  variant="solid"
                  onPress={onClose}
                >
                  Chiudi
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default SelectPlateModal;
