import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface SelectInputProps {
  label?: string;
  selected: any;
  setSelected: (value: any) => void;
  name: string;
  options: any[];
}

function SelectInput({ label, selected, setSelected, name, options }: SelectInputProps) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div>
            {label && (
              <label htmlFor={name} className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-300 mb-2">
                {label}
              </label>
            )}
            <Listbox.Button className="relative w-full cursor-pointer rounded-xl bg-zinc-200 py-2  pr-10 text-left focus:outline-none placeholder:font-semibold font-semibold text-zinc-600 dark:text-white dark:bg-zinc-700 sm:text-md sm:leading-6 mb-2">
              <span className="block truncate ml-2">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute font-semibold w-full z-20 mt-4 overflow-auto rounded-xl bg-zinc-200 dark:bg-zinc-700 py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-[#0385FF] text-white" : "text-zinc-800 dark:text-zinc-200",
                          "relative cursor-default select-none py-2 pl-3 pr-9 "
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-white",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </Listbox.Button>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default SelectInput;
