import React, { Fragment, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import TextInput from "../../components/inputs/TextInput";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

function ForgotPasword() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [sent, setSent] = useState(false);
    const [seconds, setSeconds] = useState(3);

    const handleReset = (e: any) => {
        e.preventDefault();
        UserService.resetPassword(username).then((response) => {
            setSent(true);
            setTimeout(() => {
                navigate("/login")

                setInterval(() => {
                    setSeconds((prev) => prev - 1);
                }, 1000);
            }, 3000);
        }).catch((error) => {
            toast.error((t) => (<Fragment>Qualcosa è andato storto, riprovare più tardi<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
        });
    };

  return (
    <Fragment>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
          },
        }}
      />
      <div className="flex overflow-x-hidden h-[98dvh] overflow-y-hidden p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out">
        <div className="flex min-h-full flex-1 flex-col px-6 mt-32">
          <div className="mx-auto w-full max-w-sm">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="mx-auto h-18 w-auto"
            />
            <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
              Reimposta la password
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            {!sent ? (
                <form
                className="space-y-6"
                onSubmit={(e: any) => {
                    handleReset(e);
                }}
                >
                <TextInput
                    name="username"
                    label="Username"
                    value={username}
                    onChange={setUsername}
                />
                <div>
                    <button
                    disabled={username.length < 3}
                    type="submit"
                    className="bg-red-500 font-bold hover:bg-red-400 disabled:bg-zinc-500 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1"
                    >
                    Invia mail di reset
                    </button>
                    <button
                    onClick={() => {
                        navigate("/login");
                    }}
                    type="button"
                    className="bg-zinc-500 font-bold hover:bg-zinc-400  text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1"
                    >
                    Torna indietro
                    </button>
                </div>
                </form>
            ) : (
                <div className="flex flex-col justify-center items-center">
                    <p className="text-xl font-semibold text-zinc-600 dark:text-zinc-300 text-center">Ti abbiamo inviato una mail con le istruzioni per reimpostare la password</p>
                    <p className="text-md font-semibold text-zinc-600 dark:text-zinc-300 text-center mt-4">Verrai reindirizzato al login tra <span className="text-red-600">{seconds}</span> secondi..</p>
                </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgotPasword;
