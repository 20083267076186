import React, { useState, useEffect, Fragment } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import { useParams, useNavigate } from "react-router-dom";
import { TRIP_STATES, Trip } from "../../interfaces/TripInterface";
import DateChip from "../../components/chips/DateChip";
import {
  ArrowDownIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FaTruck } from "react-icons/fa";
import InfoChip from "../../components/chips/InfoChip";
import SimpleAccordion from "../../components/accordions/SimpleAccordion";
import AttachmentList from "../../components/lists/AttachmentList";
import { useTripDetailDataAtom } from "../../context/store";
import NumberInput from "../../components/inputs/NumberInput";
import SuccessButton from "../../components/buttons/SuccessButton";
import TripService from "../../services/TripService";
import toast from "react-hot-toast";

function TripDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tripDetailData, setTripDetailData] = useTripDetailDataAtom();
  const [data, setData] = useState<Trip>(tripDetailData);

  const [openStart, setOpenStart] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [newEndKms, setNewEndKms] = useState(0);
  const [newStartKms, setNewStartKms] = useState(0);

  useEffect(() => {
    if (!id) navigate("/viaggi?tab=attuali");
    if (!tripDetailData) navigate("/viaggi?tab=attuali");
    
    if (tripDetailData.start_kms){
      setNewStartKms(tripDetailData.start_kms);
    }
    
    if (tripDetailData.end_kms){
      setNewEndKms(tripDetailData.end_kms);
    } 

  }, []);

  const handleSave = () => {
    setIsLoading(true);
    TripService.updateErroredTrip(parseInt(id), {...tripDetailData, start_kms: newStartKms, end_kms: newEndKms}).then((res) => {
      navigate("/viaggi?tab=attuali");
    }).catch((err) => {
      toast.error((t) => (
        <Fragment>
          {err?.response?.data?.detail|| "Errore nel salvataggio dei dati"}
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label="Dettaglio viaggio" backRoute={data && data.state === TRIP_STATES.COMPLETED && !data.retry ? "/viaggi?tab=passati" : "/viaggi?tab=attuali"} />
        {data && (
          <Fragment>
            <div
              className={`mt-2  w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
            >
              <div className="flex flex-row justify-between">
                <p className="font-semibold mt-1">
                  {data.id}
                  {" - "}
                  {data.supplier}
                </p>
                <DateChip date={data.date} />
              </div>
              <div className="grid grid-cols-12 gap-2 w-full">
                <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
                <p className="font-semibold mt-1 col-span-11">
                  {data.departure}
                </p>
              </div>
              <div className="grid grid-cols-12 gap-2 w-full">
                <ArrowDownIcon className="h-6 w-6 mt-1 col-span-1" />
                <div className="col-span-11"></div>
              </div>
              <div className="grid grid-cols-12 gap-2 w-full">
                <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
                <p className="font-semibold col-span-11 mt-1">{data.arrival}</p>
              </div>
              {data.vehicles.length > 0 && (
                <div className="grid grid-cols-12 gap-2 w-full mt-1">
                  <FaTruck className="h-6 w-6 mt-1 col-span-1" />
                  <p className="font-semibold col-span-11 mt-1">
                    {data.vehicles[0] ? data.vehicles[0] : ""} -{" "}
                    {data.vehicles[1] ? data.vehicles[1] : ""}
                  </p>
                </div>
              )}
              {data.cargo && (
                <div className="grid grid-cols-12 w-full mt-1 mb-2">
                  <ShoppingCartIcon className="h-6 w-6 mt-1 col-span-1" />
                  <p className="font-semibold col-span-11 mt-1">{data.cargo}</p>
                </div>
              )}
              {data.description && (
                <div className="grid grid-cols-12 w-full mt-1 mb-2">
                  <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
                  <p className="font-semibold col-span-11 mt-1">
                    {data.description}
                  </p>
                </div>
              )}
              {data.retry && (
                <div className="grid grid-cols-12 w-full mt-1 mb-2">
                  <ExclamationTriangleIcon className="h-6 w-6 mt-1 col-span-1" />
                  <p className="font-semibold col-span-11 mt-1 text-red-600 animate-pulse">
                    {data.error !== "" ? data.error : "In attesa di sincronizzazione con Chiarcosso"}
                  </p>
                </div>
              )}
              <div className="mt-2 h-0.5 rounded-full bg-zinc-400 dark:bg-zinc-600"></div>
              <InfoChip
                uom="KG"
                label="Peso netto iniziale"
                value={data.start_net_weight}
              />
              <InfoChip
                uom="KG"
                label="Peso lordo iniziale"
                value={data.start_gross_weight}
              />
              <InfoChip
                uom="KG"
                label="Tara iniziale"
                value={data.start_tare_weight}
              />
              {data.retry ? (
                  <NumberInput
                    disabled={isLoading}
                    label="KM motrice iniziali"
                    name="km_motrice"
                    placeholder="KM Motrice iniziali"
                    value={newStartKms.toString()}
                    onChange={(val) => {
                      setNewStartKms(parseInt(val));
                      if (parseInt(val) < 0) {
                        setNewStartKms(0);
                        return;
                      }
                      document
                        .getElementById("km_motrice")
                        ?.classList.remove("ring-2", "ring-red-500");
                    }}
                    uom="KM"
                  />
              ) : (
                <InfoChip uom="KM" label="Km iniziali" value={data.start_kms} />
              )}
              <InfoChip
                uom="KG"
                label="Peso netto finale"
                value={data.end_net_weight}
              />
              <InfoChip
                uom="KG"
                label="Peso lordo finale"
                value={data.end_gross_weight}
              />
              <InfoChip
                uom="KG"
                label="Tara finale"
                value={data.end_tare_weight}
              />
              {data.retry && data.state === TRIP_STATES.COMPLETED ? (
                  <NumberInput
                    disabled={isLoading}
                    label="KM motrice finali"
                    name="km_motrice"
                    placeholder="KM Motrice finali"
                    value={newEndKms.toString()}
                    onChange={(val) => {
                      setNewEndKms(parseInt(val));
                      if (parseInt(val) < 0) {
                        setNewEndKms(0);
                        return;
                      }
                      document
                        .getElementById("km_motrice")
                        ?.classList.remove("ring-2", "ring-red-500");
                    }}
                    uom="KM"
                  />
              ) : (
                <InfoChip uom="KM" label="Km finali" value={data.end_kms} />
              )}
            </div>
            <div className="mb-2">
              <SimpleAccordion
                title="Foto caricate"
                open={openStart}
                setOpen={setOpenStart}
                children={
                  <Fragment>
                    <AttachmentList canRemove={false} trip={data} />
                  </Fragment>
                }
              />
            </div>
          </Fragment>
        )}
      </div>
      <div className={`w-full sticky bottom-0 mt-3`}>
        {data.retry && (
          <SuccessButton
              label="Salva modifiche"
              isLoading={isLoading}
              onClick={() => {
                handleSave();
              }}
            />
        )}
      </div>
    </div>
  );
}

export default TripDetails;
