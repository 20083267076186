import React, { useEffect, useState } from "react";
import {
  useIsOnlineAtom,
  useOpenMenuAtom,
} from "../../context/store";
import { isMobile } from "react-device-detect";
import { Bars3Icon, WifiIcon } from "@heroicons/react/24/outline";
import PrimaryButtonSmall from "../buttons/PrimaryButtonSmall";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";

interface TitleProps {
  label?: string;
  position?: string;
  wifi?: boolean;
  backRoute?: string;
  isLoading?: boolean;
  hideMenuBtn?: boolean;
  hasSupport?: boolean;
}

function TitleWithMenuIcon({
  label,
  position,
  wifi,
  backRoute,
  isLoading,
  hideMenuBtn = false,
  hasSupport = false,
}: TitleProps) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useOpenMenuAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom();

  const guestLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="flex flex-row items-center">
      {isMobile && window.innerWidth < 768 && !hideMenuBtn && (
        <button onClick={() => setOpenMenu(!openMenu)} className="inline-flex">
          <img
            src="/logo-white.png"
            alt="logo"
            className={`${
              openMenu ? "h-10 w-auto" : "h-0 w-0"
            } transition-all duration-300 ease-in-out`}
          />
          <Bars3Icon
            className={`${
              openMenu ? "mt-2" : ""
            } transition-all duration-300 ease-in-out h-6 w-6 text-zinc-900 dark:text-zinc-300`}
          />
        </button>
      )}
      {isMobile && window.innerWidth < 768 && hideMenuBtn && hasSupport && (
        <a
          href="https://wa.me/393403322559"
          target="_blank"
          className="inline-flex"
        >
          <FaWhatsapp className="h-6 w-6" />
        </a>
      )}
      <h1
        className={`ml-2 font-bold text-3xl dark:text-zinc-200 mb-2 ${
          position === "center" ? "text-center" : ""
        }`}
      >
        {label}
      </h1>
      {wifi && (
        <>
          <WifiIcon
            className={`ml-4 h-8 w-8 transition-all ease-in-out duration-300 ${
              isOnline ? "text-green-500" : "text-red-500"
            }`}
          />
          <p
            className={`ml-2 font-bold text-sm transition-all duration-250 ease-in-out ${
              isOnline ? "text-green-500" : "text-red-500"
            }`}
          ></p>
        </>
      )}
      {isLoading && (
        <ColorRing
          visible={true}
          height="24"
          width="24"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#dc2626", "#dc2626", "#dc2626", "#dc2626", "#dc2626"]}
        />
      )}
      {backRoute && (
        <div className="flex flex-row justify-end flex-grow">
          <PrimaryButtonSmall
            label="Indietro"
            onClick={() => navigate(backRoute)}
          />
        </div>
      )}
      {hasSupport && (
        <div className="flex flex-row justify-end flex-grow">
          <button onClick={() => guestLogout()} className="inline-flex">
            <HiOutlineLogout className="h-6 w-6 text-black dark:text-zinc-200" />
          </button>
        </div>
      )}
    </div>
  );
}

export default TitleWithMenuIcon;
