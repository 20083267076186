import React, { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import TextInput from "../../components/inputs/TextInput";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

function ResetWithToken() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasCharacters, setHasCharacters] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [hasUpper, setHasUpper] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error((t) => (<Fragment>Le password non coincidono<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
      return;
    }

    setIsLoading(true);
    UserService.resetWithToken(token, newPassword, confirmPassword)
      .then((response) => {
        setChanged(true);
        toast.success((t) => (<Fragment>Password reimpostata con successo<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        toast.error((t) => (<Fragment>Qualcosa è andato storto, riprovare più tardi<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkPassword = () => {
    //check if password has at least 8 characters
    if (newPassword.length < 8) {
      setHasNumber(false);
    } else {
      setHasNumber(true);
    }

    //check if password has at least one number
    if (newPassword.match(/\d+/g) == null) {
      setHasCharacters(false);
    } else {
      setHasCharacters(true);
    }

    //check if password has at least one special character
    if (newPassword.match(/[!@#$%^&*(),.?":{}|<>]/g) == null) {
      setHasSpecial(false);
    } else {
      setHasSpecial(true);
    }

    //check if password has at least one uppercase letter
    if (newPassword.match(/[A-Z]/g) == null) {
      setHasUpper(false);
    } else {
      setHasUpper(true);
    }

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  useEffect(() => {
    checkPassword();
  }, [newPassword, confirmPassword]);

  return (
    <Fragment>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
          },
        }}
      />
      <div className="flex overflow-x-hidden h-[98dvh] overflow-y-hidden p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out">
        <div className="flex min-h-full flex-1 flex-col px-6 mt-32">
          <div className="mx-auto w-full max-w-sm">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="mx-auto h-18 w-auto"
            />
            {changed ? (
              <Fragment>
                <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
                  Tutto fatto!
                </h2>
              </Fragment>
            ) : (
              <Fragment>
                <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
                  Scegli una nuova password
                </h2>
                <ul className="text-xs mt-2">
                  <li
                    className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                      !hasCharacters ? "text-red-600" : "text-green-500"
                    }`}
                  >
                    Deve contenere almeno 8 caratteri
                    <span className="ml-2">
                      {!hasCharacters ? (
                        <XMarkIcon className="w-6 h-6 text-red-500" />
                      ) : (
                        <CheckIcon className="w-6 h-6 text-green-500" />
                      )}
                    </span>
                  </li>
                  <li
                    className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                      !hasNumber ? "text-red-600" : "text-green-500"
                    }`}
                  >
                    Deve avere almeno un numero
                    <span className="ml-2">
                      {!hasNumber ? (
                        <XMarkIcon className="w-6 h-6 text-red-500" />
                      ) : (
                        <CheckIcon className="w-6 h-6 text-green-500" />
                      )}
                    </span>
                  </li>
                  <li
                    className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                      !hasSpecial ? "text-red-600" : "text-green-500"
                    }`}
                  >
                    Deve avere almeno un carattere speciale
                    <span className="ml-2">
                      {!hasSpecial ? (
                        <XMarkIcon className="w-6 h-6 text-red-500" />
                      ) : (
                        <CheckIcon className="w-6 h-6 text-green-500" />
                      )}
                    </span>
                  </li>
                  <li
                    className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                      !hasUpper ? "text-red-600" : "text-green-500"
                    }`}
                  >
                    Deve avere almeno una lettera maiuscola
                    <span className="ml-2">
                      {!hasUpper ? (
                        <XMarkIcon className="w-6 h-6 text-red-500" />
                      ) : (
                        <CheckIcon className="w-6 h-6 text-green-500" />
                      )}
                    </span>
                  </li>
                  <li
                    className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                      !passwordsMatch ? "text-red-600" : "text-green-500"
                    }`}
                  >
                    Devono combaciare
                    <span className="ml-2">
                      {!passwordsMatch ? (
                        <XMarkIcon className="w-6 h-6 text-red-500" />
                      ) : (
                        <CheckIcon className="w-6 h-6 text-green-500" />
                      )}
                    </span>
                  </li>
                </ul>
              </Fragment>
            )}
          </div>
          {changed ? (
            <Fragment>Verrai reindirizzato tra 3 secondi!</Fragment>
          ) : (
            <Fragment>
              <form
                className="space-y-6 mt-6"
                onSubmit={(e: any) => {
                  resetPassword(e);
                }}
              >
                <TextInput
                  name="newpassword"
                  label="Nuova password"
                  value={newPassword}
                  onChange={setNewPassword}
                />
                <TextInput
                  name="newpasswordconfirm"
                  label="Conferma password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                />
                <div>
                  <button
                    disabled={
                      hasCharacters &&
                      hasNumber &&
                      hasSpecial &&
                      hasUpper &&
                      passwordsMatch
                        ? false
                        : true
                    }
                    type="submit"
                    className="bg-red-500 font-bold hover:bg-red-400 disabled:bg-zinc-500 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1"
                  >
                    Cambia la password
                  </button>
                  <button
                    onClick={() => {
                      navigate("/login");
                    }}
                    type="button"
                    className="bg-zinc-500 font-bold hover:bg-zinc-400  text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1"
                  >
                    Torna indietro
                  </button>
                </div>
              </form>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default ResetWithToken;
