import React from 'react'

interface InfoChipProps {
    label: string;
    value: any;
    uom: string;
}  

function InfoChip({label, value, uom}: InfoChipProps) {
  return (
    <div className='mt-2 flex flex-col p-2 rounded-xl border border-zinc-400 dark:border-zinc-600'>
        <h1 className='font-bold'>{label}</h1>
        <p>{value}{" "}{uom}</p>
    </div>
  )
}

export default InfoChip