import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import TitleWithMenuIcon from "../components/typography/TitleWithMenuIcon";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import NoData from "../components/NoData";
import EquipmentService from "../services/EquipmentService";
import CardEquipmentRequest from "../components/cards/CardEquipmentRequest";
import { EquipmentRequest } from "../interfaces/EquipmentRequestInterface";
import Loading from "../components/Loading";
import { useIsOnlineAtom } from "../context/store";

function Dotazioni() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [isLoading, setIsLoading] = useState(false);

  const [requests, setRequests] = useState<any[]>([]);
  useEffect(() => {
    setIsLoading(true);
    EquipmentService.getEquipmentRequests()
      .then((response) => {
        let requestToSet = response.data.filter(
          (request: EquipmentRequest) =>
            Date.parse(request.planned_pickup_datetime) >= Date.now()
        );

        if (!isOnline) {
          setRequests(requestToSet);
          setIsLoading(false);
          return;
        }

        getParents(requestToSet);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getParents = async (requests: EquipmentRequest[]) => {
    let ids: number[] = [];
    requests.forEach((request) => {
      request.equipments.forEach((equipment) => {
        if (!ids.includes(equipment.parent)) {
          ids.push(equipment.parent);
        }
      });
    });

    const promises = ids.map((id) => EquipmentService.getEquipment(id));

    const parents = await Promise.all(promises).then((responses) => {
      return responses.map((response) => response.data);
    });

    let builder: any[] = [];

    requests.forEach((request) => {
      builder.push({
        id: request.id,
        planned_pickup_datetime: request.planned_pickup_datetime,
        equipments: [],
      });
      request.equipments.forEach((equipment) => {
        let parent = parents.find((parent) => parent.id === equipment.parent);
        let equipmentIndex = builder[builder.length - 1].equipments.findIndex(
          (e: any) => e.id === equipment.parent
        );

        if (equipmentIndex === -1) {
          builder[builder.length - 1].equipments.push({
            ...parent,
            childs: [],
          });
          equipmentIndex = builder[builder.length - 1].equipments.length - 1;
        }

        builder[builder.length - 1].equipments[equipmentIndex].childs.push(
          equipment
        );
      });
    });
    setRequests(builder);
    setIsLoading(false);
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <Fragment>
        <div>
          <TitleWithMenuIcon label="Dotazioni" wifi={true} />
          {!isLoading ? (
            <Fragment>
              {requests.length > 0 ? (
                requests.map((request, index) => (
                    <CardEquipmentRequest
                      key={index}
                      index={index}
                      data={request}
                    />
                ))
              ) : (
                <NoData label="Nessuna Richiesta" />
              )}
            </Fragment>
          ) : (
            <Loading />
          )}
        </div>
        {!isLoading && isOnline && (
          <div className={`w-full sticky bottom-0 mt-3`}>
            <PrimaryButton
              label="Nuova richiesta"
              icon={<PlusIcon className="w-5 h-5 text-white font-bold" />}
              position="start"
              onClick={() => {
                navigate("/dotazioni/new");
              }}
            />
          </div>
        )}
      </Fragment>
    </div>
  );
}

export default Dotazioni;
