import React, { useState, useEffect, Fragment } from "react";
import TitleWithMenuIcon from "../components/typography/TitleWithMenuIcon";
import Avatar from "boring-avatars";
import WarningButton from "../components/buttons/WarningButton";
import DangerButton from "../components/buttons/DangerButton";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { toast } from "react-hot-toast";
import UserService from "../services/UserService";
import SelectServer from "../utils/SelectServer";
import HeaderBuilder from "../utils/HeaderBuilder";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useIsOnlineAtom } from "../context/store";
import PrimaryButton from "../components/buttons/PrimaryButton";
import DeleteLocalDataModal from "../components/modals/DeleteLocalDataModal";
import { db } from "../database/db";

function Utente() {
  const navigate = useNavigate();
  const [isOnline] = useIsOnlineAtom();
  const [user] = useState(JSON.parse(localStorage.getItem("chiarcosso_user")!));
  const [userImg, setUserImg] = useState<any>();
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    if (isOnline) {
      UserService.getUserData()
        .then((response) => {
          const options = {
            headers: HeaderBuilder.build().headers,
          };

          fetch(
            SelectServer.getServerImg() + response.data.image.image_url,
            options
          )
            .then((response) => response.blob())
            .then((blob) => {
              setUserImg(URL.createObjectURL(blob));
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  }, [isOnline]);

  const handleLocalDelete = async () => {
    setOpenDelete(false);
    localStorage.removeItem("chiarcosso_gad");
    await db.images.clear();
    await db.report_images.clear();
    window.location.reload();
  };

  const unlockDb = async () => {
    await db.images.where("locked").equals(1).modify({ locked: 0 });
    await db.report_images.where("locked").equals(1).modify({ locked: 0 });
    window.location.reload();
  };

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <div>
          <TitleWithMenuIcon label="Profilo" position="center" />
          <div className="flex flex-row justify-center items-center mb-2 relative">
            {userImg !== "" ? (
              <div className="flex flex-row justify-center items-center">
                <img
                  src={userImg}
                  className="rounded-full w-24 h-24 object-cover"
                />
              </div>
            ) : (
              <Avatar
                size={60}
                name={user ? user.name : "Utente"}
                variant="marble"
                colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
              />
            )}
            {isOnline ? (
              <span
                className={`absolute bottom-0 right-[9.5rem] block h-4 w-4 rounded-full bg-green-500 ring-2 dark:ring-zinc-900 ring-zinc-50 transition-all duration-300 ease-in-out`}
              />
            ) : (
              <span
                className={`absolute bottom-0 right-[9.5rem] block h-4 w-4 rounded-full bg-red-500 ring-2 dark:ring-zinc-900 ring-zinc-50 transition-all duration-300 ease-in-out`}
              />
            )}
          </div>
          <div className="mt-2 flex flex-row justify-center items-center">
            <h1 className="text-5xl text-zinc-900 dark:text-zinc-300 font-bold tracking-tighter">
              {user ? user.name : ""}
            </h1>
          </div>
        </div>
        <div className={`w-full sticky bottom-0 mt-3`}>
          <div className="flex flex-row justify-between items-center gap-2">
            <PrimaryButton
              label="Cancella dati locali"
              onClick={() => {
                setOpenDelete(true);
              }}
            />
            <PrimaryButton
              label="Sblocca immagini"
              onClick={() => {
                unlockDb()
              }}
            />
          </div>
          <WarningButton
            label="Cambia password"
            onClick={() => {
              navigate("/cambiapass");
            }}
          />
          <DangerButton
            label="Logout"
            onClick={() => {
              AuthService.logout()
                .then(async () => {
                  localStorage.clear();
                  await db.images.clear();
                  await db.report_images.clear();
                  navigate("/login");
                })
                .catch((error) => {
                  toast.error((t) => (
                    <Fragment>
                      Errore nel logout
                      <button
                        type="button"
                        className="ml-2"
                        onClick={() => {
                          toast.dismiss(t.id);
                        }}
                      >
                        <XMarkIcon className="h-6 w-6 text-white" />
                      </button>
                    </Fragment>
                  ));
                });
            }}
          />
        </div>
      </div>
      <DeleteLocalDataModal
        open={openDelete}
        setOpen={setOpenDelete}
        onConfirm={() => handleLocalDelete()}
      />
    </>
  );
}

export default Utente;
