import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { useIsOnlineAtom, useRefreshGADAtom, useSyncAtom } from "../context/store";
import PrimaryButton from "./buttons/PrimaryButton";
import PrimaryButtonSmall from "./buttons/PrimaryButtonSmall";
import LoadingButtonSmall from "./buttons/LoadingButtonSmall";

interface NoDataProps {
  label?: string;
  hasRefresh?: boolean;
}

function NoData({ label, hasRefresh }: NoDataProps) {
  const [refreshGAD, setRefreshGAD] = useRefreshGADAtom();
  const [syncing, setSyncing] = useSyncAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom()


  return (
    <div className="w-full h-4/5 flex flex-col justify-center">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center text-center">
          {isOnline && (
            <Player
              autoplay
              loop
              src="empty.json"
              style={{ height: "300px", width: "300px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          )}
          <h1 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-200">
            {label ? label : "Non c'è nulla qui"}
          </h1>
          {hasRefresh && (
            <div className="mx-auto">
              {syncing ? (
                <LoadingButtonSmall />
              ) : (
                <PrimaryButtonSmall
                  label="Aggiorna"
                  onClick={() => setRefreshGAD(true)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NoData;
