import React, { useState, useEffect, Fragment } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import TextInput from "../../components/inputs/TextInput";
import ImageInput from "../../components/inputs/ImageInput";
import SelectInput from "../../components/inputs/SelectInput";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SuccessButton from "../../components/buttons/SuccessButton";
import { useNavigate } from "react-router-dom";
import { ReportType } from "../../interfaces/ReportTypeInterface";
import { AvailableVehicle } from "../../interfaces/AvailableVehiclesInterface";
import FilePondComponent from "../../components/inputs/FilePondComponent";
import ReportService from "../../services/ReportService";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";
import LoadingButton from "../../components/buttons/LoadingButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useRefreshGADAtom } from "../../context/store";
import SelectPlateModal from "../../components/modals/SelectPlateModal";

function SegnalazioniStep2() {
  const navigate = useNavigate();
  const [refreshGAD, setRefreshGAD] = useRefreshGADAtom();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [descrizione, setDescrizione] = useState("");
  const [photos, setPhotos] = useState<File[]>();
  const [totalPhotosSize, setTotalPhotosSize] = useState<number>(0);

  const [report_types, setReport_types] = useState<ReportType[]>([]);
  const [type, setType] = useState<ReportType>();

  const [plates, setPlates] = useState<AvailableVehicle[]>([]);
  const [filteredPlates, setFilteredPlates] = useState<AvailableVehicle[]>([]);
  const [plate, setPlate] = useState<AvailableVehicle>(
    new AvailableVehicle(0, null)
  );
  const [calculatingSize, setCalculatingSize] = useState<boolean>(false);


  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (photos) {
      let totalSize = 0;
      photos.forEach((photo) => {
        totalSize += photo.size;
      });
      setTotalPhotosSize(totalSize);
    }
  }, [photos]);

  useEffect(() => {
    let gad = JSON.parse(localStorage.getItem("chiarcosso_gad")!);
    setReport_types(gad.report_types);
    setType(gad.report_types[0]);
    setPlates(gad.available_vehicles);
    setFilteredPlates(gad.available_vehicles);
  }, []);

  const handleCreateReport = async () => {
    if(calculatingSize){
      return;
    }


    setIsLoading(true);
    if (descrizione.length < 10) {
      toast.error((t) => (
        <Fragment>
          La descrizione deve essere lunga almeno 10 caratteri
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      setIsLoading(false);
      return;
    }

    let report: any = {
      report_type: type!.id,
      datetime: new Date().toISOString().slice(0, 19),
      description: descrizione,
      attachments: photos
    };

    if (type?.can_add_plates && !plate?.id) {
      toast.error((t) => (
        <Fragment>
          L'inserimento della targa è obbligatorio per questo tipo di
          segnalazione
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      setIsLoading(false);
      return;
    }

    // if (type?.can_add_plates) {
    //   report.plate = plate;
    // }
    report.plate = plate;

    if (report.plate.id === 0) {
      delete report.plate;
    }

    ReportService.saveReportData(report)
      .then(() => {
        toast((t) => (
          <Fragment>
            Invio segnalazione in corso
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
        setRefreshGAD(true);
        navigate("/segnalazioni");
      })
      .catch((error) => {
        console.log(error);
        let msg = {
          redirect: false,
          detail: "Errore durante la creazione della segnalazione",
        };

        if (error?.response?.data?.detail && error?.code !== "ERR_NETWORK") {
          msg = {
            redirect: false,
            detail: JSON.stringify(error?.response?.data?.detail),
          };
        }

        if (!error?.response?.data?.detail && error?.code === "ERR_NETWORK") {
          msg = {
            redirect: true,
            detail:
              "Errore di connessione, il viaggio verrà sincronizzato piu tardi",
          };
        }

        toast.error((t) => (
          <Fragment>
            {msg.detail}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));

        if (msg.redirect) {
          navigate("/segnalazioni");
        }
      })
      .finally(() => {
        //navigate("/segnalazioni");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFilteredPlates(plates);
      return;
    }
    const filtered = plates.filter((plate) => {
      return plate.license_plate.includes(searchTerm.toUpperCase());
    });
    setFilteredPlates(filtered);
  }, [searchTerm]);

  return (
    <>
      <div className="h-full  flex flex-col justify-between">
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <TitleWithMenuIcon label="Nuova segnalazione" />
              <div className="mt-2 p-2">
                {
                  //get size in mb
                  totalPhotosSize > 0 && (
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      Dimensione foto compresse:{" "}
                      {Math.round((totalPhotosSize / 1000000) * 100) / 100} di 30 MB
                    </div>
                  )
                }
              </div>
              {type && (
                <SelectInput
                  selected={type}
                  setSelected={setType}
                  name="report_type"
                  options={report_types}
                />
              )}
              {type && type.can_add_plates && (
                <SelectPlateModal plate={plate} setPlate={setPlate} />
              )}
              <TextInput
                placeholder="Descrizione"
                value={descrizione}
                onChange={setDescrizione}
                name="descrizione"
              />
              {totalPhotosSize > 30000000 && (
                <div className="w-full rounded-xl bg-red-500 text-white font-semibold p-2 mt-2">
                  Attenzione, il peso delle foto supera i 30 MB, eliminare
                  qualche foto per assicurare la sincronizzazione della
                  segnalazione!
                </div>
              )}
              <FilePondComponent
                fieldId={"report_photos"}
                label={"Foto segnalazione"}
                onChange={(images) => {
                  setCalculatingSize(true);
                  Promise.all(images).then((res) => {
                    setPhotos(res);
                    setCalculatingSize(false);
                  });
                }}
              />
            </div>
          )}
        </div>
        <div className={`w-full sticky bottom-0 mt-3`}>
          <div
            className={`grid ${
              isLoading ? "grid-cols-1" : "grid-cols-2"
            } gap-2`}
          >
            {!isLoading ? (
              <Fragment>
                <SecondaryButton
                  label="Torna indietro"
                  onClick={() => {
                    navigate("/segnalazioni/step1");
                  }}
                />
                <SuccessButton
                  isDisabled={totalPhotosSize > 30000000 || calculatingSize}
                  label="Salva"
                  isLoading={isLoading}
                  onClick={() => {
                    if (totalPhotosSize > 30000000) {
                      return;
                    }
                    handleCreateReport();
                  }}
                />
              </Fragment>
            ) : (
              <LoadingButton />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SegnalazioniStep2;
