import React, { useState } from "react";
import DateChip from "../chips/DateChip";
import {
  ArrowDownIcon,
  GlobeAltIcon,
  ShoppingCartIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FaTruck } from "react-icons/fa";
import SuccessButton from "../buttons/SuccessButton";
import DangerButton from "../buttons/DangerButton";
import { useNavigate } from "react-router-dom";
import { TRIP_STATES, Trip } from "../../interfaces/TripInterface";
import PrimaryButton from "../buttons/PrimaryButton";
import {
  useIsOnlineAtom,
  useSyncAtom,
  useTripDetailDataAtom,
} from "../../context/store";
import { DateTime } from "luxon";
import { WebAppData } from "../../interfaces/WebAppDataInterface";
import GADService from "../../services/GADService";
import toast from "react-hot-toast";

interface CardViaggiProps {
  index: number;
  data: Trip;
}

function CardViaggi({ data, index }: CardViaggiProps) {
  const navigate = useNavigate();
  const [tripDetailData, setTripDetailData] = useTripDetailDataAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [syncing, setSyncing] = useSyncAtom();

  const handleSetClickDate = (trip: Trip, status: "start" | "end") => {
    let now = new Date();
    let gad = WebAppData.deserialize(
      JSON.parse(localStorage.getItem("chiarcosso_gad")!)
    );

    DateTime.fromJSDate(now).toISO({ includeOffset: false });

    let foundTrip = gad.trips.filter((t: Trip) => t.id === trip.id)[0];

    if (status === "start") {
      if (
        foundTrip.start_datetime === "" ||
        foundTrip.start_datetime === null
      ) {
        foundTrip.start_datetime = DateTime.fromJSDate(now).toISO({
          includeOffset: false,
        });
      }
    }

    if (status === "end") {
      if (foundTrip.end_datetime === "" || foundTrip.end_datetime === null) {
        foundTrip.end_datetime = DateTime.fromJSDate(now).toISO({
          includeOffset: false,
        });
      }
    }

    gad.trips = gad.trips.filter((t: Trip) => t.id !== trip.id);
    gad.trips.push(foundTrip);

    GADService.updateOfflineGAD(gad);
  };

  const SyncMessage = () => {
    if (syncing && isOnline && data.error === "") {
      return "Sincronizzazione in corso...";
    }

    if (data.error !== "" && isOnline && !syncing) {
      return data.error;
    }

    if (data.error !== "" && !isOnline && !syncing) {
      return "Sei offline - " + data.error;
    }

    if (data.error === "" && !isOnline && !syncing) {
      return "Sei offline, il viaggio verrà sincronizzato piu tardi";
    }

    if (data.error === "" && isOnline && !syncing) {
      return "In attesa di sincronizzazione con Chiarcosso";
    }
  };

  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - "}
          {data.supplier}
        </p>
        <DateChip date={data.date} />
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold mt-1 col-span-11">{data.departure}</p>
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <ArrowDownIcon className="h-6 w-6 mt-1 col-span-1" />
        <div className="col-span-11"></div>
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold col-span-11 mt-1">{data.arrival}</p>
      </div>
      {data.vehicles.length > 0 && (
        <div className="grid grid-cols-12 gap-2 w-full mt-1">
          <FaTruck className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            {data.vehicles[0] ? data.vehicles[0] : ""} -{" "}
            {data.vehicles[1] ? data.vehicles[1] : ""}
          </p>
        </div>
      )}
      {data.cargo && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <ShoppingCartIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.cargo}</p>
        </div>
      )}
      {data.description && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.description}</p>
        </div>
      )}
      {data.retry && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <ExclamationTriangleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1 text-red-600 animate-pulse">
            {SyncMessage()}
          </p>
        </div>
      )}
      {data.state === TRIP_STATES.TODO && (
        <SuccessButton
          label="Inizia viaggio"
          onClick={() => {
            handleSetClickDate(data, "start");
            navigate("/registrapesata/start?id=" + data.id);
          }}
        />
      )}
      {data.state === TRIP_STATES.IN_PROGRESS && (
        <div
          className={`grid ${data.retry ? "grid-cols-2 gap-4" : "grid-cols-1"}`}
        >
          {data.retry && (
            <PrimaryButton
              label="Info / Modifica"
              onClick={() => {
                setTripDetailData(data);
                navigate("/trips/details/" + data.id);
              }}
            />
          )}
          <DangerButton
            disabled={syncing}
            label="Fine viaggio"
            onClick={() => {
              handleSetClickDate(data, "end");
              navigate("/registrapesata/end?id=" + data.id);
            }}
          />
        </div>
      )}

      {data.state === TRIP_STATES.COMPLETED && (
        <PrimaryButton
        disabled={syncing}
        label={data.retry ? "Info / Modifica" : "Info"}
        onClick={() => {
          setTripDetailData(data);
          navigate("/trips/details/" + data.id);
        }}
      />
      )}
    </div>
  );
}

export default CardViaggi;
