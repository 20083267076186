import { BaseAttachment } from "./BaseAttachmentInterface";
import { ATTACHMENT_TYPE, MOBILE_TRIP_STATUS } from "./TripInterface";

export class OfflineAttachment extends BaseAttachment {
  offline_id: number;

  constructor(
    offline_id: number,
    tag: ATTACHMENT_TYPE,
    mobileapp_trip_status: MOBILE_TRIP_STATUS
  ) {
    super(tag, mobileapp_trip_status);
    this.offline_id = offline_id;
  }

  static deserialize(input: any) {
    return new OfflineAttachment(
      input.offline_id,
      input.tag,
      input.mobileapp_trip_status
    );
  }
}
