import WebInstance from "../utils/AxiosInstance";
import HeaderBuilder from "../utils/HeaderBuilder";
import { WebAppData } from "../interfaces/WebAppDataInterface";
import { db } from "../database/db";
import SelectServer from "../utils/SelectServer";
import { AxiosResponse } from "axios";
import { Trip } from "../interfaces/TripInterface";
import TripService from "./TripService";
import { Report } from "../interfaces/ReportInterface";
import ReportService from "./ReportService";

const gadVarName = "chiarcosso_gad";

const GADService = {
  getGAD: async () => {
    if (navigator.onLine) {
      try {
        await sync();

        const response = await WebInstance.get(
          `${SelectServer.getServer()}/get_webapp_data`,
          HeaderBuilder.build()
        );
        let newGad = WebAppData.deserialize(response.data);
        let oldGad = getGADOffline();

        //rebuild the report array to avoid non syncronized data deletion
        let oldReports = oldGad.reports;
        let newReports = newGad.reports;

/*         oldReports.forEach((oldReport) => {
          if (oldReport.retry) {
            //check if in the newReports there is already the report with the same id and remove it
            let index = newReports.findIndex(
              (report) => report.appid === oldReport.appid
            );
            if (index !== -1) {
              newReports.splice(index, 1);
            }
            newReports.push(oldReport);
          }
        }); */



        oldReports.forEach((oldReport) => {
          if (oldReport.retry) {
            console.log(oldReport);
            //check if in the newReports there is already the report with the same id and remove it
            let dbReport = null; 
            let index = newReports.findIndex(
              (report) => {
                if(report.appid === oldReport.appid){
                  dbReport = report;
                }
                return report.appid === oldReport.appid
              }
            );
            if (index !== -1) {
              newReports.splice(index, 1);
            }

            if (dbReport) {
              oldReport.retry = false;
            }

            newReports.push(oldReport);
          }
        });

        //rebuild the trips array to avoid non syncronized data deletion
        let oldTrips = oldGad.trips;
        let newTrips = newGad.trips;

        oldTrips.forEach((oldTrip) => {
          if (oldTrip.retry || oldTrip.lock) {
            //check if in the newTrips there is already the trip with the same id and remove it
            let index = newTrips.findIndex((trip) => trip.id === oldTrip.id);
            if (index !== -1) {
              newTrips.splice(index, 1);
            }
            newTrips.push(oldTrip);
          }
        });

        newGad = { ...newGad, reports: newReports, trips: newTrips };
        await GADService.updateOfflineGAD(newGad, "sync all with merge");

        return newGad;
      } catch (error) {
        return getGADOffline();
      }
    } else {
      return getGADOffline();
    }
  },

  updateOfflineGAD: async (gad: WebAppData, from?: string) => {
    /*const time = new Date().getTime();
    const oldGad = getGADOffline();
    console.log("[" + time + "]" + " oldGad " + from || "", oldGad);*/
    /*if (gad.reports.filter((report) => report.retry).length === 0) {
      await db.report_images.clear();
    }*/
    localStorage.setItem(gadVarName, JSON.stringify(gad));
    /*const newGad = getGADOffline();
    console.log("[" + time + "]" + "newGad " + from || "", newGad);*/
    return true;
  },
};

const getGADOffline = () => {
  const data = localStorage.getItem(gadVarName);
  if (data) {
    return WebAppData.deserialize(JSON.parse(data));
  } else {
    return new WebAppData([], [], [], [], [], [], []);
  }
};

const syncTrips = async () => {
  return new Promise(async (resolve, reject) => {
    let gad = getGADOffline();

    const erroredTrips = [];
    const successTrips = [];

    const tripsToUpdate: Trip[] = gad.trips
      .filter((trip: Trip) => trip.retry && !trip.lock)
      .sort((a, b) => {
        return a.id - b.id;
      });

    if (tripsToUpdate.length > 0) {
      await Promise.allSettled([TripService.updateTrip(tripsToUpdate[0])]).then(
        (results) => {
          results.forEach((result) => {
            if (result.status === "rejected") {
              erroredTrips.push({
                id: JSON.parse(result.reason.config.data).id,
                reason: result.reason.response.data.detail,
              });
            }
            if (result.status === "fulfilled") {
              successTrips.push({ id: result.value.data.id });
            }
          });
        }
      );
    }

    for (const item of erroredTrips) {
      let index = gad.trips.findIndex((trip) => trip.id === item.id);
      if (index !== -1) {
        gad.trips[index].retry = true;
        gad.trips[index].error = item.reason;
      }
    }

    for (const item of successTrips) {
      let index = gad.trips.findIndex((trip) => trip.id === item.id);
      if (index !== -1) {
        gad.trips[index].retry = false;
      }
    }

    GADService.updateOfflineGAD(gad, "sync trips");
    resolve(true);
  });
};

const syncReports = async () => {
  const processingReports = new Set();

  return new Promise(async (resolve, reject) => {
    let gad = getGADOffline();

    const erroredReports = [];
    const successReports = [];

    const reportsToUpdate: Report[] = gad.reports
      .filter((report: Report) => report.retry)
      .sort((a, b) => a.id - b.id);

    // Filtro i report che vengono processati
    const uniqueReportsToUpdate = reportsToUpdate.filter((report) => {
      if (processingReports.has(report.id)) {
        return false;
      } else {
        processingReports.add(report.id);
        return true;
      }
    });

    // v1
    if (uniqueReportsToUpdate.length > 0) {
      console.log(uniqueReportsToUpdate);
    
      const promises = uniqueReportsToUpdate.map(async (report) => {
        try {
          await ReportService.updateReport(report);
          return { status: 'fulfilled', id: report.id };
        } catch (error) {
          return { status: 'rejected', id: report.id, reason: error.response.data.detail };
        }
      });
    
      const results = await Promise.all(promises);
      console.log('inviato report');
    
      results.forEach((result) => {
        if (result.status === 'rejected') {
          erroredReports.push({ id: result.id, reason: result.reason });
        }
        if (result.status === 'fulfilled') {
          successReports.push({ id: result.id });
        }
        // Rimuovo il record processato dal set
        processingReports.delete(result.id);
      });
    }

    // v2
/*     if (uniqueReportsToUpdate.length > 0) {
      console.log(uniqueReportsToUpdate);
    
      for (const report of uniqueReportsToUpdate) {
        try {
          await ReportService.updateReport(report);
          successReports.push({ id: report.id });
        } catch (error) {
          erroredReports.push({
            id: report.id,
            reason: error.response.data.detail,
          });
        } finally {
          // Rimuovo il record processato dal set
          processingReports.delete(report.id);
        }
    
        // Attendi un secondo prima di passare alla prossima iterazione
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    
      console.log('inviato report');
    } */

    for await (const item of erroredReports) {
      let index = gad.reports.findIndex((report) => report.appid === item.id && report.retry);
      if (index !== -1) {
        gad.reports[index].retry = true;
        gad.reports[index].error = item.reason;
      }
    }

    for await (const item of successReports) {
      let index = gad.reports.findIndex((report) => report.appid === item.id && report.retry);
      if (index !== -1) {
        gad.reports[index].retry = false;
      }
    }

    GADService.updateOfflineGAD(gad, "sync reports");
    resolve(true);
  });
};


const sync = async () => {
  console.log("syncing");
  return new Promise(async (resolve, reject) => {
    syncTrips()
      .then((result) => {})
      .catch((error) => {})
      .finally(() => {
        syncReports()
          .then((result) => {})
          .catch((error) => {})
          .finally(() => {
            resolve(true);
          });
      });
  });
};

export default GADService;
