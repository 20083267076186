import React from "react";
import { ColorRing } from "react-loader-spinner";

interface LoadingButtonProps {
  label?: string;
}

function LoadingButtonSmall({ label }: LoadingButtonProps) {
  return (
    <button
      className="bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200 flex justify-center align-middle px-4 py-1 rounded-xl transition-all duration-300 ease-in-out my-1"
    >
      <div className="inline-flex">
        <div>
        <ColorRing
          visible={true}
          height="24"
          width="24"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#dc2626", "#dc2626", "#dc2626", "#dc2626", "#dc2626"]}
          />
          </div>
        <p className="ml-1 font-semibold">{label ? label : "Caricamento..."}</p>
      </div>
    </button>
  );
}

export default LoadingButtonSmall;
