export class OfflineReportAttachment {
  offline_id: number;

  constructor(offline_id: number) {
    this.offline_id = offline_id;
  }

  static deserialize(input: any) {
    return new OfflineReportAttachment(input.offline_id);
  }
}
