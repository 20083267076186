import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGADAtom,
  useIsOnlineAtom,
  useOpenMenuAtom,
  useRefreshGADAtom,
} from "../context/store";
import GADService from "../services/GADService";

import Avatar from "boring-avatars";
import {
  Bars3Icon,
  BookOpenIcon,
  ExclamationTriangleIcon,
  GlobeEuropeAfricaIcon,
  MoonIcon,
  ShoppingCartIcon,
  SparklesIcon,
  SunIcon,
  WifiIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FaWhatsapp } from "react-icons/fa";
import Loading from "../components/Loading";
import TripService from "../services/TripService";
import AuthService from "../services/AuthService";
import ReportService from "../services/ReportService";
import InstallAppModal from "../components/modals/InstallAppModal";
import UserService from "../services/UserService";
import HeaderBuilder from "../utils/HeaderBuilder";
import SelectServer from "../utils/SelectServer";

function AltLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [GAD, setGAD] = useGADAtom();
  const [isDark, setIsDark] = useState(false);
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [isLoading, setIsLoading] = useState(false);
  const [openMenu, setOpenMenu] = useOpenMenuAtom();
  const [refreshGAD, setRefreshGAD] = useRefreshGADAtom();
  const [showInstallHelp, setShowInstallHelp] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("chiarcosso_user")!)
  );
  const [userImg, setUserImg] = useState<any>();
  const locations = useMemo(
    () => [
      "/viaggi",
      "/dotazioni",
      "/segnalazioni",
      "/manualistica",
      "/leaves",
      "/utente",
    ],
    []
  );

  useEffect(() => {

    if (document.getElementsByTagName("html")[0].classList.contains("dark")) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }

    //check if pwa is installed

    if (!window.matchMedia("(display-mode: standalone)").matches) {
      //setShowInstallHelp(true);
    }

    return () => {};
  }, []);

  const toggleDarkMode = () => {
    document.getElementsByTagName("html")[0].classList.toggle("dark");

    if (document.getElementsByTagName("html")[0].classList.contains("dark")) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  };

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    //create a switch statement based on the pathname that changes the background color of the html tag
    let elem = document.getElementsByTagName("html")[0];
    let ids = ["viaggi", "dotazioni", "segnalazioni", "manualistica", "leaves"];
    elem.className =
      "bg-gradient-to-br from-zinc-950 transition-all duration-300 ease-in-out";

    switch (location.pathname) {
      case "/viaggi":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/dotazioni":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/segnalazioni":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/manualistica":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/leaves":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      default:
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
    }

    if (isDark) {
      elem.classList.add("dark");
    }

    ids.forEach((id) => {
      if (location.pathname === `/${id}`) {
        document.getElementById(id)?.classList.add("bg-zinc-800");
        document.getElementById(id)?.classList.add("text-red-500");
      } else {
        document.getElementById(id)?.classList.remove("bg-zinc-800");
        document.getElementById(id)?.classList.remove("text-red-500");
      }
    });

    // TODO - da rivedere
    if (locations.includes(location.pathname)) {
      setRefreshGAD(true);
      setOpenMenu(false);
    }
  }, [location]);

  return (
    <>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
            icon: "",
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
            icon: "",
          },
        }}
      />
      <div className="flex overflow-hidden">
        {isMobile ? (
          <>
            {openMenu && <div></div>}
            <div
              className={`${
                openMenu ? "translate-x-20 scale-75 blur-sm" : ""
              } z-20 p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 opacity-100 flex-1 transition-all duration-300 ease-in-out overflow-y-scroll max-h-[98dvh] overflow-x-hidden`}
            >
              {isLoading ? <Loading /> : <Outlet />}
            </div>
          </>
        ) : (
          <div className="z-20 p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out max-h-[98dvh]">
            {isLoading ? <Loading /> : <Outlet />}
          </div>
        )}
      </div>

      <InstallAppModal open={showInstallHelp} setOpen={setShowInstallHelp} />
    </>
  );
}

export default AltLayout;
