import React, { useState, Fragment, useEffect } from "react";
import TextInput from "../../components/inputs/TextInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import InstallAppModal from "../../components/modals/InstallAppModal";
import { XMarkIcon } from "@heroicons/react/24/outline";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showInstallHelp, setShowInstallHelp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //check if pwa is installed

    if (!window.matchMedia("(display-mode: standalone)").matches) {
      //setShowInstallHelp(true);
    }
  }, []);

  const handleLogin = (e: any) => {
    e.preventDefault();

    if (username === "" || password === "") {
      toast.error((t) => (
        <Fragment>
          Inserire username e password
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      return;
    }

    AuthService.login(username, password)
      .then(() => {
        navigate("/viaggi?tab=attuali");
      })
      .catch((error) => {
        toast.error((t) => (
          <Fragment>
            {error?.response?.data?.message || "Errore nel login"}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
      });
  };

  return (
    <Fragment>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
          },
        }}
      />
      <div className="flex overflow-x-hidden h-[98dvh] overflow-y-hidden p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out">
        <div className="flex min-h-full flex-1 flex-col px-6 mt-32">
          <div className="mx-auto w-full max-w-sm">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="mx-auto h-18 w-auto"
            />
            <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
              Effettua il login
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              onSubmit={(e: any) => {
                handleLogin(e);
              }}
            >
              <TextInput
                name="username"
                label="Username"
                value={username}
                onChange={setUsername}
              />
              <PasswordInput
                name="password"
                label="Password"
                value={password}
                onChange={setPassword}
                haveForgotPassword={true}
                forgotPassword={() => {
                  navigate("/forgot");
                }}
                color="red"
              />
              <div>
                <button
                  type="submit"
                  className="bg-red-500 font-bold hover:bg-red-400 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95"
                >
                  Entra
                </button>
              </div>
            </form>
            {/* Predisposizione al login con codice
            <div className="flex flex-col items-center justify-center mt-2">
              <button
                type="button"
                onClick={() => {
                  navigate("/codelogin");
                }}
                className="bg-sky-500 font-bold hover:bg-sky-400 text-white flex justify-center align-middle w-full py-1 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95"
              >
                Login con codice
              </button>
            </div>
            */}
            <p className="text-zinc-500 w-full text-center text-xs mt-6">{"Chiarcosso autisti v" + process.env.REACT_APP_VERSION}</p>
          </div>
        </div>
      </div>


      <InstallAppModal open={showInstallHelp} setOpen={setShowInstallHelp} />
    </Fragment>
  );
}

export default Login;
