import { AxiosResponse } from "axios";
import { db } from "../database/db";
import {
  IAttachmentToAdd,
  MOBILE_TRIP_STATES,
  TRIP_STATES,
  Trip,
} from "../interfaces/TripInterface";
import HeaderBuilder from "../utils/HeaderBuilder";
import { WebAppData } from "../interfaces/WebAppDataInterface";
import { OfflineAttachment } from "../interfaces/OfflineAttachmentInterface";
import SelectServer from "../utils/SelectServer";
import WebInstance from "../utils/AxiosInstance";
import { ConvertToBase64 } from "../utils/ConvertToBase64";
import GADService from "./GADService";

const apiUrl = SelectServer.getServer();
const gadVarName = "chiarcosso_gad";

//Main service

const TripService = {
  getTrips: async () => {
    return getTripsOffline();
  },

  savePreTripData: async (id: number, data: any) => {
    console.log("pre trip data", data)
    return new Promise(async (resolve, reject) => {
      //mi prendo il gad dal localstorage
      const gad = WebAppData.deserialize(
        JSON.parse(localStorage.getItem(gadVarName)!)
      );

      //trovo il viaggio corrente
      const currentTrip = gad.trips.find((trip: Trip) => trip.id === id);

      //creo un array di offline attachments
      let offlineAttachments: OfflineAttachment[] = [];
      for (const [index, image] of data.photos.entries()) {
        console.log(image);
        
        let pid = await db.images.add({
          ...image,
          phase: MOBILE_TRIP_STATES.START,
          locked: 0,
          base64: await ConvertToBase64(image.image),
        });
        offlineAttachments.push(
          new OfflineAttachment(pid, image.type, MOBILE_TRIP_STATES.START)
        );
      }

      if (currentTrip) {
        currentTrip.start_net_weight = parseFloat(data.netWeight);
        currentTrip.start_gross_weight = parseFloat(data.grossWeight);
        currentTrip.start_tare_weight = parseFloat(data.tareWeight);
        currentTrip.start_kms = parseFloat(data.kms);
        currentTrip.start_coordinates = data.coords;
        currentTrip.state = TRIP_STATES.IN_PROGRESS;
        currentTrip.offline_attachments = offlineAttachments;
        currentTrip.retry = true;
        currentTrip.start_datetime = currentTrip.start_datetime
          ? currentTrip.start_datetime.slice(0, 19)
          : new Date().toISOString().slice(0, 19);
      }
      gad.trips.map((trip: Trip) => (trip.id === id ? currentTrip : trip));
      GADService.updateOfflineGAD(gad, "savePreTripData");
      resolve(true);
    });
  },

  savePostTripData: async (id: number, data: any) => {
    const gad = WebAppData.deserialize(
      JSON.parse(localStorage.getItem(gadVarName)!)
    );
    const currentTrip = gad.trips.find((trip: Trip) => trip.id === id);
    let offlineAttachments: OfflineAttachment[] = [];
    for (const [index, image] of data.photos.entries()) {
      let pid = await db.images.add({
        ...image,
        phase: MOBILE_TRIP_STATES.END,
        locked: 0,
        base64: await ConvertToBase64(image.image),
      });
      offlineAttachments.push(
        new OfflineAttachment(pid, image.type, MOBILE_TRIP_STATES.END)
      );
    }

    if (currentTrip) {
      currentTrip.end_net_weight = parseFloat(data.netWeight);
      currentTrip.end_gross_weight = parseFloat(data.grossWeight);
      currentTrip.end_tare_weight = parseFloat(data.tareWeight);
      currentTrip.end_kms = parseFloat(data.kms);
      currentTrip.end_coordinates = data.coords;
      currentTrip.state = TRIP_STATES.COMPLETED;
      currentTrip.offline_attachments =
        currentTrip.offline_attachments.concat(offlineAttachments);
      currentTrip.retry = true;
      currentTrip.start_datetime = currentTrip.start_datetime
        ? currentTrip.start_datetime.slice(0, 19)
        : new Date().toISOString().slice(0, 19);
      currentTrip.end_datetime = currentTrip.end_datetime
        ? currentTrip.end_datetime.slice(0, 19)
        : new Date().toISOString().slice(0, 19);
    }

    gad.trips.map((trip: Trip) => (trip.id === id ? currentTrip : trip));
    GADService.updateOfflineGAD(gad, "savePostTripData");
    return true;
  },

  updateErroredTrip: async (id: number, data: any) => {
    //mi prendo il gad dal localstorage
    const gad = WebAppData.deserialize(
      JSON.parse(localStorage.getItem(gadVarName)!)
    );
    //trovo il viaggio corrente
    let currentTrip = gad.trips.find((trip: Trip) => trip.id === id);
    //se il viaggio esiste lo aggiorno con i nuovi dati
    if (currentTrip) {
      currentTrip = { ...currentTrip, ...data, error: "" };
    }
    //rimuovo il viaggio dal gad e lo reinserisco con i nuovi dati
    gad.trips = gad.trips.filter((trip: Trip) => trip.id !== id);
    gad.trips.push(currentTrip!);

    //aggiorno il localstorage
    GADService.updateOfflineGAD(gad);
    return true;
  },

  updateTrip: async (trip: Trip): Promise<AxiosResponse<Trip>> => {
    let new_start_gross_weight = null;
    let new_end_gross_weight = null;

    if (trip.start_net_weight !== null && trip.start_tare_weight !== null) {
      new_start_gross_weight =
        parseFloat(trip.start_net_weight.toString()) +
        parseFloat(trip.start_tare_weight.toString());
    } else {
      new_start_gross_weight = 0.0;
    }

    if (trip.end_net_weight !== null && trip.end_tare_weight !== null) {
      new_end_gross_weight =
        parseFloat(trip.end_net_weight.toString()) +
        parseFloat(trip.end_tare_weight.toString());
    } else {
      new_end_gross_weight = 0.0;
    }

    let data = {
      id: trip.id,
      attachments_to_remove: trip.attachments
        .filter((at) => at.to_remove)
        .map((at) => at.id),
      start_coordinates: trip.start_coordinates,
      end_coordinates: trip.end_coordinates,
      state: trip.state,
      start_gross_weight: new_start_gross_weight,
      start_net_weight: trip.start_net_weight,
      start_tare_weight: trip.start_tare_weight,
      end_gross_weight: new_end_gross_weight,
      end_net_weight: trip.end_net_weight,
      end_tare_weight: trip.end_tare_weight,
      start_kms: trip.start_kms,
      end_kms: trip.end_kms,
      start_datetime: trip.start_datetime.slice(0, 23),
      end_datetime: trip.end_datetime.slice(0, 23),
    };

    return WebInstance.post(
      `${apiUrl}/update_trip`,
      data,
      HeaderBuilder.build()
    );
  },

  updatePhoto: async (): Promise<any> => {
    let attachments_start: IAttachmentToAdd[] = [];
    let attachments_end: IAttachmentToAdd[] = [];

    //select all the photos from the db
    const photos = await db.images.where("locked").equals(0).toArray();
    if (photos.length === 0) return false;
    let photo = photos[0];

    await db.images.update(photo.id!, { locked: 1 });
    if (photo.phase === MOBILE_TRIP_STATES.START) {
      attachments_start.push({
        type: photo.type!,
        base64:  photo.base64 === null || photo.base64 === undefined  || photo.base64 === '' ? await ConvertToBase64(photo.image) : photo.base64,
        // base64: await ConvertToBase64(photo.image),
      });
    } else {
      attachments_end.push({
        type: photo.type!,
        base64:  photo.base64 === null || photo.base64 === undefined  || photo.base64 === '' ? await ConvertToBase64(photo.image) : photo.base64,
        // base64: await ConvertToBase64(photo.image),
      });
    }

    return new Promise((resolve, reject) => {

      if(photo.tripId === undefined || photo.tripId === null) return resolve(true);

      WebInstance.post(
        `${apiUrl}/update_trip`,
        {
          id: photo.tripId!,
          attachments_to_add_start: attachments_start,
          attachments_to_add_end: attachments_end,
        },
        HeaderBuilder.build()
      )
        .then(() => {
          db.images.delete(photo.id!);
          resolve(true);
        })
        .catch(() => {
          console.log("Errore nell'upload della foto");
          db.images.update(photo.id!, { locked: 0 });
          reject();
        });
    });
  },

  getOldTrips: async (
    page: number,
    itemsPerPage: number
  ): Promise<{ total: number; data: Trip[] }> => {
    const rawRes = await WebInstance.get(
      `${apiUrl}/trip?page=${page}&page_size=${itemsPerPage}`,
      HeaderBuilder.build()
    );
    return {
      total: rawRes.data.total,
      data: rawRes.data?.items?.map((res) => Trip.deserialize(res)),
    };
  },

  getTrip: async (id: number) => {
    const rawRes = await WebInstance.get(
      `${apiUrl}/trip/${id}`,
      HeaderBuilder.build()
    );
    return Trip.deserialize(rawRes.data);
  },
};

//Offline methods

const getTripsOffline = () => {
  const data = localStorage.getItem(gadVarName);
  if (data) {
    return JSON.parse(data).trips.map((trip: any) => Trip.deserialize(trip));
  } else {
    return [];
  }
};

export default TripService;
