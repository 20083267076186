import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SuccessButton from "../../components/buttons/SuccessButton";
import { useEquipmentCartAtom, useIsOnlineAtom } from "../../context/store";
import EquipmentItemSimple from "../../components/listelements/EquipmentItemSimple";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
import "../flatpickr.css";
import { Equipment } from "../../interfaces/EquipmentInterface";
import EquipmentService from "../../services/EquipmentService";
import { toast } from "react-hot-toast";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import StandaloneAccordion from "../../components/accordions/StandaloneAccordion";
import LoadingButton from "../../components/buttons/LoadingButton";
import { DateTime } from "luxon";


function RiepilogoDotazioni() {
  const navigate = useNavigate();
  const [equipmentCart, setEquipmentCart] = useEquipmentCartAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom()

  const [date, setDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<number>(9);
  const [minutes, setMinutes] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddHour = () => {
    if (hours === 19) {
      setHours(9);
      return;
    }
    setHours((prev) => prev + 1);
  };

  const handleRemoveHour = () => {
    if (hours === 9) {
      setHours(19);
      return;
    }
    setHours((prev) => prev - 1);
  };

  const handleAddMinute = () => {
    if (minutes === 45) {
      setMinutes(0);
      return;
    }
    setMinutes((prev) => prev + 15);
  };

  const handleRemoveMinute = () => {
    if (minutes === 0) {
      setMinutes(45);
      return;
    }
    setMinutes((prev) => prev - 15);
  };

  useEffect(() => {
    if (equipmentCart.length === 0) {
      navigate("/dotazioni/new");
    }
  }, [equipmentCart]);

  const handleCreateRequest = () => {
    setIsLoading(true);

    let equipments: number[] = [];

    equipmentCart.forEach((e: Equipment) => {
      equipments.push(e.id);
    });

    if(!isOnline) {
      toast.error("Non sei connesso ad internet");
      setIsLoading(false);
      return;
    }

    EquipmentService.newEquipmentRequest({
      pickup_date: DateTime.fromJSDate(date).set({hour: hours-1, minute: minutes, second: 0}).toISO({ includeOffset: false }),
      equipments: equipments,
    })
      .then((response) => {
        toast((t) => (<Fragment>Invio richiesta di dotazione..<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
        navigate("/dotazioni");
        setEquipmentCart([]);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail || "Errore nell'invio della richiesta");
      })
      .finally(() => {
        setIsLoading(false);
      });
      
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label={"Riepilogo"} />
        <div className="mt-2"></div>
        {equipmentCart && equipmentCart.length > 0 && (
          <Fragment>
            <StandaloneAccordion
              title={"Dotazioni selezionate"}
              isOpen={true}
              children={
                <div className="mt-2">
                  {equipmentCart.map((e: any, index: number) => (
                    <EquipmentItemSimple equipment={e} index={index} />
                  ))}
                </div>
              }
            />
          </Fragment>
        )}
        <div className="mt-2"></div>
        <div className=" flex flex-row justify-center">
          <Flatpickr
            style={{ display: "none" }}
            data-enable-time
            options={{
              inline: true,
              locale: Italian,
              enableTime: false,
              minDate: "today",
            }}
            value={date}
            onChange={([date]) => {
              setDate(date);
            }}
          />
        </div>
        <div className=" flex flex-row justify-center items-center mt-2">
          <div className="flex flex-col justify-center items-center">
            <div className="text-zinc-600 dark:text-zinc-300 text-xl font-bold">
              Orario di ritiro
            </div>
          </div>
        </div>
        <div className=" flex flex-row justify-center items-center mt-6 mx-auto w-4/6">
          <div className="flex flex-col justify-center items-center w-full">
            <ChevronDoubleUpIcon
              className="h-6 w-6 text-zinc-500"
              onClick={() => {
                handleAddHour();
              }}
            />
            <div className="h-24 w-full border border-zinc-300 dark:border-zinc-700 rounded-xl mt-2">
              <div className="flex flex-col justify-center items-center h-full">
                <p className="font-bold text-3xl text-zinc-900 dark:text-zinc-200">
                  {hours < 10 && "0"}
                  {hours}
                </p>
              </div>
            </div>
            <ChevronDoubleDownIcon
              className="h-6 w-6 text-zinc-500 mt-2"
              onClick={() => {
                handleRemoveHour();
              }}
            />
          </div>
          <div className="mx-1" />
          <div className="flex flex-col justify-center items-center w-full">
            <ChevronDoubleUpIcon
              className="h-6 w-6 text-zinc-500"
              onClick={() => {
                handleAddMinute();
              }}
            />
            <div className="h-24 w-full border border-zinc-300 dark:border-zinc-700 rounded-xl mt-2">
              <div className="flex flex-col justify-center items-center h-full">
                <p className="font-bold text-3xl text-zinc-900 dark:text-zinc-200">
                  {minutes < 10 && "0"}
                  {minutes}
                </p>
              </div>
            </div>
            <ChevronDoubleDownIcon
              className="h-6 w-6 text-zinc-500 mt-2"
              onClick={() => {
                handleRemoveMinute();
              }}
            />
          </div>
        </div>
      </div>
      <div className={`w-full sticky bottom-0 mt-3`}>
        <div
          className={`grid ${isLoading ? "grid-cols-1" : "grid-cols-2"} gap-2`}
        >
          {isLoading ? (
            <LoadingButton />
          ) : (
            <Fragment>
              <SecondaryButton
                label="Indietro"
                onClick={() => {
                  navigate("/dotazioni/new");
                }}
              />
              <SuccessButton
                label={"Invia"}
                onClick={() => {
                  handleCreateRequest();
                }}
              />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default RiepilogoDotazioni;
