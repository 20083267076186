import { Equipment } from "./EquipmentInterface";

export class EquipmentRequest {
    id: number;
    planned_pickup_datetime: string;
    equipments: Equipment[];

    constructor(
        id: number,
        planned_pickup_datetime: string,
        equipments: Equipment[]
    ) {
        this.id = id;
        this.planned_pickup_datetime = planned_pickup_datetime;
        this.equipments = equipments;
    }

    static deserialize(input: any): EquipmentRequest {
        return new EquipmentRequest(
            input.id,
            input.planned_pickup_datetime,
            input.equipments.map((equipment: Equipment) => Equipment.deserialize(equipment))
        );
    }

    static getId(equipment_request: EquipmentRequest): number {
        return equipment_request.id;
    }
}
