import React, { useState } from "react";
import DateChip from "../chips/DateChip";
import {
  ArrowDownIcon,
  GlobeAltIcon,
  ShoppingCartIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FaTruck } from "react-icons/fa";
import SuccessButton from "../buttons/SuccessButton";
import DangerButton from "../buttons/DangerButton";
import { useNavigate } from "react-router-dom";
import { TRIP_STATES, Trip } from "../../interfaces/TripInterface";
import PrimaryButton from "../buttons/PrimaryButton";
import { useTripDetailDataAtom } from "../../context/store";

interface CardViaggiProps {
  index: number;
  data: Trip;
}

function CardViaggiPadroncini({ data, index }: CardViaggiProps) {
  const navigate = useNavigate();
  const [tripDetailData, setTripDetailData] = useTripDetailDataAtom();

  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - "}
          {data.supplier}
        </p>
        <DateChip date={data.date} />
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold mt-1 col-span-11">{data.departure}</p>
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <ArrowDownIcon className="h-6 w-6 mt-1 col-span-1" />
        <div className="col-span-11"></div>
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <GlobeAltIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold col-span-11 mt-1">{data.arrival}</p>
      </div>
      {data.vehicles.length > 0 && (
        <div className="grid grid-cols-12 gap-2 w-full mt-1">
          <FaTruck className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            {data.vehicles[0] ? data.vehicles[0] : ""} -{" "}
            {data.vehicles[1] ? data.vehicles[1] : ""}
          </p>
        </div>
      )}
      {data.cargo && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <ShoppingCartIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.cargo}</p>
        </div>
      )}
      {data.description && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.description}</p>
        </div>
      )}
      {data.retry && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <ExclamationTriangleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1 text-red-600 animate-pulse">
            {data.error !== "" ? data.error : "In attesa di sincronizzazione con Chiarcosso"}
          </p>
        </div>
      )}
      {data.state === TRIP_STATES.TODO && (
        <SuccessButton
          label="Inizia viaggio"
          onClick={() => {
            setTripDetailData(data);
            navigate("/padroncini/registrapesata/start");
          }}
        />
      )}
      {data.state === TRIP_STATES.IN_PROGRESS && (
        <div className={`grid ${data.retry ? "grid-cols-2 gap-4" : "grid-cols-1"}`}>
          {data.retry && (
            <PrimaryButton
              label="Info / Modifica"
              onClick={() => {
                setTripDetailData(data);
                navigate("/trips/details/" + data.id);
              }}
            />
          )}
          <DangerButton
            label="Fine viaggio"
            onClick={() => {
              setTripDetailData(data);
              navigate("/padroncini/registrapesata/end");
            }}
          />
        </div>
      )}
      {data.state === TRIP_STATES.COMPLETED && (
        <PrimaryButton
          label={data.retry ? "Info / Modifica" : "Info"}
          onClick={() => {
            setTripDetailData(data);
            navigate("/trips/details/" + data.id);
          }}
        />
      )}
    </div>
  );
}

export default CardViaggiPadroncini;
