import React, { Fragment, Key, useState } from "react";
import Title from "../components/typography/Title";
import Subtitle from "../components/typography/Subtitle";
import SelectServer from "../utils/SelectServer";
import SecondaryButton from "../components/buttons/SecondaryButton";
import { Tab, Tabs } from "@nextui-org/react";

function HowToInstall() {
  const [view, setView] = useState<boolean>(false);
  const [selected, setSelected] = useState<Key>("iOS");

  return (
    <div className="overflow-y-scroll z-20 p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out max-h-[98dvh] h-[98dvh]">
      <div className="h-full flex flex-col justify-between">
        <div>
          <Title label="Come installare l'app." />
          <Tabs
            fullWidth
            size="md"
            aria-label="Tabs form"
            selectedKey={selected}
            onSelectionChange={(key: Key) => {
              setSelected(key);
              if (key === "iOS") {
                setView(true);
              } else {
                setView(false);
              }
            }}
          >
            <Tab key="iOS" title="iOS" className="font-bold">
              <Fragment>
                <div className="mt-4" />

                <Subtitle label="iOS" />
                <div className="mt-4" />
                <p>
                  La prima cosa da fare è cliccare sul pulsante in basso al
                  centro, quello con il simbolo del quadrato con la freccia
                  verso l'alto.
                </p>
                <div className="mt-4" />
                <img
                  src={SelectServer.getImg() + "howto/ios/howto_1.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">Dopo apparirà una sezione come questa</p>
                <img
                  src={SelectServer.getImg() + "howto/ios/howto_2.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Dovrai scorrere fino a trovare la voce{" "}
                  <span className="font-bold">Aggiungi a schermata home</span>
                </p>
                <img
                  src={SelectServer.getImg() + "howto/ios/howto_3.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Una volta trovata cliccaci sopra e apparirà un'altra finestra
                </p>
                <img
                  src={SelectServer.getImg() + "howto/ios/howto_4.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  In questa schermata non modificare nulla, basterà cliccare sul
                  pulsante{" "}
                  <span className="font-bold text-blue-600">Aggiungi</span> in
                  alto a destra
                  <br />
                  Una volta fatto verrai portato alla{" "}
                  <span className="font-bold">homescreen</span> del tuo iPhone e
                  vedrai un'icona come le altre app ma con il logo di{" "}
                  <span className="font-bold text-red-600">Chiarcosso.</span>
                </p>
                <img
                  src={SelectServer.getImg() + "howto/ios/howto_5.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Complimenti! Hai installato l'app con successo!
                </p>

                <div className="mt-4" />
              </Fragment>
            </Tab>
            <Tab key="Android" title="Android" className="font-bold">
              <Fragment>
                <Subtitle label="Android" />
                <div className="mt-4" />
                <div className="p-4 my-2 rounded-xl flex flex-col justify-start items-start bg-red-500 text-white">
                  <h3 className="text-xl font-bold">ATTENZIONE</h3>
                  <p>
                    Gli screenshot presenti in questa pagina sono stati fatti su
                    un <span className="font-bold">Oneplus</span>.
                  </p>
                  <p>
                    Le interfacce per le diverse marche di dispositivi
                    potrebbero essere leggermente differenti
                  </p>
                </div>
                <p>
                  La prima cosa da fare è cliccare sui tre pallini in alto a
                  destra presenti nella schermata di{" "}
                  <span className="font-bold">Chrome</span>.
                </p>
                <div className="mt-4" />
                <img
                  src={SelectServer.getImg() + "howto/android/howto_1.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">Dopo apparirà una sezione come questa</p>
                <img
                  src={SelectServer.getImg() + "howto/android/howto_2.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Cliccare su{" "}
                  <span className="font-bold">Aggiungi a schermata home</span>.
                </p>
                <p className="my-4">
                  Una volta fatto apparirà una finestra come questa
                </p>
                <img
                  src={SelectServer.getImg() + "howto/android/howto_3.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Qui non modificare nulla, basterà cliccare su{" "}
                  <span className="font-bold text-sky-500">Aggiungi</span>
                </p>
                <p className="my-4">
                  Talvolta potrebbe apparire un'altra finestra, come nel caso di
                  questo particolare dispositivo.
                </p>
                <p className="my-4">
                  Eventualmente, cliccare sempre sull'opzione che suggerisce
                  l'aggiunta dell'app
                </p>
                <img
                  src={SelectServer.getImg() + "howto/android/howto_4.jpg"}
                  className="rounded-xl"
                />
                <p>
                  Una volta fatto verrai portato alla{" "}
                  <span className="font-bold">homescreen</span> del tuo telefono
                  e vedrai un'icona come le altre app ma con il logo di{" "}
                  <span className="font-bold text-red-600">Chiarcosso.</span>
                </p>
                <img
                  src={SelectServer.getImg() + "howto/android/howto_5.jpg"}
                  className="rounded-xl"
                />
                <p className="my-4">
                  Complimenti! Hai installato l'app con successo!
                </p>

                <div className="mt-4" />
              </Fragment>
            </Tab>
          </Tabs>
        </div>
        <div className={`w-full sticky bottom-0 mt-3`}>
          <SecondaryButton
            label="Torna indietro"
            onClick={() => window.history.back()}
          />
        </div>
      </div>
    </div>
  );
}

export default HowToInstall;
