import { AvailableVehicle } from "./AvailableVehiclesInterface";
import { Equipment } from "./EquipmentInterface";
import { ReportType } from "./ReportTypeInterface";
import { Report } from "./ReportInterface";
import { Trip } from "./TripInterface";
import { EquipmentRequest } from "./EquipmentRequestInterface";
import { Vacation } from "./VacationInterface";
import { Leave } from "./LeaveInterface";

export class WebAppData {
    trips: Trip[];
    available_vehicles: AvailableVehicle[];
    equipments: Equipment[];
    equipment_requests: EquipmentRequest[];
    reports: Report[];
    report_types: ReportType[];
    vacations: Leave[];

    constructor(
        trips: Trip[],
        available_vehicles: AvailableVehicle[],
        equipments: Equipment[],
        equipment_requests: EquipmentRequest[],
        reports: Report[],
        report_types: ReportType[],
        vacations: Leave[]
    ) {
        this.trips = trips;
        this.available_vehicles = available_vehicles;
        this.equipments = equipments;
        this.equipment_requests = equipment_requests;
        this.reports = reports;
        this.report_types = report_types;
        this.vacations = vacations;
    }

    static deserialize(input: any): WebAppData {
        return new WebAppData(
            input.trips ? input.trips.map((trip: Trip) => Trip.deserialize(trip)) : [],
            input.available_vehicles ? input.available_vehicles.map((available_vehicles: AvailableVehicle) => AvailableVehicle.deserialize(available_vehicles)) : [],
            input.equipments ? input.equipments.map((equipment: Equipment) => Equipment.deserialize(equipment)) : [] ,
            input.equipment_requests ? input.equipment_requests.map((equipment_request: EquipmentRequest) => EquipmentRequest.deserialize(equipment_request)) : [],
            input.reports ? input.reports.map((report: Report) => Report.deserialize(report)) : [],
            input.report_types ? input.report_types.map((report_type: ReportType) => ReportType.deserialize(report_type)) : [],
            input.vacations ? input.vacations.map((vacation: Leave) => Leave.deserialize(vacation)) : []
        );
    }
}