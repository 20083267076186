import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SuccessButton from "../../components/buttons/SuccessButton";
import SimpleStepper from "../../components/steppers/SimpleStepper";
import { Equipment } from "../../interfaces/EquipmentInterface";
import EquipmentItem from "../../components/listelements/EquipmentItem";
import Title from "../../components/typography/Title";
import SimpleAccordion from "../../components/accordions/SimpleAccordion";
import StandaloneAccordion from "../../components/accordions/StandaloneAccordion";
import TextInput from "../../components/inputs/TextInput";

function RichiestaDotazioni() {
  const navigate = useNavigate();
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [filteredEquipments, setFilteredEquipments] = useState<Equipment[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const handlePrevStep = () => {
    setSearchValue('');
    if (currentStep > 0) setCurrentStep(currentStep - 1);
    else navigate("/dotazioni");
  };

  const handleNextStep = () => {
    setSearchValue('');
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    else navigate("/dotazioni/recap");
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("chiarcosso_gad")!)) {
      let equip = JSON.parse(
        localStorage.getItem("chiarcosso_gad")!
      ).equipments;
      setEquipments(equip);
      setFilteredEquipments(equip);
      setSteps(equip.map((e: any) => e.name));
    }
  }, []);

  useEffect(() => {
    if(equipments[currentStep]?.childs != undefined){
      const filteredChilds = equipments[currentStep]?.childs.filter((equipment) => {
        return equipment.name.toLowerCase().includes(searchValue.toLowerCase());
      });
  
      const updatedEquipments = equipments.map((equipment, index) => {
        if (index === currentStep) {
          return {
            ...equipment,
            childs: filteredChilds
          };
        }
        return equipment;
      });
      setFilteredEquipments(updatedEquipments);
  
    }
  }, [searchValue]);

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label={steps[currentStep]} />
        <div className="mt-2"></div>
        <SimpleStepper steps={steps} currentStep={currentStep} />
        <TextInput
          name="searchvalue"
          placeholder="Cerca..."
          value={searchValue}
          onChange={setSearchValue}
        />
        <div className="mt-2"></div>
        {filteredEquipments &&
          filteredEquipments[currentStep]?.childs?.map(
            (e: Equipment, index: number) => (
              <Fragment key={index}>
                <StandaloneAccordion
                  title={e.name}
                  children={<CategoryCard dotazione={e} />}
                />
              </Fragment>
            )
          )}
      </div>
      <div className={`w-full sticky bottom-0 mt-3`}>
        <div className="grid grid-cols-2 gap-2">
          <SecondaryButton
            label="Indietro"
            onClick={() => {
              handlePrevStep();
            }}
          />
          <SuccessButton
            label={currentStep === steps.length - 1 ? "Riepilogo" : "Avanti"}
            onClick={() => {
              handleNextStep();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RichiestaDotazioni;

interface CategoryCardProps {
  dotazione: Equipment;
}

function CategoryCard({ dotazione }: CategoryCardProps) {
  return (
    <Fragment key={dotazione.id}>
      {dotazione.childs.length ? (
        <Fragment>
          {dotazione.childs.map((childDotazione: Equipment) => (
            <Fragment key={childDotazione.id}>
              <CategoryCard dotazione={childDotazione} />
            </Fragment>
          ))}
        </Fragment>
      ) : (
        <div>
          <EquipmentItem equipment={dotazione} index={1} />
        </div>
      )}
    </Fragment>
  );
}
