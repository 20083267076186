import React from "react";
import DateChip from "../chips/DateChip";
import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Report } from "../../interfaces/ReportInterface";
import CardImageValidated from "../images/CardImageValidated";
import PrimaryButton from "../buttons/PrimaryButton";
import { useIsOnlineAtom } from "../../context/store";

interface CardSegnalazioneProps {
  index: number;
  data: Report;
}

function CardSegnalazioneOffline({ data, index }: CardSegnalazioneProps) {
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - "}
          {"Segnalazione"}
        </p>
        <DateChip date={data.date.substring(0, 10)} />
      </div>
      {data.report_type && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2 text-red-500 animate-pulse">
          <QuestionMarkCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            In sincronizzazione
          </p>
        </div>
      )}
      {data.description && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.description}</p>
        </div>
      )}
      {data.attachments.length > 0 && (
        <CardImageValidated url={data.attachments[0].url} />
      )}
    </div>
  );
}

export default CardSegnalazioneOffline;
