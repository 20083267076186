import HeaderBuilder from "../utils/HeaderBuilder";
import SelectServer from "../utils/SelectServer";
import WebInstance from "../utils/AxiosInstance";

const apiUrl = SelectServer.getServer()
const gadVarName = "chiarcosso_gad";

const EquipmentService = {
    getEquipments: async () => {
        const response = WebInstance.get(`${apiUrl}/equipment`, HeaderBuilder.build())
        return response
    },
    
    getEquipment: async (id: number) => {
        const response = WebInstance.get(`${apiUrl}/equipment/${id}`, HeaderBuilder.build())
        return response
    },

    getEquipmentRequests: async () => {
        if(window.navigator.onLine){
            const response = WebInstance.get(`${apiUrl}/equipment/requests`, HeaderBuilder.build())
            return response
        }

        const data = localStorage.getItem(gadVarName)
        if(data){
            let builder = JSON.parse(data)
            return {data: builder.equipment_requests}
        }
    },

    newEquipmentRequest: async (data: Object) => {
        const response = WebInstance.post(`${apiUrl}/equipment/request`, data, HeaderBuilder.build())
        return response
    },


};

export default EquipmentService;