import React from "react";

interface IconButtonProps {
  icon: any;
  iconTransform: any;
  onClick: () => void;
  selected?: boolean;
}

function IconButton({ icon, iconTransform, onClick, selected }: IconButtonProps) {
  return (
    <button
      className={`${selected ? "bg-red-500 hover:bg-red-400" : "bg-[#0385FF] hover:bg-blue-400"} text-white flex flex-row justify-center align-center relative w-full h-14 p-4 rounded-xl transition-all duration-300 ease-in-out`}
      onClick={() => {
        onClick();
      }}
    >
      <div className="absolute" style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
        {icon}
      </div>
      <div className="absolute" style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
        {iconTransform}
      </div>
    </button>
  );
}

export default IconButton;
