import React, { Fragment } from "react";
import { Equipment } from "../../interfaces/EquipmentInterface";
import DateTime from "luxon";
import { ClockIcon } from "@heroicons/react/24/outline";
import StandaloneAccordion from "../accordions/StandaloneAccordion";

interface CardEquipmentRequestProps {
  index: number;
  data: DataInProps;
}

interface DataInProps {
  id: number;
  planned_pickup_datetime: string;
  equipments: Equipment[];
}

function CardEquipmentRequest({ data, index }: CardEquipmentRequestProps) {
  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - Richiesta Dotazione"}
        </p>
      </div>
      <StandaloneAccordion title="Dotazioni richieste" helper={true} children={
        <div className="flex flex-col">
          {data.equipments.map((equipment, index) => (
            <div key={index} className="p-2 border border-zinc-400 rounded-xl my-1">
              <div><p className="font-bold text-xl mt-1">{equipment.name}</p></div>
              <ul className="ml-4">
                {equipment.childs.map((child, index) => (
                  <li key={index}>
                    <p className="font-semibold mt-1">{child.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      } />
      <div className="rounded-xl w-full p-2 bg-[#38BDF8] text-white flex flex-col justify-center items-center mt-1">
        <h1 className="text-xl font-bold inline-flex items-center">
          <span className="mr-1">
            <ClockIcon className="h-5 w-5 text-white" />
          </span>
          Ritiro previsto
        </h1>
        {new Date().toLocaleDateString("it-IT") ===
        new Date(data.planned_pickup_datetime).toLocaleDateString("it-IT") ? (
          <span className="text-4xl font-bold tracking">Oggi</span>
        ) : (
          <h1 className="text-2xl font-bold">
            {new Date(data.planned_pickup_datetime).toLocaleDateString("it-IT") + " " + new Date(data.planned_pickup_datetime).toLocaleTimeString("it-IT").substring(0, 5)}
          </h1>
        )}
      </div>
    </div>
  );
}

export default CardEquipmentRequest;
