import React, { useEffect } from "react";

interface TripCardDividerProps {
    date: string;
}

function TripCardDivider({date}: TripCardDividerProps) {
  return (
    <div className="flex flex-row items-center w-full px-2 my-3">
      <div className="h-0.5 grow bg-zinc-900 dark:bg-zinc-200 rounded-full"></div>
      <p className="ml-2 grow-1 font-semibold text-zinc-900 dark:text-zinc-200">
        {date}
      </p>
    </div>
  );
}

export default TripCardDivider;
