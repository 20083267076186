import { Outlet, Navigate } from "react-router-dom";
import AuthService from "../services/AuthService";

const PrivateRoutes = () => {
  let auth = false;
  try {
    auth = !!AuthService.getUserData().sessionId;
  } catch (e) {
    AuthService.logout();
  }

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
