import React, { useState, useEffect, Fragment } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import { Trip } from "../../interfaces/TripInterface";
import NoData from "../../components/NoData";
import TripCardDivider from "../../components/cards/TripCardDivider";
import { useGADAtom } from "../../context/store";
import PadronciniTripService from "../../services/PadronciniTripService";
import CardViaggiPadroncini from "../../components/cards/CardViaggiPadroncini";

function ViaggiPadroncini() {
  const [GAD, setGAD] = useGADAtom();
  const [trips, setTrips] = useState<Trip[]>([]); //Tutti i viaggi
  const [isLoading, setIsLoading] = useState<boolean>(true); //Gestisce la visualizzazione del loading dei viaggi nuovi  e in corso

  useEffect(() => {
    PadronciniTripService.getTripsPadroncini().then((trips) => {
      setTrips(trips);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error)
    });
  }, [GAD]);

  return (
    <div className="h-[95dvh] flex flex-col justify-between">
      <Fragment>
        <div>
          <TitleWithMenuIcon hasSupport={true} hideMenuBtn={true} label="Viaggi" />
          <Fragment>
            {trips.length > 0 &&
              trips.map((trip, index) => (
                <Fragment key={index}>
                  {trip.date !== trips[index - 1]?.date && (
                    <TripCardDivider date={trip.date} />
                  )}
                  <CardViaggiPadroncini key={index} data={trip} index={index} />
                </Fragment>
              ))}
            {trips.length === 0 && (
              <NoData
                label="Non ci sono viaggi al momento..."
                hasRefresh={true}
              />
            )}
          </Fragment>
        </div>
      </Fragment>
    </div>
  );
}

export default ViaggiPadroncini;
