import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { useIsOnlineAtom } from "../context/store";

function Loading() {
  const [isOnline, setIsOnline] = useIsOnlineAtom()

  return (
    <div className="w-full h-full flex flex-col justify-center">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center">
          {isOnline && (
            <Player
              autoplay
              loop
              src="loading.json"
              style={{ height: "300px", width: "300px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          )}
          <img
            src="/logo.png"
            alt="logo"
            className={`transition-all duration-300 ease-in-out animate-pulse`}
          />
        </div>
      </div>
    </div>
  );
}

export default Loading;
