import React, {useEffect, useState} from "react";
import { Equipment } from "../../interfaces/EquipmentInterface";
import IconButton from "../buttons/IconButton";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEquipmentCartAtom } from "../../context/store";

interface EquipmentItemProps{
    equipment: Equipment;
    index: number;
}

function EquipmentItem({equipment, index}: EquipmentItemProps) {
  const [selected, setSelected] = useState(false);

  const [equipmentCart, setEquipmentCart] = useEquipmentCartAtom();

  useEffect(() => {
    if (equipmentCart.find((e: Equipment) => e.id === equipment.id)) setSelected(true);
    else setSelected(false);
  }, [equipmentCart]);

  const handleAddEquipment = () => {
    if (!selected) {
      setEquipmentCart([...equipmentCart, equipment]);
    } else {
      setEquipmentCart(equipmentCart.filter((e: Equipment) => e.id !== equipment.id));
    }
  };


  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full border ${selected ? "text-green-500 dark:text-green-400" : "text-zinc-900 dark:text-zinc-200"} border-zinc-300 dark:border-zinc-700  p-2 rounded-xl font-semibold grid grid-cols-12 pl-3 transition-all duration-300 ease-in-out`}
    >
      <div className="col-span-10 flex flex-col justify-center text-xl">{equipment.name}</div>
      <div className="col-span-2 text-right">
        <IconButton
          selected={selected}
          onClick={() => {handleAddEquipment()}}
          icon={<PlusIcon className={`h-5 w-5 text-white ${selected ? "rotate-180 opacity-0" : "opacity-100"} transition-all duration-300 ease-in-out`}/>}
          iconTransform={<TrashIcon className={`h-5 w-5 text-white ${selected ? "rotate-0 opacity-100" : "-rotate-180 opacity-0"} transition-all duration-300 ease-in-out`}/>}
        />
      </div>
    </div>
  );
}

export default EquipmentItem;
