import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function InstallAppModal({open, setOpen}: Props) {
  const navigate = useNavigate();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-500 dark:bg-zinc-950 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-zinc-900 dark:text-zinc-200">
                      Installa l'app per un'esperienza migliore!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-zinc-500">
                        Clicca qui sotto per saperne di piu su come fare!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 grid grid-cols-2 gap-4">
                  <SecondaryButton label="Chiudi" onClick={() => setOpen(false)} />
                  <PrimaryButton label="Scopri di più" onClick={() => navigate('/howto')} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default InstallAppModal