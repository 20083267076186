import React from 'react'
import LoadingButton from './LoadingButton';

interface SuccessButtonProps {
  label?: string;
  icon?: any;
  position?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

function SuccessButton({ label, icon, position, isLoading, onClick, isDisabled }: SuccessButtonProps) {

  if(isLoading) return (
    <LoadingButton label={"Caricamento..."}  />
  )

  return (
    <button disabled={isDisabled} className="bg-green-500 hover:bg-green-400 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 disabled:bg-zinc-700 disabled:hover:bg-zinc-700 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className="font-semibold">{label ? label : "Success"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 ml-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  )
}

export default SuccessButton