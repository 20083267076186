const SelectServer = {
    getServer: () => {
        switch (window.location.hostname) {
            case "localhost":
                return "https://pwa.chiarcosso.com/app";
            case "10.10.16.77":
                return "https://pwa.chiarcosso.com/app";
            case "staging-app.chiarcosso.com": //netlify id: a69bed34-6ffc-4217-a572-ab59a37689a3
                return "https://pwa.chiarcosso.com/app";
            case "app.chiarcosso.com": //netlify id: d8f0e29b-2e9e-4b74-867e-21c5ad44acd8
                return "https://odoo.chiarcosso.com/app";
            default:
                return "https://pwa.chiarcosso.com/app";
        }
    },

    getImg: () => {
        switch (window.location.hostname) {
            case "staging-app.chiarcosso.com":
                return "https://staging-app.chiarcosso.com/";
            case "app.chiarcosso.com":
                return "https://app.chiarcosso.com/";
            default:
                return "http://"+window.location.hostname+":3000/";
        }
    },

    getServerImg: () => {
        switch (window.location.hostname) {
            case "localhost":
                return "https://pwa.chiarcosso.com";
            case "10.10.16.77":
                return "https://pwa.chiarcosso.com";
            case "staging-app.chiarcosso.com": //netlify id: a69bed34-6ffc-4217-a572-ab59a37689a3
                return "https://pwa.chiarcosso.com";
            case "app.chiarcosso.com": //netlify id: d8f0e29b-2e9e-4b74-867e-21c5ad44acd8
                return "https://odoo.chiarcosso.com";
            default:
                return "https://pwa.chiarcosso.com";
        }
    },
}

export default SelectServer;