import { useState, useEffect, Fragment, Key } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import CardViaggi from "../../components/cards/CardViaggi";
import { TRIP_STATES, Trip } from "../../interfaces/TripInterface";
import TripService from "../../services/TripService";
import NoData from "../../components/NoData";
import TripCardDivider from "../../components/cards/TripCardDivider";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGADAtom, useIsOnlineAtom } from "../../context/store";
import { Tabs, Tab } from "@nextui-org/react";
import RemainingItemsToSync from "../../components/notifications/RemainingItemsToSync";

function Viaggi() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [GAD, setGAD] = useGADAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [trips, setTrips] = useState<Trip[]>([]); //Tutti i viaggi
  const [isLoading, setIsLoading] = useState<boolean>(true); //Gestisce la visualizzazione del loading dei viaggi nuovi  e in corso
  const [isLoadingCompleted, setIsLoadingCompleted] = useState<boolean>(true); //Gestisce la visualizzazione del loading dei viaggi completati
  const [oldTrips, setOldTrips] = useState<Trip[]>([]); //I viaggi che sono finiti e non sono di oggi
  const [viewOldTrips, setViewOldTrips] = useState<boolean>(false); //Gestisce la visualizzazione dei viaggi passati
  const [selected, setSelected] = useState<Key>("attuali");

  const [page, setPage] = useState<number>(1);
  const [currentItems, setCurrentItems] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [lastPage, setlastPage] = useState<boolean>(false);

  useEffect(() => {
    TripService.getTrips().then((trips) => {
      setTrips(trips);
      setIsLoading(false);
    });
  }, [GAD]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      if (
        searchParams.get("tab") === "attuali" ||
        searchParams.get("tab") === "passati"
      ) {
        setSelected(searchParams.get("tab") as Key);
        setViewOldTrips(searchParams.get("tab") === "passati");
      }
    } else {
      setSearchParams({ tab: "attuali" });
    }
  }, []);

  useEffect(() => {
    if (viewOldTrips) {
      setIsLoadingCompleted(false);
      TripService.getOldTrips(page, itemsPerPage)
        .then((trips) => {
          let oldTrips = trips.data.filter(
            (trip: Trip) => trip.state === TRIP_STATES.COMPLETED
          );
          setOldTrips(oldTrips);
          setTotalItems(trips.total);
          setCurrentItems(
            itemsPerPage < trips.total ? itemsPerPage * page : trips.total
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingCompleted(true);
        });
    }
  }, [viewOldTrips, page]);

  const handleNextPage = () => {
    if (itemsPerPage * (page + 1) > totalItems) {
      if(lastPage){
        setPage(1);
        setCurrentItems(10);
        setlastPage(false);
      }else{
        setPage(page + 1);
        setCurrentItems(totalItems);
        setlastPage(true);
      }
      return;
    } else {
      setCurrentItems(itemsPerPage * (page + 1));
      setPage(page + 1);
      return;
    }
  };

  const handlePrevPage = () => {
    if (page - 1 > 1) {
      setCurrentItems(itemsPerPage * (page - 1));
      setPage(page - 1);
    }

    if (page - 1 === 1) {
      setCurrentItems(itemsPerPage < totalItems ? itemsPerPage : totalItems);
      setPage(1);
    }
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <Fragment>
        <div>
          <TitleWithMenuIcon label="Viaggi" wifi={true} />
          <Tabs
            fullWidth
            size="md"
            aria-label="Tabs form"
            selectedKey={selected}
            onSelectionChange={(key: Key) => {
              setSelected(key);
              if (key === "passati") {
                setViewOldTrips(true);
                setSearchParams({ tab: "passati" });
              } else {
                setViewOldTrips(false);
                setSearchParams({ tab: "attuali" });
              }
            }}
          >
            <Tab key="attuali" title="Attuali" className="font-bold">
              <Fragment>
                <RemainingItemsToSync />
                {trips.length > 0 &&
                  trips.map((trip, index) => (
                    <Fragment key={index}>
                      {trip.date.trim() !== trips[index - 1]?.date.trim() && (
                        <TripCardDivider date={trip.date} />
                      )}
                      <CardViaggi key={index} data={trip} index={index} />
                    </Fragment>
                  ))}
                {trips.length === 0 && (
                  <NoData
                    label="Non ci sono viaggi al momento..."
                    hasRefresh={true}
                  />
                )}
              </Fragment>
            </Tab>
            <Tab key="passati" title="Passati" className="font-bold">
              <Fragment>
                {isOnline ? (
                  <Fragment>
                    {oldTrips.length > 0 &&
                      oldTrips.map((trip, index) => (
                        <Fragment key={index}>
                          {trip.date.trim() !==
                            oldTrips[index - 1]?.date.trim() && (
                            <TripCardDivider date={trip.date} />
                          )}
                          <CardViaggi key={index} data={trip} index={index} />
                        </Fragment>
                      ))}
                    {oldTrips.length === 0 && (
                      <NoData label="Non ci sono viaggi passati..." />
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <NoData label="Oh no, niente internet..." />
                  </Fragment>
                )}
              </Fragment>
            </Tab>
          </Tabs>
        </div>
        {viewOldTrips && isOnline && (
          <div className="w-full sticky bottom-0 mt-3 bg-white dark:bg-zinc-900">
            <div className="grid grid-cols-3 gap-2">
              <PrimaryButton
                label="Indietro"
                onClick={() => {
                  handlePrevPage();
                }}
              />
              <div className="flex flex-col w-full justify-center items-center">
                {isLoadingCompleted && (
                  <Fragment>
                    {lastPage ? totalItems : currentItems} / {totalItems}
                  </Fragment>
                )}
              </div>
              <PrimaryButton
                label="Avanti"
                onClick={() => {
                  handleNextPage();
                }}
              />
            </div>
          </div>
        )}
      </Fragment>
    </div>
  );
}

export default Viaggi;
