import React from "react";

interface SimpleStepperProps {
  steps: string[];
  currentStep: number;
}

function SimpleStepper({ steps, currentStep }: SimpleStepperProps) {
  return (
    <div className="w-full flex flex-row flex-nowrap justify-between items-start">
      {steps.map((step, index) => (
        <div className="flex flex-col w-full items-center justify-center mx-1">
          <div className={`w-full h-1 rounded-full ${index <= currentStep ? "bg-green-400" : "bg-zinc-400 dark:bg-zinc-700"} transition-all duration-500 ease-in-out`} />
        </div>
      ))}
    </div>
  );
}

export default SimpleStepper;
