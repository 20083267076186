import React from 'react'

interface SecondaryButtonProps {
  label?: string;
  icon?: any;
  position?: string;
  onClick: () => void;
}

function SecondaryButton({ label, icon, position, onClick }: SecondaryButtonProps) {  
  return (
    <button className="bg-zinc-200 hover:bg-zinc-100 text-black dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:text-zinc-200 flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className="font-semibold">{label ? label : "Secondary"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 ml-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  )
}

export default SecondaryButton