import Dexie, {Table} from 'dexie';
import { ATTACHMENT_TYPE } from '../interfaces/TripInterface';
import { ConvertToBase64 } from '../utils/ConvertToBase64';



export interface Image {
    id?: number;
    image: File;
    tripId?: string;
    type?: ATTACHMENT_TYPE;
    phase?: string;
    locked?: number;
    base64?: string;
}

export interface ReportImage {
    id?: number;
    image: string;
    reportId?: string;
    locked?: number;
    base64?: string;
}


export class ImagesTable extends Dexie {
    images!: Table<Image, number>;
    report_images!: Table<ReportImage, number>;

    constructor() {
        super("chiarcosso_db");

        this.version(1).stores({
            images: "++id,image,tripId,type,phase,locked",
            report_images: "++id,image,reportId,locked"
        });

        this.version(2).stores({
            images: "++id,image,tripId,type,phase,locked,base64",
            report_images: "++id,image,reportId,locked,base64"
        }).upgrade(async (trans) => {
            trans.table('images').toCollection().modify(async (item) => {
                item.base64 = await ConvertToBase64(item.image);
            });
            trans.table('report_images').toCollection().modify(async (item) => {
                item.base64 = await ConvertToBase64(item.image);
            });
        });
    }
}

export const db = new ImagesTable();