import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosRequestConfig,
  CancelTokenSource,
} from "axios";
import SelectServer from "./SelectServer";
import HeaderBuilder from "./HeaderBuilder";
import { toast } from "react-hot-toast";

let cancelTokenSources: CancelTokenSource[] = [];

const lsTokenVarName = "chiarcosso_token";

const WebInstance = axios.create({
  baseURL: SelectServer.getServer(),
  //timeout: 30000,
  headers: HeaderBuilder.build().headers,
});

const logOnDev = (message: string) => {
  if (window.location.hostname !== "app.chiarcosso.com") {
  }
};

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { method, url, headers } = config;

  if (!localStorage.getItem(lsTokenVarName) && !config.headers["Skip-Auth"]) {
    resetAuth();
  }

  delete headers["Skip-Auth"];

  config.headers.set({ ...HeaderBuilder.build().headers, ...headers });

  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`);
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { method, url } = response.config;
  const { status } = response;
  // Set Loading End Here
  // Handle Response Data Here
  // Error Handling When Return Success with Error Code Here
  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);

  return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { statusText, status } = (error.response as AxiosResponse) ?? {};

    logOnDev(
      `🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`
    );

    switch (status) {
      case 400: {
        // toast.error(error?.response?.data?.detail);
        break;
      }
      case 401: {
        toast("Sessione scaduta");
        resetAuth();
        break;
      }
      case 403: {
        //error("Permesso negato");
        break;
      }
      case 404: {
        //error("Risorsa non trovata");
        break;
      }
      case 500: {
        //error("Errore interno del server");
        break;
      }
      default: {
        //error("Errore sconosciuto");
        break;
      }
    }
  } else {
    logOnDev(`🚨 [API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};

const setupInterceptors = (WebInstance: AxiosInstance): AxiosInstance => {
  WebInstance.interceptors.request.use((config) => {
    // Create a new CancelToken
    const cancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelTokenSource.token;
  
    // Add the CancelTokenSource to the array
    cancelTokenSources.push(cancelTokenSource);
  
    return config;
  });
  WebInstance.interceptors.request.use(onRequest, onErrorResponse);
  WebInstance.interceptors.response.use(onResponse, onErrorResponse);

  return WebInstance;
};

const resetAuth = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export function cancelAllRequests() {
  for (const cancelTokenSource of cancelTokenSources) {
    cancelTokenSource.cancel('Operation canceled manually.');
  }

  // Clear the array
  cancelTokenSources = [];
}

setupInterceptors(WebInstance);

export default WebInstance;
