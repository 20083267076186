import { AvailableVehicle } from "./AvailableVehiclesInterface";
import { OfflineReportAttachment } from "./OfflineReportAttachmentInterface";
import { ReportType } from "./ReportTypeInterface";
import { UploadedAttachment } from "./UploadedAttachmentInterface";

export class Report {
  id: number;
  date: string;
  report_type: ReportType;
  plate: AvailableVehicle;
  description: string;
  attachments: UploadedAttachment[];
  offline_attachments: OfflineReportAttachment[];
  retry: boolean = false;
  vehicle: AvailableVehicle | null = null;
  app_create_date?: string = null;
  appid?: string = null;
  error?: string;

  constructor(
    id: number,
    date: string,
    report_type: ReportType,
    plate: AvailableVehicle,
    description: string,
    attachments: UploadedAttachment[],
    offline_attachments: OfflineReportAttachment[] = [],
    retry: boolean = false,
    vehicle: AvailableVehicle | null = null,
    app_create_date: string = null,
    appid: string = null,
    error: string = ""
  ) {
    this.id = id;
    this.date = date;
    this.report_type = report_type;
    this.plate = plate;
    this.description = description;
    this.attachments = attachments;
    this.offline_attachments = offline_attachments;
    this.retry = retry;
    this.vehicle = vehicle;
    this.app_create_date = app_create_date;
    this.appid = appid;
    this.error = error;
  }

  static deserialize(input: any): Report {
    return new Report(
      input.id,
      input.date,
      input.report_type,
      input.plate ? AvailableVehicle.deserialize(input.plate) : null,
      input.description,
      input.attachments ? input.attachments.map((attachment: UploadedAttachment) => UploadedAttachment.deserialize(attachment)) : [],
      input.offline_attachments ? input.offline_attachments.map((attachment: OfflineReportAttachment) => OfflineReportAttachment.deserialize(attachment)) : [],
      input.retry,
      input.vehicle ? AvailableVehicle.deserialize(input.vehicle) : null,
      input.app_create_date ? input.app_create_date : null,
      input.appid ? input.appid : null,
      input.error ? input.error : ""
    );
  }

  static getId(report: Report): number {
    return report.id;
  }
}
