import React from 'react'

interface DateChipProps {
    date: string;
}

function DateChip({ date }: DateChipProps) {
  return (
    <div className='bg-zinc-300 rounded-full px-4 py-1 min-w-fit  h-fit'>
        <p className='font-bold text-zinc-500'>{date}</p>
    </div>
  )
}

export default DateChip
