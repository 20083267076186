import React, { useState, useEffect, Fragment } from 'react'
import getAddress from '../../services/Geocoding'
import { GlobeEuropeAfricaIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { useIsOnlineAtom } from '../../context/store';


interface GeoLocationInputProps {
    name: string;
    label?: string;
    onChange: (value: string) => void;
  }

function GeoLocationInput({ name, label, onChange }: GeoLocationInputProps) {
    const [placeholder, setPlaceholder] = useState<string>("Recuperando la posizione...")
    const [parsedLocation, setParsedLocation] = useState<any>(null)
    const [isOnline, setIsOnline] = useIsOnlineAtom()

    useEffect(() => {
        if(navigator.geolocation && isOnline) {
          navigator.geolocation.getCurrentPosition((position) => {
            getAddress(position.coords.latitude, position.coords.longitude)
                .then((response) => {
              setParsedLocation(response.data)
              setPlaceholder(response.data.display_name)
              onChange(response.data.display_name)
            }).catch((error) => {
                navigator.geolocation.getCurrentPosition((position) => {
                  setPlaceholder("LAT: "+ position.coords.latitude + " LNG: " + position.coords.longitude)
                }, (error) => {
                  setPlaceholder("Geolocalizzazione non disponibile")
                })
            });
          },(error) => {
            toast.error((t) => (<Fragment>Impossibile recuperare la posizione <button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>))
            setPlaceholder("Geolocalizzazione non disponibile")
          })
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            setPlaceholder("LAT: "+ position.coords.latitude + " LNG: " + position.coords.longitude)
          }, (error) => {
            setPlaceholder("Geolocalizzazione non disponibile")
          })
        }


      }, [])


  return (
    <div className='relative'>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-300">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          id={name}
          name={name}
          type="text"
          placeholder={placeholder || ""}
          autoComplete={name}
          value={parsedLocation ? parsedLocation.display_name : ""}
          disabled
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-xl py-1.5 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 border-none ring-0 focus:ring-transparent focus:border-transparent focus:shadow-outline sm:text-sm sm:leading-6"
        />
      </div>
      <GlobeEuropeAfricaIcon className="absolute h-6 w-6 text-zinc-500 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 right-3 top-1.5" />
    </div>
  )
}

export default GeoLocationInput
