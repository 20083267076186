import React, { useState, useEffect, Fragment } from 'react'
import { db } from '../../database/db';

interface ImageFromIndexDBProps {
    id: number;
    onClick: (id:number) => void;
}  

function ImageFromIndexDB({id, onClick}: ImageFromIndexDBProps) {
    const [image, setImage] = useState<File>()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        let active = true
        loadImage()
        return () => { active = false }
    }, [id])

    const loadImage = async () => {
        db.images.get(id).then((result) => {
            if (result) {
                setImage(result.image)
                setLoaded(true)
            }
        })
    }

  return (
    <Fragment>
        {loaded && (
            <img src={URL.createObjectURL(image!)} onClick={() => {onClick(id)}} className="rounded-xl object-cover" />
        )}
    </Fragment>
  )
}

export default ImageFromIndexDB