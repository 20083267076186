import React, { Fragment, Key, useEffect, useState } from "react";
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

function DarkModeSwitch() {
  const [selected, setSelected] = useState<Key>(localStorage.getItem("chiarcosso_theme") || "light");

  useEffect(() => {
    let theme = localStorage.getItem("chiarcosso_theme");

    if (theme === "dark") {
      setSelected("dark");
    } else {
      setSelected("light");
    }
  }, []);

  useEffect(() => {
    if (selected === "light") {
      localStorage.setItem("chiarcosso_theme", "light");
      document.body.classList.remove("dark");
    } else {
      localStorage.setItem("chiarcosso_theme", "dark");
      document.body.classList.add("dark");
    }
  }, [selected]);

  return (
    <div className="flex flex-col">
      <Tabs
        fullWidth
        size="md"
        aria-label="Darkmode selector"
        selectedKey={selected}
        onSelectionChange={setSelected}
      >
        <Tab
          key="light"
          title={
            <span className="inline-flex items-center gap-2 font-bold">
              <SunIcon className="w-5 h-5" />
              <p>Light</p>
            </span>
          }
        ></Tab>
        <Tab
          key="dark"
          title={
            <span className="inline-flex items-center gap-2 font-bold">
              <MoonIcon className="w-5 h-5" />
              <p>Dark</p>
            </span>
          }
        ></Tab>
      </Tabs>
    </div>
  );
}

export default DarkModeSwitch;
