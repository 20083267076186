import React from "react";
import DateChip from "../chips/DateChip";
import {
  DocumentIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import SegnalazioneInterface from "../../interfaces/SegnalazioneInterface";
import { FaTruck } from "react-icons/fa";
import { Report } from "../../interfaces/ReportInterface";
import ImageWithLoadValidator from "../images/ImageWithLoadValidator";
import CardImageValidated from "../images/CardImageValidated";

interface CardSegnalazioneProps {
  index: number;
  data: Report;
}

function CardSegnalazione({ data, index }: CardSegnalazioneProps) {
  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - "}
          {"Segnalazione"}
        </p>
        <DateChip date={data.date.substring(0, 10)} />
      </div>
      {data.report_type && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <QuestionMarkCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            {data.report_type.name}
          </p>
        </div>
      )}
      {data.plate ? (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <FaTruck className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            {data.report_type.name}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <FaTruck className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">
            {data.vehicle ? data.vehicle.license_plate : "Nessun veicolo"}
          </p>
        </div>
      )}
      {data.description && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1">{data.description}</p>
        </div>
      )}
      {data.retry && (
        <div className="grid grid-cols-12 w-full mt-1 mb-2">
          <ExclamationTriangleIcon className="h-6 w-6 mt-1 col-span-1" />
          <p className="font-semibold col-span-11 mt-1 text-red-600 animate-pulse">
            {data.error !== "" ? data.error : "In attesa di sincronizzazione con Chiarcosso"}
          </p>
        </div>
      )}
      {data.attachments.length > 0 && (
        <CardImageValidated url={data.attachments[0].url} />
      )}
    </div>
  );
}

export default CardSegnalazione;
