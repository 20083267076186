import axios from "axios";
import WebInstance from "../utils/AxiosInstance";
import { toast } from "react-hot-toast";
import HeaderBuilder from "../utils/HeaderBuilder";
import { User } from "../interfaces/UserInterface";
import SelectServer from "../utils/SelectServer";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const apiUrl = SelectServer.getServer().replace("/app", "");

const AuthService = {
  login: async (username: string, password: string) => {
    const raw = await WebInstance.post(
      `${apiUrl}/app/auth/login`,
      {},
      { auth: { username, password }, headers: { "Skip-Auth": "true" } }
    );

    return AuthService.saveUserData(User.deserialize(raw.data));
  },

  saveUserData: (data: any) => {
    localStorage.setItem("chiarcosso_user", JSON.stringify(data));
    localStorage.setItem("chiarcosso_token", data.sessionId);
  },

  getUserData: () => {
    try {
      let user = JSON.parse(localStorage.getItem("chiarcosso_user")!);
      return user;
    } catch (error) {
      return null;
    }
  },

  logout: async () => {
    if (navigator.onLine) {
      return await WebInstance.get(
        `${apiUrl}/app/auth/logout`,
        HeaderBuilder.build()
      )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //toast.error("Errore nel logout");
          return null;
        });
    } else {
      toast.error((t) => (<Fragment>Al momento sei offline, impossibile effettuare il logout.<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>))
      return null;
    }
  },

  checkSession: async () => {
    return new Promise(async (resolve, reject) => {
      let offlineUser = localStorage.getItem("chiarcosso_user");
      let offlineCheck = false;
      let onlineCheck = false;

      if (offlineUser) {
        offlineCheck = true;
      }

      if (navigator.onLine) {
        await WebInstance.get(
          `${SelectServer.getServer()}/user`,
          HeaderBuilder.build()
        )
          .then((response) => {
            onlineCheck = true;
          })
          .catch((error) => {
            onlineCheck = false;
          });
      } else {
        onlineCheck = true;
      }

      if (offlineCheck && onlineCheck) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  },

  check: async () => {
    return await axios.get(`${apiUrl}/app/auth/check`, HeaderBuilder.build());
  },
};

export default AuthService;
