import React, { Fragment } from "react";
import {
  ATTACHMENT_TYPES,
  MOBILE_TRIP_STATES,
  Trip,
} from "../../interfaces/TripInterface";
import ImageFromIndexDB from "../images/ImageFromIndexDB";
import ImageWithLoadValidator from "../images/ImageWithLoadValidator";
import { UploadedAttachment } from "../../interfaces/UploadedAttachmentInterface";
import { OfflineAttachment } from "../../interfaces/OfflineAttachmentInterface";
import { useRefreshCurrentTripAtom } from "../../context/store";
import { WebAppData } from "../../interfaces/WebAppDataInterface";
import { db } from "../../database/db";
import { TrashIcon } from "@heroicons/react/24/outline";

interface AttachmentListProps {
  trip: Trip;
  canRemove: boolean;
}

const weightTypes: { label: string; type: ATTACHMENT_TYPES }[] = [
  { label: "Foto Rimorchio", type: ATTACHMENT_TYPES.LOAD },
  { label: "Foto DDT", type: ATTACHMENT_TYPES.DDT },
  { label: "Foto pesata", type: ATTACHMENT_TYPES.WEIGHT },
];

function AttachmentList({ trip, canRemove }: AttachmentListProps) {
  const [refreshCurrentTrip, setRefreshCurrentTrip] =
    useRefreshCurrentTripAtom();

  const handleImageRemove = async (
    id: number,
    type: "uploaded" | "offline"
  ) => {
    if (!canRemove) return;
    let gad = WebAppData.deserialize(
      JSON.parse(localStorage.getItem("chiarcosso_gad")!)
    );
    let newTrip = trip;

    if (type === "uploaded") {
      let newAttachments = newTrip.attachments;
      newAttachments.forEach((attachment, index) => {
        if (attachment.id === id) {
          if (attachment.to_remove) {
            newAttachments[index].to_remove = false;
          } else {
            newAttachments[index].to_remove = true;
          }
        }
      });
      newTrip.attachments = newAttachments;
      gad.trips.forEach((trip: Trip, index: number) => {
        if (trip.id === newTrip.id) {
          gad.trips[index] = newTrip;
        }
      });
      localStorage.setItem("chiarcosso_gad", JSON.stringify(gad));
      setRefreshCurrentTrip(true);
    }

    if (type === "offline") {
      db.images.delete(id).then(() => {
        newTrip.offline_attachments = newTrip.offline_attachments.filter(
          (attachment: OfflineAttachment) => attachment.offline_id !== id
        );
        gad.trips.forEach((trip: Trip, index: number) => {
          if (trip.id === newTrip.id) {
            gad.trips[index] = newTrip;
          }
        });

        localStorage.setItem("chiarcosso_gad", JSON.stringify(gad));
        setRefreshCurrentTrip(true);
      });
    }
  };

  return (
    <Fragment>
      <div className="mt-2 w-full">
        {weightTypes.map((weightType, index) => (
          <div
            key={index}
            className="mt-2 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 rounded-xl w-full py-3 px-2"
          >
            <div className="px-3">
              <label className="block text-sm font-medium leading-6 text-zinc-700 dark:text-zinc-300">
                {weightType.label}
              </label>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-2 sm:grid-cols-4">
              {trip.attachments
                .filter(
                  (attachment: UploadedAttachment) =>
                    attachment.tag === weightType.type
                )
                .map((attachment, index) => (
                  <div className="relative">
                    {attachment.to_remove && (
                      <div className="absolute top-0 right-0 w-full h-full bg-transparent flex items-center justify-center">
                        <TrashIcon className="w-6 h-6 text-red-500" />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        handleImageRemove(attachment.id, "uploaded");
                      }}
                      key={index}
                      className={`${
                        attachment.to_remove ? "opacity-25" : "opacity-100"
                      } flex flex-col items-center justify-center p-1 border border-zinc-400 rounded-xl`}
                    >
                      <ImageWithLoadValidator url={attachment.url} />
                      <span>
                        {attachment.mobileapp_trip_status ===
                        MOBILE_TRIP_STATES.START ? (
                          <p className="inline-flex items-center">
                            <span className="w-2 h-2 rounded-full bg-green-400 mr-1"></span>
                            Inizio viaggio
                          </p>
                        ) : (
                          <p className="inline-flex items-center">
                            <span className="w-2 h-2 rounded-full bg-red-500 mr-1"></span>
                            Fine viaggio
                          </p>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              {trip.offline_attachments &&
                trip.offline_attachments
                  .filter(
                    (attachment: OfflineAttachment) =>
                      attachment.tag === weightType.type
                  )
                  .map((attachment, index) => (
                    <div
                      onClick={() => {
                        handleImageRemove(attachment.offline_id, "offline");
                      }}
                      key={index}
                      className="flex flex-col items-center justify-center p-1 border border-zinc-400 rounded-xl"
                    >
                      <ImageFromIndexDB
                        id={attachment.offline_id}
                        onClick={() => {}}
                      />
                      <span>
                        {attachment.mobileapp_trip_status ===
                        MOBILE_TRIP_STATES.START ? (
                          <p className="inline-flex items-center">
                            <span className="w-2 h-2 rounded-full bg-green-400 mr-1"></span>
                            Inizio viaggio
                          </p>
                        ) : (
                          <p className="inline-flex items-center">
                            <span className="w-2 h-2 rounded-full bg-red-500 mr-1"></span>
                            Fine viaggio
                          </p>
                        )}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default AttachmentList;
