import React from 'react'

interface DangerButtonProps  {
  label?: string;
  icon?: any;
  position?: string;
  onClick: () => void;
  disabled?: boolean;
}


function DangerButton({ label, icon, position, onClick, disabled = false }: DangerButtonProps) {
  return (
    <button disabled={disabled} className="bg-red-500 hover:bg-red-400 disabled:bg-zinc-700 disabled:text-zinc-200 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex my-auto">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className="font-semibold">{label ? label : "Danger"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  )
}

export default DangerButton