import React, { useState, Fragment, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SingleDigitInput from "../../components/inputs/SingleDigitInput";

function CodeLogin() {
  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);

  const navigate = useNavigate();

  useEffect(() => {
    //rimuovere una volta abilitato il login con codice
    navigate("/login")
  }, []);

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (code.includes("")) {
      toast.error("Inserire il codice completo");
      return;
    }

    const codeString = code.join("");
  };

  const handleCodeChange = (index: number, value: string) => {
    const newCode = [...code];
    if (value) {
      newCode[index] = value;
      setCode(newCode);
    } else {
      newCode[index] = "";
      setCode(newCode);
    }
  };

  return (
    <Fragment>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
          },
        }}
      />
      <div className="flex overflow-x-hidden h-[98dvh] overflow-y-hidden p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out">
        <div className="flex min-h-full flex-1 flex-col px-6 mt-32">
          <div className="mx-auto w-full max-w-sm">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="mx-auto h-18 w-auto"
            />
            <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-zinc-200">
              Inserisci il codice
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              onSubmit={(e: any) => {
                handleLogin(e);
              }}
            >
              <div className="flex flex-row w-full justify-center gap-2 items-center">
                <SingleDigitInput
                  index={0}
                  onChange={(val) => handleCodeChange(0, val)}
                />
                <SingleDigitInput
                  index={1}
                  onChange={(val) => handleCodeChange(1, val)}
                />
                <SingleDigitInput
                  index={2}
                  onChange={(val) => handleCodeChange(2, val)}
                />
                <SingleDigitInput
                  index={3}
                  onChange={(val) => handleCodeChange(3, val)}
                />
                <SingleDigitInput
                  index={4}
                  onChange={(val) => handleCodeChange(4, val)}
                />
                <SingleDigitInput
                  index={5}
                  onChange={(val) => handleCodeChange(5, val)}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-red-500 font-bold hover:bg-red-400 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95"
                >
                  Entra
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CodeLogin;
