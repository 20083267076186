import React from "react";

interface SubtitleProps {
  label?: string;
  position?: string;
}

function Subtitle({ label, position }: SubtitleProps) {

  return (
    <div className="inline-flex items-center">
      <h1
        className={`ml-2 font-bold text-lg dark:text-zinc-200 mb-2 ${
          position === "center" ? "text-center" : ""
        }`}
      >
        {label}
      </h1>
    </div>
  );
}

export default Subtitle;