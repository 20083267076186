import { AxiosResponse } from "axios";
import { db } from "../database/db";
import HeaderBuilder from "../utils/HeaderBuilder";
import { WebAppData } from "../interfaces/WebAppDataInterface";
import { Report } from "../interfaces/ReportInterface";
import SelectServer from "../utils/SelectServer";
import { OfflineReportAttachment } from "../interfaces/OfflineReportAttachmentInterface";
import WebInstance from "../utils/AxiosInstance";
import { ConvertToBase64 } from "../utils/ConvertToBase64";
import GADService from "./GADService";
import UUID from "../utils/UUID";

const gadVarName = "chiarcosso_gad";

const ReportService = {
  getReports: async () => {
    return getReportsOffline();
  },

  saveReportData: async (data: any) => {
    const appId = UUID.v4();
    //mi prendo il gad dal localstorage
    const gad = WebAppData.deserialize(
      JSON.parse(localStorage.getItem(gadVarName)!)
    );

    //creo un array di offline attachments convertendo e allegando le immagini
    for (const [index, image] of data.attachments.entries()) {
      let pid = await db.report_images.add({
        image: image,
        locked: 0,
        reportId: appId,
        base64: await ConvertToBase64(image),
      });
    }

    const updatedPhotos = await db.report_images.toArray();

    gad.reports.push(
      new Report(
        0,
        data.datetime,
        data.report_type,
        data.plate,
        data.description,
        [],
        [],
        true,
        null,
        new Date().toISOString().slice(0, 19),
        appId,
        ""
      )
    );

    GADService.updateOfflineGAD(gad);
    return true;
  },

  updateReport: async (report: Report): Promise<AxiosResponse<Report>> => {
    let attachments: any[] = [];

    if (report.offline_attachments.length) {
      for await (const attachment of report.offline_attachments) {
        const file = await db.images.get(attachment.offline_id);
        // const base64 = await ConvertToBase64(file!.image);
        attachments.push(
          { 
            base64:  file.base64 === null || file.base64 === undefined  || file.base64 === '' ? await ConvertToBase64(file.image) : file.base64,
            mimetype: "image/png" 
          });
      }
    }

    const data = {
      report_type: report.report_type,
      vehicle: report.plate?.id || null,
      datetime: report.date,
      description: report.description,
      attachments: attachments,
      appid: report.appid,
      app_create_date: report.app_create_date,
    };

    return WebInstance.post(
      `${SelectServer.getServer()}/report`,
      data,
      HeaderBuilder.build()
    );
  },

  testReport: async (report: Report): Promise<AxiosResponse<Report>> => {
    let attachments: any[] = [];
    let logUrl = "http://192.168.1.2:3001/log";

    if (report.offline_attachments.length) {
      for await (const attachment of report.offline_attachments) {
        const file = await db.report_images.get(attachment.offline_id);
        const base64 = await ConvertToBase64(file!.image);
        attachments.push({ base64: base64, mimetype: "image/png" });
      }
    }

    const data = {
      report_type: report.report_type,
      vehicle: report.plate?.id || null,
      datetime: report.date,
      description: report.description,
      attachments: attachments,
      appid: report.appid,
      app_create_date: report.app_create_date,
    };

    return WebInstance.post(`${logUrl}/report`, data, HeaderBuilder.build());
  },

  updatePhoto: async (): Promise<any> => {
    let attachments: any[] = [];

    //select all the photos from the db
    const photos = await db.report_images.where("locked").equals(0).toArray();
    if (photos.length === 0) return;
    let photo = photos[0];

    await db.report_images.update(photo.id!, { locked: 1 });
    attachments.push({
      base64:  photo.base64 === null || photo.base64 === undefined  || photo.base64 === '' ? await ConvertToBase64(photo.image) : photo.base64,
      // base64: await ConvertToBase64(photo.image),
      mimetype: "image/png",
    });

    return new Promise((resolve, reject) => {
      console.log("1 - starting upload")

      if(photo.reportId === undefined || photo.reportId === null) return resolve(true);

      WebInstance.post(
        `${SelectServer.getServer()}/report/update`,
        {
          appid: photo.reportId!,
          attachments: attachments,
        },
        HeaderBuilder.build()
      )
        .then((res) => {
          console.log("2 - photo uploaded")
          db.report_images.delete(photo.id!);
          resolve(res);
        })
        .catch(() => {
          console.log("Errore nell'upload della foto");
          db.report_images.update(photo.id!, { locked: 0 });
          reject();
        });
    });
  },
};

const getReportsOffline = () => {
  const data = localStorage.getItem(gadVarName);
  if (data) {
    return JSON.parse(data).reports.map((report: any) =>
      Report.deserialize(report)
    );
  } else {
    return [];
  }
};

export default ReportService;
