export class AvailableVehicle {
  id: number;
  license_plate: string;

  constructor(id: number, license_plate: string) {
    this.id = id;
    this.license_plate = license_plate;
  }

  static deserialize(input: any): AvailableVehicle {
    return new AvailableVehicle(input.id, input.license_plate);
  }

  static getId(availableVehicles: AvailableVehicle): number {
    return availableVehicles.id;
  }
}
