import React from "react";

interface PrimaryButtonProps {
  label?: string;
  icon?: any;
  position?: string;
  onClick: () => void;
}

function PrimaryButtonSmall({ label, icon, position, onClick }: PrimaryButtonProps) {
  return (
    <button className="bg-[#0385FF] hover:bg-blue-400 text-white flex justify-center align-middle px-4  py-1 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className={`font-semibold`}>{label ? label : "Primary"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 ml-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  );
}

export default PrimaryButtonSmall;
