import { Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";

interface SimpleAccordionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
}

function SimpleAccordion({
  open,
  setOpen,
  title,
  children,
}: SimpleAccordionProps) {
  return (
    <Fragment>
      <div>
        <div className="mt-2 w-full">
          <div
            className="mt-2 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 rounded-xl w-full py-3 px-2"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div className="px-2 flex flex-row items-center justify-between">
              <label className="block text-sm font-medium leading-6 text-zinc-700 dark:text-zinc-300">
                {title}
              </label>
              <ChevronRightIcon
                className={`transition-all duration-300 ease-in-out h-5 w-5 text-zinc-700 dark:text-zinc-300 ${
                  open ? "rotate-90" : "rotate-0"
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <Transition
        className="mx-auto"
        show={open}
        enter="transition-all ease-in-out duration-500 delay-[200ms]"
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </Fragment>
  );
}

export default SimpleAccordion;
