import React from "react";

interface TextInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

function TextInput({ name, label, placeholder, value, onChange }: TextInputProps) {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-300">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          id={name}
          name={name}
          type="text"
          placeholder={placeholder || ""}
          autoComplete={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-xl py-1.5 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 border-none ring-0 focus:ring-transparent focus:border-transparent focus:shadow-outline sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

export default TextInput;
