import { atom, useAtom } from "jotai";
import { Equipment } from "../interfaces/EquipmentInterface";

const openMenuAtom = atom(false);

export const useOpenMenuAtom = () => {
  const [openMenu, setOpenMenu] = useAtom(openMenuAtom);
  return [openMenu, setOpenMenu] as const;
};

const equipmentCartAtom = atom<Equipment[]>([]);

export const useEquipmentCartAtom = () => {
  const [equipmentCart, setEquipmentCart] = useAtom(equipmentCartAtom);
  return [equipmentCart, setEquipmentCart] as const;
};

const refreshGADAtom = atom(false);

export const useRefreshGADAtom = () => {
  const [refreshGAD, setRefreshGAD] = useAtom(refreshGADAtom);
  return [refreshGAD, setRefreshGAD] as const;
};

const refreshCurrentTripAtom = atom(false);

export const useRefreshCurrentTripAtom = () => {
  const [refreshCurrentTrip, setRefreshCurrentTrip] = useAtom(
    refreshCurrentTripAtom
  );
  return [refreshCurrentTrip, setRefreshCurrentTrip] as const;
}

const GADAtom = atom<any>(false);

export const useGADAtom = () => {
  const [GAD, setGAD] = useAtom(GADAtom);
  return [GAD, setGAD] as const;
};

const tripDetailDataAtom = atom<any>(false);

export const useTripDetailDataAtom = () => {
  const [tripDetailData, setTripDetailData] = useAtom(tripDetailDataAtom);
  return [tripDetailData, setTripDetailData] as const;
}

const tokenAtom = atom<string | null>("");

export const useTokenAtom = () => {
  const [token, setToken] = useAtom(tokenAtom);
  return [token, setToken] as const;
};

const isOnlineAtom = atom<boolean>(navigator.onLine);

export const useIsOnlineAtom = () => {
  const [isOnline, setIsOnline] = useAtom(isOnlineAtom);
  return [isOnline, setIsOnline] as const;
};

const syncAtom = atom(false);

export const useSyncAtom = () => {
  const [syncing, setSyncing] = useAtom(syncAtom);
  return [syncing, setSyncing] as const;
};

const photoSyncAtom = atom(false);

export const usePhotoSyncAtom = () => {
  const [syncingPhotos, setSyncingPhotos] = useAtom(photoSyncAtom);
  return [syncingPhotos, setSyncingPhotos] as const;
};

const reportPhotoSyncAtom = atom(false);

export const useReportPhotoSyncAtom = () => {
  const [syncingReportPhotos, setSyncingReportPhotos] = useAtom(reportPhotoSyncAtom);
  return [syncingReportPhotos, setSyncingReportPhotos] as const;
};


const photosToSyncAtom = atom<number>(0);

export const usePhotosToSyncAtom = () => {
  const [photosToSync, setPhotosToSync] = useAtom(photosToSyncAtom);
  return [photosToSync, setPhotosToSync] as const;
};

const tripsToSyncAtom = atom<number>(0);

export const useTripsToSyncAtom = () => {
  const [tripsToSync, setTripsToSync] = useAtom(tripsToSyncAtom);
  return [tripsToSync, setTripsToSync] as const;
};

const reportsToSyncAtom = atom<number>(0);

export const useReportsToSyncAtom = () => {
  const [reportsToSync, setReportsToSync] = useAtom(reportsToSyncAtom);
  return [reportsToSync, setReportsToSync] as const;
};