import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface PasswordInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  haveForgotPassword?: boolean;
  forgotPassword: () => void;
  color?: string;
}

function PasswordInput({
  name,
  label,
  placeholder,
  value,
  onChange,
  haveForgotPassword,
  forgotPassword,
  color,
}: PasswordInputProps) {
  const [visible, setVisible] = useState(false);


  return (
    <div>
      <div className="flex items-center justify-between">
        {label && (
          <label htmlFor={name} className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-300">
            {label}
          </label>
        )}
        {haveForgotPassword && (
          <div className="text-sm">
            <button type="button" onClick={() => forgotPassword()} className={`font-semibold text-red-600 hover:text-red-500`}>
              Password dimenticata?
            </button>
          </div>
        )}
          </div>
        <div className="mt-2 flex flex-row gap-2 items-center">
          <input
            id={name}
            name={name}
            type={visible ? "text" : "password"}
            placeholder={placeholder || ""}
            autoComplete={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="block w-full rounded-xl h-10 py-1.5 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 border-none ring-0 focus:ring-transparent focus:border-transparent focus:shadow-outline sm:text-sm sm:leading-6"
          />
          <button type="button" onClick={() => {setVisible(!visible)}} className="h-10 w-12 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 rounded-xl inline-flex items-center justify-center transition-all duration-300 ease-in-out active:scale-75">{visible ? (<EyeSlashIcon className="w-6 h-6" />) : (<EyeIcon className="w-6 h-6" />)}</button>
        </div>
    </div>
  );
}

export default PasswordInput;
