import React, { Fragment, useEffect, useState } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import CardSegnalazione from "../../components/cards/CardSegnalazione";
import { Report } from "../../interfaces/ReportInterface";
import ReportService from "../../services/ReportService";
import TextInput from "../../components/inputs/TextInput";
import CardSegnalazioneOffline from "../../components/cards/CardSegnalazioneOffline";
import { useGADAtom } from "../../context/store";

function Segnalazioni() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [reports, setReports] = useState<Report[]>([]);
  const [filteredReports, setFilteredReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [GAD, setGAD] = useGADAtom();

  const init = () => {
    setIsLoading(true);
    ReportService.getReports()
      .then((res: Report[]) => {
        const isEqual = areArraysEqual(res, reports);
        if(!isEqual){
          console.log('rifaccio init quindi ti resetto tutti i record')
          setReports(res);
          setFilteredReports(res);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  const areArraysEqual = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
  };


  useEffect(() => {
    init();
    console.log('Cambiato GAD');
  }, [GAD]);

  useEffect(() => {
    const filtered = reports.filter((report) => {
      return report.description
        .toLowerCase()
        .includes(searchValue.toLowerCase()) || report.report_type.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredReports(filtered);
  }, [searchValue]);

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label="Segnalazioni" isLoading={isLoading} wifi={true} />
        <TextInput
          name="searchvalue"
          placeholder="Cerca..."
          value={searchValue}
          onChange={setSearchValue}
        />
        <div className="mb-2"></div>
        {filteredReports.map((report, index) => (
          <Fragment key={index}>
            {report.id !== 0 ? (
              <CardSegnalazione key={report.id} data={report} index={index} />
            ) : (
              <CardSegnalazioneOffline key={report.id} data={report} index={index} />
            )}
          </Fragment>
        ))}
      </div>
      <div className={`w-full sticky bottom-0 mt-3`}>
        <PrimaryButton
          label="Nuova segnalazione"
          icon={<PlusIcon className="w-5 h-5 text-white font-bold" />}
          position="start"
          onClick={() => {
            navigate("/segnalazioni/step1");
          }}
        />
      </div>
    </div>
  );
}

export default Segnalazioni;
