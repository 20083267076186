export type LEAVE_STATE = "confirm" | "validate" | "refuse";
export class LEAVE_STATES {
  static CONFIRM: LEAVE_STATE = "confirm";
  static VALIDATE: LEAVE_STATE = "validate";
  static REFUSED: LEAVE_STATE = "refuse";
}

export class LeaveType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  static deserialize(input: any) {
    return new LeaveType(input.id, input.mobileapp_name ? input.mobileapp_name : input.name);
  }
}

export class Leave {
  id: number;
  name: string;
  state: LEAVE_STATE;
  leave_type: LeaveType;
  date_from: string;
  date_to: string;
  period?: string;
  appid?: string;
  app_create_date?: string;

  constructor(
    id: number,
    name: string,
    state: LEAVE_STATE,
    leave_type: LeaveType,
    date_from: string,
    date_to: string,
    period?: string,
    appid?: string,
    app_create_date?: string
  ) {
    this.id = id;
    this.name = name;
    this.state = state;
    this.leave_type = leave_type;
    this.date_from = date_from;
    this.date_to = date_to;
    this.period = period;
    this.appid = appid;
    this.app_create_date = app_create_date;
  }

  static deserialize(input: any) {
    return new Leave(
      input.id,
      input.name,
      input.state,
      LeaveType.deserialize(input.leave_type),
      input.date_from,
      input.date_to,
      input.period,
      input.appid,
      input.app_create_date
    );
  }

  static getId(leave: Leave): number {
    return leave.id;
  }
}
