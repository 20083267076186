import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
import "../flatpickr.css";

import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SuccessButton from "../../components/buttons/SuccessButton";
import { LeaveType } from "../../interfaces/LeaveInterface";
import LeaveService from "../../services/LeaveService";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import { toast } from "react-hot-toast";
import { DateTime } from 'luxon';
import LoadingButton from "../../components/buttons/LoadingButton";
import { XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

function LeaveRequest() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dates, setDates] = useState<Date[]>([DateTime.now().toJSDate(), DateTime.now().toJSDate()],);
    const [anticipate, setAnticipate] = useState<boolean>(false);
    const [types, setTypes] = useState<LeaveType[]>([]);
    const [selectedType, setSelectedType] = useState<LeaveType>();
    const [description, setDescirption] = useState<string>("");
    const [periods, setPeriods] = useState<any[]>([
        {id: "am", name: "Mattina"},
        {id: "pm", name: "Pomeriggio"},
        {id: null, name: "Intera"},
    ]);
    const [selectedPeriod, setSelectedPeriod] = useState<any>({
        id: null,
        name: "Intera",
    });
    const [hidePeriod, setHidePeriod] = useState<boolean>(false);

    useEffect(() => {
        LeaveService.getLeaveTypes().then((response) => {
            setSelectedType(LeaveType.deserialize(response.data[0]));
            setTypes(
                response.data.map((type: LeaveType) => LeaveType.deserialize(type))
            );
        }).catch((error) => {
            navigate("/leaves");
        });
    }, []);

    useEffect(() => {
        if (dates[0] && dates[1]) {
            if (DateTime.fromJSDate(dates[0]).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(dates[1]).toFormat('yyyy-MM-dd')) {
                setHidePeriod(false);
                return
            }
            setHidePeriod(true);
        }
    }, [dates]);

    const handleCreateLeave = () => {
        setIsLoading(true);
        if (!dates[0] || !dates[1]) {
            toast.error((t) => (<Fragment><p className="text-sm">Periodo selezionato non corretto. Se vuoi selezionare lo stesso giorno premere due volte sulla data desiderata</p><button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>));
            setIsLoading(false);
            return;
        }

        if (description === "" || description.length < 5) {
            toast.error((t) => (<Fragment>Inserisci una descrizione di almeno 5 caratteri!<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>))
            setIsLoading(false);
            return;
        }

        let data: any = {
            type: selectedType!.id,
            description: description,
            date_from: DateTime.fromJSDate(dates[0]).toFormat('yyyy-MM-dd'),
            date_to: DateTime.fromJSDate(dates[1]).toFormat('yyyy-MM-dd'),
            request_advance: anticipate,
            app_create_date: new Date().toISOString().slice(0, 23),
        };

        if (DateTime.fromJSDate(dates[0]).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(dates[1]).toFormat('yyyy-MM-dd')) {
            data.period = selectedPeriod?.id;
        }

        LeaveService.createleave(data)
            .then(() => {
                toast.success((t) => (<Fragment>Inserisci una descrizione!<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>))
                navigate("/leaves");
            })
            .catch((error) => {
                toast.error((t) => (<Fragment>{error?.response?.data?.detail || 'Errore durante la creazione della richiesta'}<button type='button' className='ml-2' onClick={() => {toast.dismiss(t.id)}}><XMarkIcon className="h-6 w-6 text-white" /></button></Fragment>))
            }).finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <TitleWithMenuIcon label="Nuova richiesta"/>
                <div className="flex flex-row w-full justify-center">
                    <Flatpickr
                        style={{display: "none"}}
                        data-enable-time
                        options={{
                            mode: "range",
                            inline: true,
                            locale: Italian,
                            enableTime: false,
                            minDate: "today",
                        }}
                        value={dates}
                        onChange={(range) => {
                            setDates(range);
                        }}
                    />
                </div>
                <div className="mt-2"></div>
                <TextInput
                    name="description"
                    placeholder="Descrizione"
                    value={description}
                    onChange={setDescirption}
                />
                <div className="mt-2"></div>
                {types.length > 0 && selectedType && (
                    <SelectInput
                        selected={selectedType}
                        setSelected={setSelectedType}
                        name="LeaveType"
                        options={types}
                    />
                )}
                {selectedPeriod && !hidePeriod && (
                    <SelectInput
                        selected={selectedPeriod}
                        setSelected={setSelectedPeriod}
                        name="Period"
                        options={periods}
                    />
                )}
                <div className="flex flex-row w-full justify-start items-center mt-2">
                    <Switch
                        checked={anticipate}
                        onChange={setAnticipate}
                        className={classNames(
                            anticipate ? "bg-green-500" : "bg-gray-200 dark:bg-zinc-600",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-0"
                        )}
                    >
                        <span className="sr-only">Use setting</span>
                        <span
                            aria-hidden="true"
                            className={classNames(
                                anticipate ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                        />
                    </Switch>
                    <p className="ml-2 font-semibold text-zinc-500">Anticipo ferie</p>
                </div>
            </div>
            <div className={`w-full sticky bottom-0 mt-3`}>
                <div className={`grid ${isLoading ? "grid-cols-1" : "grid-cols-2"} gap-2`}>
                    {!isLoading ? (
                        <Fragment>
                            <SecondaryButton
                                label="Torna indietro"
                                onClick={() => {
                                    navigate("/leaves");
                                }}
                            />
                            <SuccessButton
                                label="Salva"
                                onClick={() => {
                                    handleCreateLeave();
                                }}
                            />
                        </Fragment>
                    ) : (<LoadingButton />)}
                </div>
            </div>
        </div>
    );
}

export default LeaveRequest;
