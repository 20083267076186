import { OfflineAttachment } from "./OfflineAttachmentInterface";
import { UploadedAttachment } from "./UploadedAttachmentInterface";

export interface IAttachmentToAdd {
  type: ATTACHMENT_TYPE;
  base64: string;
}

export class Trip {
  id: number;
  date: string;
  state: TRIP_STATUS;
  start_net_weight: number;
  end_net_weight: number;
  start_gross_weight: number;
  end_gross_weight: number;
  start_tare_weight: number;
  end_tare_weight: number;
  start_kms: number;
  end_kms: number;
  description: string;
  cover: string;
  attachments: UploadedAttachment[];
  supplier: string;
  cargo: string;
  departure: string;
  arrival: string;
  start_coordinates: { latitude: number; longitude: number };
  end_coordinates: { latitude: number; longitude: number };
  vehicles: string[];
  offline_attachments: OfflineAttachment[];
  retry: boolean = false;
  start_datetime: string = "";
  end_datetime: string = "";
  error?: string = "";
  lock?: boolean = false;

  constructor(
    id: number,
    date: string,
    state: TRIP_STATUS,
    start_net_weight: number,
    end_net_weight: number,
    start_gross_weight: number,
    end_gross_weight: number,
    start_tare_weight: number,
    end_tare_weight: number,
    start_kms: number,
    end_kms: number,
    description: string,
    cover: string,
    attachments: UploadedAttachment[],
    supplier: string,
    cargo: string,
    departure: string,
    arrival: string,
    start_coordinates: { latitude: number; longitude: number },
    end_coordinates: { latitude: number; longitude: number },
    vehicles: string[],
    offline_attachments: OfflineAttachment[],
    retry: boolean = false,
    start_datetime = "",
    end_datetime = "",
    error = "",
    lock = false
  ) {
    this.id = id;
    this.date = date;
    this.state = state;
    this.start_net_weight = start_net_weight;
    this.end_net_weight = end_net_weight;
    this.start_gross_weight = start_gross_weight;
    this.end_gross_weight = end_gross_weight;
    this.start_tare_weight = start_tare_weight;
    this.end_tare_weight = end_tare_weight;
    this.start_kms = start_kms;
    this.end_kms = end_kms;
    this.description = description;
    this.cover = cover;
    this.attachments = attachments;
    this.supplier = supplier;
    this.cargo = cargo;
    this.departure = departure;
    this.arrival = arrival;
    this.start_coordinates = start_coordinates;
    this.end_coordinates = end_coordinates;
    this.vehicles = vehicles;
    this.offline_attachments = offline_attachments;
    this.retry = retry;
    this.start_datetime = start_datetime;
    this.end_datetime = end_datetime;
    this.error = error;
    this.lock = lock;
  }
  static deserialize(input: any): Trip {
    return new Trip(
      input.id,
      input.date,
      input.state,
      input.start_net_weight,
      input.end_net_weight,
      input.start_gross_weight,
      input.end_gross_weight,
      input.start_tare_weight,
      input.end_tare_weight,
      input.start_kms,
      input.end_kms,
      input.description,
      input.cover,
      input.attachments ? input.attachments.map((attachment: UploadedAttachment) => UploadedAttachment.deserialize(attachment)) : [],
      input.supplier,
      input.cargo,
      input.departure,
      input.arrival,
      input.start_coordinates,
      input.end_coordinates,
      input.vehicles,
      input.offline_attachments ? input.offline_attachments.map((attachment: OfflineAttachment) => OfflineAttachment.deserialize(attachment)) : [],
      input.retry,
      input.start_datetime ? input.start_datetime.slice(0,19) : "",
      input.end_datetime ? input.end_datetime.slice(0,19) : "",
      input.error || "",
      input.lock || false
    );
  }

  static getId(trip: Trip): number {
    return trip.id;
  }

  static getHumanReadableStatus(status: TRIP_STATUS): string {
    switch (status) {
      case TRIP_STATES.TODO:
        return "Da fare";
      case TRIP_STATES.IN_PROGRESS:
        return "In corso";
      case TRIP_STATES.COMPLETED:
        return "Completato";
      default:
        return "";
    }
  }

  static getHumanReadableAType(type: ATTACHMENT_TYPE): string {
    switch (type) {
      case ATTACHMENT_TYPES.DDT:
        return "DDT";
      case ATTACHMENT_TYPES.LOAD:
        return "Rimorchio";
      default:
        return "";
    }
  }
}

export type TRIP_STATUS = "todo" | "in_progress" | "completed";
export class TRIP_STATES {
  static TODO: TRIP_STATUS = "todo";
  static IN_PROGRESS: TRIP_STATUS = "in_progress";
  static COMPLETED: TRIP_STATUS = "completed";
}

export type ATTACHMENT_TYPE = "ddt" | "load" | "weight";
export class ATTACHMENT_TYPES {
  static DDT: ATTACHMENT_TYPE = "ddt";
  static LOAD: ATTACHMENT_TYPE = "load";
  static WEIGHT: ATTACHMENT_TYPE = "weight";
}

export type MOBILE_TRIP_STATUS = "start" | "end";
export class MOBILE_TRIP_STATES {
  static START: MOBILE_TRIP_STATUS = "start";
  static END: MOBILE_TRIP_STATUS = "end";
}  