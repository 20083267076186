import React, { useState, useEffect } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { ATTACHMENT_TYPES } from "../../interfaces/TripInterface";
import Compressor from "compressorjs";
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType
);

interface FilePondUploadProps {
  label?: string;
  fieldId: string;
  onChange: (value: any) => void;
  isLoading?: boolean;
}

function FilePondComponent({
  label,
  onChange,
  fieldId,
  isLoading,
}: FilePondUploadProps) {
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    const exportFilesBuilder = files.map(async (file: any) => {
      const res = await compressImage(file.file);
      return res;

    });
    onChange(exportFilesBuilder);
  }, [files]);

  const compressImage = (file: any) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.2,
        success: (compressedFile) => {
          resolve(compressedFile);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  };

  return (
    <div className="mt-2 w-full">
      <div
        className="mt-2 text-zinc-900 dark:text-zinc-300 bg-zinc-200 dark:bg-zinc-700 rounded-xl w-full py-3 px-2"
        id={fieldId}
      >
        <div className="px-3">
          {label && (
            <label className="block text-sm font-medium leading-6 text-zinc-700 dark:text-zinc-300">
              {label}
            </label>
          )}
        </div>
        <FilePond
          allowMultiple={true}
          onupdatefiles={setFiles}
          files={files}
          disabled={isLoading}
          allowImagePreview={true}
          labelIdle="Clicca qui per aggiungere le foto"
          allowFileTypeValidation={true}
          labelFileTypeNotAllowed="Formato non supportato"
          acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
        />
      </div>
    </div>
  );
}

export default FilePondComponent;
