import React, { Fragment, useMemo } from "react";
import DateChip from "../chips/DateChip";
import { LEAVE_STATES, Leave } from "../../interfaces/LeaveInterface";
import {
  ArrowRightIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { BsArrowRight } from "react-icons/bs";

interface CardLeaveProps {
  index: number;
  data: Leave;
}

const formatDate = (date: string) => {
  let first = new Date(date);
  return first
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .toUpperCase();
};

function CardLeave({ data, index }: CardLeaveProps) {
  const from = useMemo(() => {
    return formatDate(data.date_from);
  }, [data]);
  const to = useMemo(() => {
    return formatDate(data.date_to);
  }, [data]);
  return (
    <div
      className={`${
        index !== 0 ? "mt-2" : ""
      } w-full bg-zinc-200 dark:bg-zinc-700 text-black dark:text-zinc-200 p-2 rounded-xl font-semibold flex flex-col pl-3`}
    >
      <div className="flex flex-row justify-between">
        <p className="font-semibold mt-1">
          {data.id}
          {" - "}
          {"Richiesta"}
        </p>
        <DateChip date={data.app_create_date ? data.app_create_date.substring(0, 10) : ""} />
      </div>

      <div className="flex flex-row justify-evenly items-center mt-1 mb-2">
        <div className="font-semibold flex flex-col justify-center items-center mt-1">
          <div className="text-3xl font-bold">{from.split(" ")[0]}</div>
          <div className="text-xl font-bold -mt-3">{from.split(" ")[1]}</div>
          <div className="text-lg font-bold tracking-tight -mt-3">
            {from.split(" ")[2]}
          </div>
        </div>
        <div className="font-semibold flex flex-col justify-center items-center  mt-1">
          <BsArrowRight className="w-12 h-12 text-zinc-900" />
        </div>
        <div className="font-semibold flex flex-col justify-center items-center  mt-1">
          <div className="text-3xl font-bold">{to.split(" ")[0]}</div>
          <div className="text-xl font-bold -mt-3">{to.split(" ")[1]}</div>
          <div className="text-lg font-bold tracking-tight -mt-3">
            {to.split(" ")[2]}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 w-full mt-1 mb-2">
        <InformationCircleIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold col-span-11 mt-1">{data.name}</p>
      </div>
      <div className="grid grid-cols-12 w-full mt-1 mb-2">
        <QuestionMarkCircleIcon className="h-6 w-6 mt-1 col-span-1" />
        <p className="font-semibold col-span-11 mt-1">
          {data.leave_type.name}{" "}
          {data.period ? (
            <Fragment>
              {data.period === "am" ? "Mattina" : "Pomeriggio"}
            </Fragment>
          ) : (
            ""
          )}
        </p>
      </div>
      <div
        className={`flex flex-row p-4 rounded-xl ${
          data.state === LEAVE_STATES.VALIDATE && "bg-green-400"
        } ${data.state === LEAVE_STATES.CONFIRM && "bg-zinc-400"} ${
          data.state === LEAVE_STATES.REFUSED && "bg-red-500"
        } text-4xl font-bold text-white items-center justify-center`}
      >
        <div>{data.state === LEAVE_STATES.CONFIRM && "Attesa conferma"}</div>
        <div>{data.state === LEAVE_STATES.VALIDATE && "Approvate"}</div>
        <div>{data.state === LEAVE_STATES.REFUSED && "Rifiutate"}</div>
      </div>
    </div>
  );
}

export default CardLeave;
