import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import MainLayout from "./layouts/MainLayout";
import Dotazioni from "./pages/Dotazioni";
import Segnalazioni from "./pages/segnalazioni/Segnalazioni";
import Viaggi from "./pages/trips/Viaggi";
import Manualistica from "./pages/Manualistica";
import Login from "./pages/auth/Login";
import Utente from "./pages/Utente";
import RegistraPesataStart from "./pages/RegistraPesataStart";
import RegistraPesataEnd from "./pages/RegistraPesataEnd";
import ChangePass from "./pages/auth/ChangePass";
import SegnalazioniStep1 from "./pages/segnalazioni/SegnalazioniStep1";
import SegnalazioniStep2 from "./pages/segnalazioni/SegnalazioniStep2";
import RichiestaDotazioni from "./pages/dotazioni/RichiestaDotazioni";
import RiepilogoDotazioni from "./pages/dotazioni/RiepilogoDotazioni";
import TripDetails from "./pages/trips/TripDetails";
import LeaveRequest from "./pages/leaves/LeaveRequest";
import Leaves from "./pages/leaves/Leaves";
import PrivateRoutes from "./utils/PrivateRoutes";
import ForgotPasword from "./pages/auth/ForgotPasword";
import ResetWithToken from "./pages/auth/ResetWithToken";
import HowToInstall from "./pages/HowToInstall";
import CodeLogin from "./pages/auth/CodeLogin";
import AltLayout from "./layouts/AltLayout";
import ViaggiPadroncini from "./pages/padroncini/ViaggiGuest";
import GuestLogin from "./pages/auth/GuestLogin";
import RegistraPesataStartGuest from "./pages/padroncini/RegistraPesataStartGuest";
import RegistraPesataEndGuest from "./pages/padroncini/RegistraPesataEndGuest";

function App() {
  {
    /*useEffect(() => {
    if(showReload && waitingWorker){
      toast((t) => (<div className="w-full flex flex-row justify-start items-center">
        <p>Nuova versione disponibile!</p><button className="ml-2 bg-zinc-300 rounded-xl p-2" onClick={() => reloadPage()}>Aggiorna</button>
      </div>))
    }
  }, [waitingWorker, showReload, reloadPage]);*/
  } 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/codelogin" element={<CodeLogin />} />
        <Route path="/guest/:token" element={<GuestLogin />} />
        <Route path="/forgot" element={<ForgotPasword />} />
        <Route path="/howto" element={<HowToInstall />} />
        <Route path="/reset/:token" element={<ResetWithToken />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to={"/viaggi"} />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="/dotazioni" element={<Dotazioni />} />
            <Route path="/dotazioni/new" element={<RichiestaDotazioni />} />
            <Route path="/dotazioni/recap" element={<RiepilogoDotazioni />} />
            <Route path="/segnalazioni">
              <Route path="/segnalazioni" element={<Segnalazioni />} />
              <Route
                path="/segnalazioni/step1"
                element={<SegnalazioniStep1 />}
              />
              <Route
                path="/segnalazioni/step2"
                element={<SegnalazioniStep2 />}
              />
            </Route>
            <Route path="/viaggi" element={<Viaggi />} />
            <Route path="/trips/details/:id" element={<TripDetails />} />
            <Route path="/leaves" element={<Leaves />} />
            <Route path="/leaves/new" element={<LeaveRequest />} />
            <Route path="/manualistica" element={<Manualistica />} />
            <Route path="/utente" element={<Utente />} />

            <Route
              path="/registrapesata/start"
              element={<RegistraPesataStart />}
            />
            <Route path="/registrapesata/end" element={<RegistraPesataEnd />} />

            <Route path="/cambiapass" element={<ChangePass />} />
          </Route>
        </Route>

        <Route path="/padroncini" element={<AltLayout />}>
          <Route path="/padroncini/viaggi" element={<ViaggiPadroncini />} />
          <Route path="/padroncini/registrapesata/start" element={<RegistraPesataStartGuest />} />
          <Route path="/padroncini/registrapesata/end" element={<RegistraPesataEndGuest />} />

          {/* REDIRECTS */}
          <Route
            path="/padroncini"
            element={<Navigate to={"/padroncini/viaggi"} />}
          />
          <Route
            path="/padroncini/*"
            element={<Navigate to={"/padroncini/viaggi"} />}
          />
        </Route>

        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
