import React, { FormEvent, Fragment, useEffect, useState } from "react";
import TitleWithMenuIcon from "../../components/typography/TitleWithMenuIcon";
import PasswordInputNoForgot from "../../components/inputs/PasswordInputNoForgot";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import UserService from "../../services/UserService";
import AuthService from "../../services/AuthService";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../../components/inputs/TextInput";

function ChangePass() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasCharacters, setHasCharacters] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [hasUpper, setHasUpper] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const checkPassword = () => {
    //check if password has at least 8 characters
    if (newPassword.length < 8) {
      setHasNumber(false);
    } else {
      setHasNumber(true);
    }

    //check if password has at least one number
    if (newPassword.match(/\d+/g) == null) {
      setHasCharacters(false);
    } else {
      setHasCharacters(true);
    }

    //check if password has at least one special character
    if (newPassword.match(/[!@#$%^&*(),.?":{}|<>]/g) == null) {
      setHasSpecial(false);
    } else {
      setHasSpecial(true);
    }

    //check if password has at least one uppercase letter
    if (newPassword.match(/[A-Z]/g) == null) {
      setHasUpper(false);
    } else {
      setHasUpper(true);
    }

    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    checkPassword();
  }, [newPassword, confirmPassword]);

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      toast.error((t) => (
        <Fragment>
          Le password non coincidono
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      return;
    }

    if (newPassword.length < 8) {
      toast.error((t) => (
        <Fragment>
          La password deve essere lunga almeno 8 caratteri
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      return;
    }

    if (newPassword === password) {
      toast.error((t) => (
        <Fragment>
          La nuova password deve essere diversa da quella attuale
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      return;
    }

    UserService.changePassword(password, newPassword, confirmPassword)
      .then((response) => {
        toast.success((t) => (
          <Fragment>
            Password cambiata con successo
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
        AuthService.logout()
          .then(() => {
            localStorage.clear();
            navigate("/login");
          })
          .catch((error) => {});
      })
      .catch((error) => {
        toast.error((t) => (
          <Fragment>
            {error?.response?.data?.message || "Errore nel cambio password"}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
      });
  };

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <div>
          <TitleWithMenuIcon label="Cambia password" />
          <form className="space-y-6 mt-6">
            <PasswordInputNoForgot
              name="password"
              label="Password attuale"
              value={password}
              onChange={setPassword}
            />
            <TextInput
              name="newpassword"
              label="Nuova password"
              value={newPassword}
              onChange={setNewPassword}
            />
            <TextInput
              name="newpasswordconfirm"
              label="Conferma password"
              value={confirmPassword}
              onChange={setConfirmPassword}
            />
            <Fragment>
              <ul className="text-xs mt-2">
                <li
                  className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                    !hasCharacters ? "text-red-600" : "text-green-500"
                  }`}
                >
                  Deve contenere almeno 8 caratteri
                  <span className="ml-2">
                    {!hasCharacters ? (
                      <XMarkIcon className="w-6 h-6 text-red-500" />
                    ) : (
                      <CheckIcon className="w-6 h-6 text-green-500" />
                    )}
                  </span>
                </li>
                <li
                  className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                    !hasNumber ? "text-red-600" : "text-green-500"
                  }`}
                >
                  Deve avere almeno un numero
                  <span className="ml-2">
                    {!hasNumber ? (
                      <XMarkIcon className="w-6 h-6 text-red-500" />
                    ) : (
                      <CheckIcon className="w-6 h-6 text-green-500" />
                    )}
                  </span>
                </li>
                <li
                  className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                    !hasSpecial ? "text-red-600" : "text-green-500"
                  }`}
                >
                  Deve avere almeno un carattere speciale
                  <span className="ml-2">
                    {!hasSpecial ? (
                      <XMarkIcon className="w-6 h-6 text-red-500" />
                    ) : (
                      <CheckIcon className="w-6 h-6 text-green-500" />
                    )}
                  </span>
                </li>
                <li
                  className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                    !hasUpper ? "text-red-600" : "text-green-500"
                  }`}
                >
                  Deve avere almeno una lettera maiuscola
                  <span className="ml-2">
                    {!hasUpper ? (
                      <XMarkIcon className="w-6 h-6 text-red-500" />
                    ) : (
                      <CheckIcon className="w-6 h-6 text-green-500" />
                    )}
                  </span>
                </li>
                <li
                  className={`transition-all duration-300 ease-in-out flex flex-row items-center justify-between font-bold ${
                    !passwordsMatch ? "text-red-600" : "text-green-500"
                  }`}
                >
                  Devono combaciare
                  <span className="ml-2">
                    {!passwordsMatch ? (
                      <XMarkIcon className="w-6 h-6 text-red-500" />
                    ) : (
                      <CheckIcon className="w-6 h-6 text-green-500" />
                    )}
                  </span>
                </li>
              </ul>
            </Fragment>
          </form>
        </div>
        <div className={`w-full sticky bottom-0 mt-3`}>
          <button
            disabled={
              hasCharacters &&
              hasNumber &&
              hasSpecial &&
              hasUpper &&
              passwordsMatch
                ? false
                : true
            }
            type="button"
            onClick={() => handleChangePassword()}
            className="bg-red-500 font-bold hover:bg-red-400 disabled:bg-zinc-500 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1"
          >
            Cambia la password
          </button>
          <SecondaryButton
            label="Torna indietro"
            onClick={() => {
              navigate("/utente");
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ChangePass;
