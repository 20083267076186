export class ReportType{
    id: number;
    name: string;
    can_add_plates: boolean;

    constructor(id: number, name: string, can_add_plates: boolean) {
        this.id = id;
        this.name = name;
        this.can_add_plates = can_add_plates;
    }

    static deserialize(input: any): ReportType {
        return new ReportType(input.id, input.name, input.can_add_plates);
    }

    static getId(reportType: ReportType): number {
        return reportType.id;
    }
}