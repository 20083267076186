import React, { useEffect, useState } from "react";
import SelectServer from "../../utils/SelectServer";
import HeaderBuilder from "../../utils/HeaderBuilder";
import PlaceholderImage from "../../utils/PlaceholderImage";

interface ImageWithLoadValidatorProps {
  url: string;
}

function ImageWithLoadValidator({ url }: ImageWithLoadValidatorProps) {
  const [errored, setErrored] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Function to fetch the image with authentication headers
    const fetchImage = async () => {
      try {
        const response = await fetch(
          SelectServer.getServerImg() + "/" + url.split("&")[0],
          {
            headers: HeaderBuilder.build().headers,
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const src = URL.createObjectURL(blob);
          setImageSrc(src);
        } else {
          setErrored(true);
        }
      } catch (error) {
        setErrored(true);
      }
    };

    fetchImage();

    // Clean up when the component unmounts
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [url]);

  return (
    <div className={""}>
      {!errored && imageSrc ? (
        <img
          alt="img"
          src={imageSrc}
          className="rounded-xl object-cover"
          onError={(e) => {
            setErrored(true);
          }}
        />
      ) : (
        <PlaceholderImage styleoverride="rounded-xl object-cover dark:invert" />
      )}
    </div>
  );
}

export default ImageWithLoadValidator;
