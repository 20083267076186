import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import TripService from "../services/TripService";
import TitleWithMenuIcon from "../components/typography/TitleWithMenuIcon";
import GeoLocationInput from "../components/inputs/GeoLocationInput";
import NumberInput from "../components/inputs/NumberInput";
import SuccessButton from "../components/buttons/SuccessButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import FilePondComponent from "../components/inputs/FilePondComponent";
import { ATTACHMENT_TYPES, Trip } from "../interfaces/TripInterface";
import SimpleAccordion from "../components/accordions/SimpleAccordion";
import AttachmentList from "../components/lists/AttachmentList";
import {
  useIsOnlineAtom,
  useRefreshCurrentTripAtom,
  useRefreshGADAtom,
} from "../context/store";
import LoadingButton from "../components/buttons/LoadingButton";
import SelectServer from "../utils/SelectServer";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { WebAppData } from "../interfaces/WebAppDataInterface";
import GADService from "../services/GADService";

function RegistraPesataEnd() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params] = useSearchParams();
  const [trip, setTrip] = useState<Trip>();
  const [netWeight, setNetWeight] = useState<any>();
  const [grossWeight, setGrossWeight] = useState<any>();
  const [tareWeight, setTareWeight] = useState<any>();
  const [kms, setKms] = useState<any>();
  const [location, setLocation] = useState("");
  const [coords, setCoords] = useState<any>();
  const [refreshCurrentTrip, setRefreshCurrentTrip] =
    useRefreshCurrentTripAtom();
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [refreshGAD, setRefreshGAD] = useRefreshGADAtom();
  const [totalPhotosSize, setTotalPhotosSize] = useState<number>(0);
  const [calculatingSize, setCalculatingSize] = useState<boolean>(false);

  const weightTypes: { label: string; type: ATTACHMENT_TYPES }[] = [
    { label: "Foto Rimorchio", type: ATTACHMENT_TYPES.LOAD },
    { label: "Foto DDT", type: ATTACHMENT_TYPES.DDT },
    { label: "Foto pesata", type: ATTACHMENT_TYPES.WEIGHT },
  ];

  const [photos, setPhotos] = useState<
    { type: ATTACHMENT_TYPES; image: File }[]
  >([]);

  useEffect(() => {
    if (photos) {
      let totalSize = 0;
      photos.forEach((photo) => {
        totalSize += photo.image.size;
      });
      setTotalPhotosSize(totalSize);
    }
  }, [photos]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    JSON.parse(localStorage.getItem("chiarcosso_gad")!).trips.forEach(
      (trip: Trip) => {
        if (trip.id === parseInt(params.get("id")!)) {
          setTrip(trip);
          if (trip.end_net_weight && trip.end_net_weight !== 0) {
            setNetWeight(trip.end_net_weight);
          }
          if (trip.end_tare_weight && trip.end_tare_weight !== 0) {
            setTareWeight(trip.end_tare_weight);
          }
          if (trip.end_kms && trip.end_kms !== 0) {
            setKms(trip.end_kms);
          }
          if (!trip.end_kms && trip.start_kms) {
            setKms(trip.start_kms + 1);
          }
        }
      }
    );
  }, []);

  useEffect(() => {
    if (refreshCurrentTrip) {
      JSON.parse(localStorage.getItem("chiarcosso_gad")!).trips.forEach(
        (trip: Trip) => {
          if (trip.id === parseInt(params.get("id")!)) {
            setTrip(trip);
            setRefreshCurrentTrip(false);
          }
        }
      );
    }
  }, [refreshCurrentTrip]);

  useEffect(() => {
    let net = 0.0;
    let tare = 0.0;

    if (parseFloat(netWeight)) {
      net = parseFloat(netWeight);
    }

    if (parseFloat(tareWeight)) {
      tare = parseFloat(tareWeight);
    }

    setGrossWeight(net + tare);
  }, [netWeight, tareWeight]);

  const handleSave = () => {
    if (calculatingSize) {
      return;
    }

    if (!netWeight || parseInt(netWeight) === 0) {
      toast.error((t) => (
        <Fragment>
          {parseInt(netWeight) === 0
            ? "Inserire un numero superiore a 0"
            : "Inserisci la pesata netta"}
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document.getElementById("netta")?.classList.add("ring-2", "ring-red-500");
      return;
    }

    if (!tareWeight || parseInt(tareWeight) === 0) {
      toast.error((t) => (
        <Fragment>
          {parseInt(tareWeight) === 0
            ? "Inserire un numero superiore a 0"
            : "Inserisci la tara"}
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document.getElementById("tara")?.classList.add("ring-2", "ring-red-500");
      return;
    }

    if (!kms || parseInt(kms) === 0) {
      toast.error((t) => (
        <Fragment>
          {parseInt(kms) === 0
            ? "Inserire un numero superiore a 0"
            : "Inserisci i km correnti"}
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document
        .getElementById("km_motrice")
        ?.classList.add("ring-2", "ring-red-500");
      return;
    }

    if (
      !coords &&
      SelectServer.getServer() !== "https://pwa.chiarcosso.com/app"
    ) {
      toast.error((t) => (
        <Fragment>
          Attendi il caricamento della posizione
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      return;
    }

    if (
      photos.filter((photo) => photo.type === ATTACHMENT_TYPES.WEIGHT)
        .length === 0
    ) {
      toast.error((t) => (
        <Fragment>
          Inserisci la foto della pesata
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document
        .getElementById(ATTACHMENT_TYPES.WEIGHT as string)
        ?.classList.add("ring-2", "ring-red-500");
      return;
    }

    if (
      photos.filter((photo) => photo.type === ATTACHMENT_TYPES.DDT).length === 0
    ) {
      toast.error((t) => (
        <Fragment>
          Inserisci la foto del documento
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document
        .getElementById(ATTACHMENT_TYPES.DDT as string)
        ?.classList.add("ring-2", "ring-red-500");
      return;
    }

    if (
      photos.filter((photo) => photo.type === ATTACHMENT_TYPES.LOAD).length ===
      0
    ) {
      toast.error((t) => (
        <Fragment>
          Inserisci la foto del rimorchio
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <XMarkIcon className="h-6 w-6 text-white" />
          </button>
        </Fragment>
      ));
      document
        .getElementById(ATTACHMENT_TYPES.LOAD as string)
        ?.classList.add("ring-2", "ring-red-500");
      return;
    }

    setIsLoading(true);
    TripService.savePostTripData(parseInt(params.get("id")!), {
      netWeight,
      grossWeight,
      tareWeight,
      kms,
      coords,
      photos,
    })
      .then((data) => {
        toast((t) => (
          <Fragment>
            Invio viaggio in corso
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
        setRefreshGAD(true);
        navigate("/viaggi?tab=attuali");
      })
      .catch((error) => {
        if (!isOnline || error?.response?.status !== 400) {
          toast(
            (t) => (
              <Fragment>
                {
                  "Problemi di connessione, il viaggio verrà sincronizzato piu tardi"
                }
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => {
                    toast.dismiss(t.id);
                  }}
                >
                  <XMarkIcon className="h-6 w-6 text-zinc-800" />
                </button>
              </Fragment>
            ),
            { duration: 3000 }
          );
          navigate("/viaggi?tab=attuali");
          return;
        }
        toast.error((t) => (
          <Fragment>
            {error?.response?.data?.detail ||
              "Errore nel salvataggio del fine viaggio"}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBack = () => {
    navigate("/viaggi?tab=attuali");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoords({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => {
        toast.error((t) => (
          <Fragment>
            Impossibile recuperare la posizione
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
      }
    );
  }, []);

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <TitleWithMenuIcon label="Dati fine viaggio" wifi={true} />
        <div className="mt-2 p-2">
          {totalPhotosSize > 0 && (
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Dimensione foto compresse:{" "}
              {Math.round((totalPhotosSize / 1000000) * 100) / 100} di 30 MB
            </div>
          )}
        </div>
        <GeoLocationInput name="location" onChange={setLocation} />
        <div className="mt-2"></div>
        <NumberInput
          disabled={isLoading}
          label="Pesata Netta"
          name="netta"
          placeholder="Pesata Netta"
          value={netWeight}
          onChange={(val) => {
            if (parseInt(val) < 0) {
              setNetWeight(0);
              return;
            }
            setNetWeight(val);
            document
              .getElementById("netta")
              ?.classList.remove("ring-2", "ring-red-500");
          }}
          uom="KG"
        />
        <div className="mt-2"></div>
        <NumberInput
          disabled={isLoading}
          label="Tara"
          name="tara"
          placeholder="Tara"
          value={tareWeight}
          onChange={(val) => {
            if (parseInt(val) < 0) {
              setTareWeight(0);
              return;
            }
            setTareWeight(val);
            document
              .getElementById("tara")
              ?.classList.remove("ring-2", "ring-red-500");
          }}
          uom="KG"
        />
        <div className="mt-2"></div>
        <NumberInput
          disabled={isLoading}
          label="KM motrice"
          name="km_motrice"
          placeholder="KM Motrice"
          value={kms}
          onChange={(val) => {
            if (parseInt(val) < 0) {
              setKms(0);
              return;
            }
            setKms(val);
            document
              .getElementById("km_motrice")
              ?.classList.remove("ring-2", "ring-red-500");
          }}
          uom="KM"
        />
        {totalPhotosSize > 30000000 && (
          <div className="w-full rounded-xl bg-red-500 text-white font-semibold p-2 mt-2">
            Attenzione, il peso delle foto supera i 30 MB, eliminare qualche
            foto per assicurare la sincronizzazione del viaggio!
          </div>
        )}
        {weightTypes.map(
          (type: { label: string; type: ATTACHMENT_TYPES }, index: number) => (
            <FilePondComponent
              isLoading={isLoading}
              key={index}
              fieldId={type.type as string}
              label={type.label}
              onChange={(images) => {
                setCalculatingSize(true);
                const filteredPhotos = photos.filter(
                  (photo) => photo.type !== type.type
                );
                Promise.all(images).then((res) => {
                  setPhotos([
                    ...filteredPhotos,
                    ...res.map((image: File) => ({
                      type: type.type,
                      image: image,
                      tripId: trip?.id,
                    })),
                  ]);
                  setCalculatingSize(false);
                });
                document
                  .getElementById(type.type as string)
                  ?.classList.remove("ring-2", "ring-red-500");
              }}
            />
          )
        )}
        <div className="mt-2"></div>
        <div className="mb-2">
          <SimpleAccordion
            title="Foto caricate"
            open={open}
            setOpen={setOpen}
            children={
              <Fragment>
                <AttachmentList canRemove={true} trip={trip!} />
              </Fragment>
            }
          />
        </div>
      </div>
      <div
        className={`w-full sticky bottom-0 mt-3 grid ${
          isLoading ? "grid-cols-1" : "grid-cols-2"
        } gap-2`}
      >
        {!isLoading && (
          <SecondaryButton
            label="Torna indietro"
            onClick={() => {
              handleBack();
            }}
          />
        )}
        {isLoading ? (
          <LoadingButton />
        ) : (
          <SuccessButton
            isDisabled={totalPhotosSize > 30000000 || calculatingSize}
            label="Salva"
            onClick={() => {
              handleSave();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RegistraPesataEnd;
