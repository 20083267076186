import React from "react";

interface PrimaryButtonProps {
  label?: string;
  icon?: any;
  position?: string;
  onClick: () => void;
  disabled?: boolean;
}

function PrimaryButton({ label, icon, position, onClick, disabled = false }: PrimaryButtonProps) {
  return (
    <button disabled={disabled} className="bg-[#0385FF] hover:bg-blue-400 disabled:bg-zinc-700 disabled:text-zinc-200 text-white flex justify-center align-middle w-full p-4 rounded-xl transition-all duration-300 ease-in-out my-1 active:scale-95" onClick={() => {onClick()}}>
      <div className="inline-flex">
        {icon && position === "start" && (
          <span className="mt-0.5 mr-2 font-bold">
          {icon}
          </span>
        )}
        <p className={`font-semibold`}>{label ? label : "Primary"}</p>
        {icon && position === "end" && (
          <span className="mt-0.5 ml-2 font-bold">
          {icon}
          </span>
        )}
      </div>
    </button>
  );
}

export default PrimaryButton;