import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGADAtom,
  useIsOnlineAtom,
  useOpenMenuAtom,
  usePhotoSyncAtom,
  usePhotosToSyncAtom,
  useRefreshGADAtom,
  useReportPhotoSyncAtom,
  useReportsToSyncAtom,
  useSyncAtom,
  useTripsToSyncAtom,
} from "../context/store";
import GADService from "../services/GADService";

import Avatar from "boring-avatars";
import {
  Bars3Icon,
  ExclamationTriangleIcon,
  GlobeEuropeAfricaIcon,
  ShoppingCartIcon,
  SparklesIcon,
  WifiIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FaWhatsapp } from "react-icons/fa";
import Loading from "../components/Loading";
import InstallAppModal from "../components/modals/InstallAppModal";
import UserService from "../services/UserService";
import HeaderBuilder from "../utils/HeaderBuilder";
import SelectServer from "../utils/SelectServer";
import { User } from "../interfaces/UserInterface";
import DarkModeSwitch from "../components/buttons/DarkModeSwitch";
import WarningLeaveModal from "../components/modals/WarningLeaveModal";
import { cancelAllRequests } from "../utils/AxiosInstance";
import TripService from "../services/TripService";
import { db } from "../database/db";
import Tracker from "@openreplay/tracker";
import ReportService from "../services/ReportService";

function MainLayout() {
  const location = useLocation();
  const [GAD, setGAD] = useGADAtom();
  const [isDark, setIsDark] = useState(false);
  const [isOnline, setIsOnline] = useIsOnlineAtom();
  const [isLoading, setIsLoading] = useState(false);
  const [openMenu, setOpenMenu] = useOpenMenuAtom();
  const [refreshGAD, setRefreshGAD] = useRefreshGADAtom();
  const [syncing, setSyncing] = useSyncAtom();
  const [syncingPhotos, setSyncingPhotos] = usePhotoSyncAtom();
  const [syncingReportPhotos, setSyncingReportPhotos] =
    useReportPhotoSyncAtom();
  const [showInstallHelp, setShowInstallHelp] = useState(false);
  const [showWarningLeave, setShowWarningLeave] = useState(false);
  const [photosToSync, setPhotosToSync] = usePhotosToSyncAtom();
  const [tripsToSync, setTripsToSync] = useTripsToSyncAtom();
  const [reportsToSync, setReportsToSync] = useReportsToSyncAtom();
  const [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem("chiarcosso_user")!)
  );
  const [userImg, setUserImg] = useState<any>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [tracker, setTracker] = useState<any>();
  const locations = useMemo(
    () => [
      "/viaggi",
      "/dotazioni",
      "/segnalazioni",
      "/manualistica",
      "/leaves",
      "/utente",
    ],
    []
  );

  useEffect(() => {
    window.addEventListener("beforeunload", alertLeaving);
    window.addEventListener("unload", handleLeave);
    return () => {
      window.removeEventListener("beforeunload", alertLeaving);
      window.removeEventListener("unload", handleLeave);
    };
  }, []);

  const alertLeaving = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleLeave = () => {
    cancelAllRequests();
  };

  const syncProcess = () => {
    setIsFirstLoad(false);
    if (syncing) return;
    setSyncing(true);
    GADService.getGAD()
      .then((gad) => {
        setGAD(gad);
        setSyncing(false);
      })
      .catch((error) => {
        toast.error((t) => (
          <Fragment>
            {error?.response?.data?.detail ||
              "Errore nel caricamento dei dati, riprova più tardi."}
            <button
              type="button"
              className="ml-2"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-white" />
            </button>
          </Fragment>
        ));
      })
      .finally(() => {
        setSyncing(false);
        setRefreshGAD(false);
      });
  };

  const checkRemainingItems = async () => {
    const remaining_img = await db.images.where("locked").equals(0).toArray();
    const remaining_rep = await db.report_images
      .where("locked")
      .equals(0)
      .toArray();

    setReportsToSync(0);
    setTripsToSync(0);
    setPhotosToSync(remaining_img.length + remaining_rep.length);

    return { trip: remaining_img.length, report: remaining_rep.length };
  };

  //OpenReplay config
  useEffect(() => {
    const startOpenReplay = async () => {
      if (!tracker) {
        try {
          const trackerInstance = new Tracker({
            ingestPoint: "https://openreplay.chiarcosso.com/ingest",
            projectKey:
              window.location.hostname === "app.chiarcosso.com"
                ? "Q4ER49tFYLjXOsKYFKoi"
                : "cEQQMULVwy9IcGqkwfSF",
            __DISABLE_SECURE_MODE:
              window.location.hostname === "app.chiarcosso.com" ||
              window.location.hostname === "staging-app.chiarcosso.com"
                ? false
                : true,
            respectDoNotTrack: false,
            resourceBaseHref:
              window.location.hostname === "app.chiarcosso.com"
                ? "https://app.chiarcosso.com"
                : "https://staging-app.chiarcosso.com",
            obscureInputDates: false,
            obscureInputEmails: false,
            obscureInputNumbers: false,
            obscureTextEmails: false,
            obscureTextNumbers: false,
            capturePerformance: true,
            defaultInputMode: 0,
            network: {
              capturePayload: true,
              failuresOnly: false,
              sessionTokenHeader: "",
              ignoreHeaders: [],
              captureInIframes: false,
            },
          });
          if (!trackerInstance.isActive()) {
            setTracker(trackerInstance);
            await trackerInstance.start({
              userID: user.username,
              metadata: { appVersion: process.env.REACT_APP_VERSION },
            });
          }
        } catch (error) {
          console.error("OpenReplay error: ", error);
        }
      }
    };
    startOpenReplay();
  }, []);

  //Tab color manager
  useEffect(() => {
    let elem = document.getElementsByTagName("html")[0];
    let ids = ["viaggi", "dotazioni", "segnalazioni", "manualistica", "leaves"];
    elem.className =
      "bg-gradient-to-br from-zinc-950 transition-all duration-300 ease-in-out";

    switch (location.pathname) {
      case "/viaggi":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/dotazioni":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/segnalazioni":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/manualistica":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      case "/leaves":
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
      default:
        elem.classList.add("via-zinc-900");
        elem.classList.add("to-red-900");
        break;
    }

    if (isDark) {
      elem.classList.add("dark");
    }

    ids.forEach((id) => {
      if (location.pathname === `/${id}`) {
        document.getElementById(id)?.classList.add("bg-zinc-800");
        document.getElementById(id)?.classList.add("text-red-500");
      } else {
        document.getElementById(id)?.classList.remove("bg-zinc-800");
        document.getElementById(id)?.classList.remove("text-red-500");
      }
    });

    // TODO - da rivedere
    if (locations.includes(location.pathname)) {
      setOpenMenu(false);
    }
  }, [location]);

  
  // Refresh user data
  useEffect(() => {
    if (isOnline) {
      UserService.getUserData()
        .then((response) => {
          const options = {
            headers: HeaderBuilder.build().headers,
          };
          fetch(
            SelectServer.getServerImg() + response.data.image.image_url,
            options
          )
            .then((response) => response.blob())
            .then((blob) => {
              setUserImg(URL.createObjectURL(blob));
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  }, [isOnline]);

  //First load checks and configs
  useEffect(() => {
    console.log("Chiarcosso autisti v" + process.env.REACT_APP_VERSION);
    if (localStorage.getItem("chiarcosso_theme")) {
      if (localStorage.getItem("chiarcosso_theme") === "dark") {
        document.body.classList.add("dark");
        setIsDark(true);
      } else {
        document.body.classList.remove("dark");
        setIsDark(false);
      }
    } else {
      localStorage.setItem("chiarcosso_theme", "light");
      document.body.classList.remove("dark");
      setIsDark(false);
    }

    //check if pwa is installed
    if (
      !window.matchMedia("(display-mode: standalone)").matches &&
      !localStorage.getItem("chiarcosso_disable_modal")
    ) {
      setShowInstallHelp(true);
    }

    if (isFirstLoad) {
      unlockDb().then(() => {
        syncProcess();
      });
    }
    return () => {};
  }, []);

  const unlockDb = async () => {
    await db.images.where("locked").equals(1).modify({ locked: 0 });
    await db.report_images.where("locked").equals(1).modify({ locked: 0 });
    checkRemainingItems();
    return;
  };

  //Online status manager
  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    //Sync Trips
    const intervalId = setInterval(() => {
      syncProcess();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (refreshGAD) {
      syncProcess();
    }
  }, [refreshGAD]);

  useEffect(() => {
    //Sync photos

    console.log(
      "Syncing photos : " + syncingPhotos + " - " + syncingReportPhotos
    );

    if (syncingPhotos || syncingReportPhotos) return;

    const intervalId = setInterval(async () => {
      let count = await checkRemainingItems();

      if (count.trip + count.report === 0) return;

      if (!syncingPhotos && count.trip > 0) {
        setSyncingPhotos(true);
        TripService.updatePhoto()
          .then((response) => {
            setSyncingPhotos(false);
          })
          .catch((error) => {
            setSyncingPhotos(false);
            console.log(error);
          });
      }

      if (!syncingReportPhotos && count.report > 0) {
        setSyncingReportPhotos(true);
        ReportService.updatePhoto()
          .then((response) => {
            setSyncingReportPhotos(false);
          })
          .catch((error) => {
            setSyncingReportPhotos(false);
            console.log(error);
          });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [syncingPhotos, syncingReportPhotos]);

  return (
    <>
      <Toaster
        toastOptions={{
          style: { width: "100%", borderRadius: "13px" },
          success: {
            iconTheme: { primary: "#4ade80", secondary: "#fff" },
            style: { backgroundColor: "#4ade80", color: "#ffffff" },
            icon: "",
          },
          error: {
            iconTheme: { primary: "#ef4444", secondary: "#fff" },
            style: { backgroundColor: "#ef4444", color: "#ffffff" },
            icon: "",
          },
        }}
      />
      <div className="flex overflow-hidden">
        {openMenu && (
          <div className="left-48 h-[100dvh] fixed inset-0 z-[100] bg-transparent opacity-100"></div>
        )}
        <div
          className={`${
            openMenu ? "w-48" : "w-0 sm:w-12"
          } h-[100dvh] relative transition-all duration-300 ease-in-out`}
        >
          <div className="flex flex-col h-full">
            <ul className="pt-6 ml-2">
              <li
                className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2`}
              >
                <div className="inline-flex">
                  <button
                    onClick={() => setOpenMenu(!openMenu)}
                    className="inline-flex"
                  >
                    <img
                      src="/logo-white.png"
                      alt="logo"
                      className={`${
                        openMenu ? "h-10 w-auto" : "h-0 w-0"
                      } transition-all duration-300 ease-in-out`}
                    />
                    <Bars3Icon
                      className={`${
                        openMenu ? "mt-2" : ""
                      } transition-all duration-300 ease-in-out h-6 w-6 text-zinc-400`}
                    />
                  </button>
                </div>
              </li>
            </ul>
            <ul className="pt-6 ml-2">
              <li
                id="viaggi"
                className="flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
              >
                <Link to="/viaggi" className="inline-flex">
                  <GlobeEuropeAfricaIcon className="h-6 w-6" />
                  <p
                    className={`${
                      openMenu
                        ? "opacity-100 blur-none"
                        : "opacity-0 blur-2xl translate-x-[-200px]"
                    } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                  >
                    Viaggi
                  </p>
                </Link>
              </li>
              {user && user.access_rights === "full" && (
                <li
                  id="dotazioni"
                  className={`flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2`}
                >
                  <Link to="/dotazioni" className={`inline-flex`}>
                    <ShoppingCartIcon className={`h-6 w-6`} />
                    <p
                      className={`${
                        openMenu
                          ? "opacity-100 blur-none"
                          : "opacity-0 blur-2xl translate-x-[-200px]"
                      } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                    >
                      Dotazioni
                    </p>
                  </Link>
                </li>
              )}
              <li
                id="segnalazioni"
                className="flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
              >
                <Link to="/segnalazioni" className="inline-flex">
                  <ExclamationTriangleIcon className="h-6 w-6" />
                  <p
                    className={`${
                      openMenu
                        ? "opacity-100 blur-none"
                        : "opacity-0 blur-2xl translate-x-[-200px]"
                    } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                  >
                    Segnalazioni
                  </p>
                </Link>
              </li>
              {/*<li
                id="manualistica"
                className="flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
              >
                <Link to="/manualistica" className="inline-flex">
                  <BookOpenIcon className="h-6 w-6" />
                  <p
                    className={`${
                      openMenu
                        ? "opacity-100 blur-none"
                        : "opacity-0 blur-2xl translate-x-[-200px]"
                    } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                  >
                    Manualistica
                  </p>
                </Link>
              </li>*/}
              {user && user.access_rights === "full" && (
                <li
                  id="leaves"
                  className="flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
                >
                  <Link to="/leaves" className="inline-flex">
                    <SparklesIcon className="h-6 w-6" />
                    <p
                      className={`${
                        openMenu
                          ? "opacity-100 blur-none"
                          : "opacity-0 blur-2xl translate-x-[-200px]"
                      } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                    >
                      Ferie
                    </p>
                  </Link>
                </li>
              )}
            </ul>
            <div className="flex flex-grow"></div>
            <ul className="pt-6 ml-2 mb-2">
              <li className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2 -translate-x-1.5">
                <span className="inline-flex">
                  <WifiIcon
                    className={`h-10 w-10 transition-all ease-in-out duration-300 ${
                      isOnline ? "text-green-500" : "text-red-500"
                    }`}
                  />
                </span>
              </li>
              <li
                id="leaves"
                className="flex rounded-xl p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2"
              >
                <a
                  href="https://wa.me/393403322559"
                  target="_blank"
                  className="inline-flex"
                >
                  <FaWhatsapp className="h-6 w-6" />
                  <p
                    className={`${
                      openMenu
                        ? "opacity-100 blur-none"
                        : "opacity-0 blur-2xl translate-x-[-200px]"
                    } ml-4 mt-0.5 font-semibold transition-all duration-300 ease-in-out`}
                  >
                    Supporto
                  </p>
                </a>
              </li>
              <li
                id="utente"
                className={`flex rounded-xl ml-0.5 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2 transition-all duration-300 ease-in-out ${
                  openMenu && location.pathname === "/utente"
                    ? "bg-zinc-800 p-2"
                    : ""
                }`}
              >
                <Link to={"/utente"} className="relative inline-block">
                  <div className="inline-flex">
                    {userImg !== "" ? (
                      <img
                        src={userImg}
                        className="rounded-full w-10 h-10 object-cover"
                      />
                    ) : (
                      <Avatar
                        size={40}
                        name={user ? user.name : "Utente"}
                        variant="marble"
                        colors={[
                          "#92A1C6",
                          "#146A7C",
                          "#F0AB3D",
                          "#C271B4",
                          "#C20D90",
                        ]}
                      />
                    )}
                    {openMenu && (
                      <p className="text-white font-semibold text-sm ml-2 mt-2.5">
                        Profilo
                      </p>
                    )}
                  </div>
                </Link>
              </li>
              <li className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2">
                {openMenu ? <DarkModeSwitch /> : <Fragment></Fragment>}
              </li>
            </ul>
          </div>
        </div>
        {isMobile ? (
          <>
            {openMenu && <div></div>}
            <div
              className={`${
                openMenu ? "translate-x-20 scale-75 blur-sm" : ""
              } z-20 p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 opacity-100 flex-1 transition-all duration-300 ease-in-out overflow-y-scroll max-h-[98dvh] overflow-x-hidden`}
            >
              {isLoading ? <Loading /> : <Outlet />}
            </div>
          </>
        ) : (
          <div className="z-20 p-2 pt-4 m-2 rounded-xl bg-zinc-50 dark:bg-zinc-900 flex-1 transition-all duration-300 ease-in-out max-h-[98dvh]">
            {isLoading ? <Loading /> : <Outlet />}
          </div>
        )}
      </div>

      <InstallAppModal open={showInstallHelp} setOpen={setShowInstallHelp} />
      <WarningLeaveModal
        open={showWarningLeave}
        setOpen={setShowWarningLeave}
        leave={handleLeave}
      />
    </>
  );
}

export default MainLayout;
